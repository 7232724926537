import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxBackground, BrxImageWithTextProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { PaymentMeta } from '@mediashop/app/api/types/Payment';

const ExpressCheckoutOrganism = lazy(() => lazyRetry(() => import('./ExpressCheckout')));

export type ExpressCheckoutProps = BaseProps & {
    background: BrxBackground;
    icons: BrxImageWithTextProps[];
    payment: PaymentMeta;
};

const ExpressCheckout = (props: ExpressCheckoutProps) => (
    <LazyComponent>
        <ExpressCheckoutOrganism {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.ExpressCheckout', ExpressCheckout, 'catalog-base');
