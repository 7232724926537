import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import {
    BaseProps,
    BrxBackground,
    BrxHeadlines,
    BrxImageWithTextProps,
    BrxLink,
} from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const MPCheckoutOrganism = lazy(() => lazyRetry(() => import('./MultiPageCheckout')));

export type CheckoutAddressFormSubmitHandle = {
    submit: () => void;
};

export type MultiPageCheckoutProps = BaseProps & {
    headline?: BrxHeadlines;
    background: BrxBackground;
    icons: BrxImageWithTextProps[];
    passwordForgotten: BrxLink;
};

const Checkout = (props: MultiPageCheckoutProps) => (
    <LazyComponent>
        <MPCheckoutOrganism {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.MultiPageCheckout', Checkout, 'catalog-base');
