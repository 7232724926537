import { CSSProperties, useRef } from 'react';
import classNames from 'classnames';
import { BaseProps, BrxImageSetImpl } from '@mediashop/app/bloomreach/types';
import { breakpointWidthMap } from '@mediashop/app/config/breakpoints';
import { useBloomreachImage } from '@mediashop/base/hooks/useBloomreachImage';
import { BrXImageVariant } from '../../../types/imageVariants';
import { useElementIsVisible } from '@mediashop/app/hooks/useElementIsVisible';

const componentName = `responsive-image`;

type Props = BaseProps & {
    alt: string;
    mobileImage?: BrxImageSetImpl;
    tabletImage?: BrxImageSetImpl;
    desktopImage: BrxImageSetImpl;
    variant: BrXImageVariant;
    style?: CSSProperties;
};

/**
 * Responsive image component for images coming from BrX
 */
export default function BloomreachImageResponsive({
    className = '',
    alt,
    desktopImage,
    tabletImage = desktopImage,
    mobileImage = tabletImage,
    variant,
    style,
}: Props): JSX.Element {
    const rewrittenDesktopImage = useBloomreachImage(desktopImage, variant, 'desktop');
    const rewrittenTabletImage = useBloomreachImage(tabletImage, variant, 'tablet');
    const rewrittenMobileImage = useBloomreachImage(mobileImage, variant, 'mobile');

    const imageRef = useRef(null);

    const isVisible = useElementIsVisible(imageRef);

    return (
        <picture ref={imageRef}>
            {/** Desktop */}
            <source
                media={`(min-width: ${breakpointWidthMap.desktop}px)`}
                srcSet={rewrittenDesktopImage}
                height={desktopImage?.original?.model?.height || undefined}
                width={desktopImage?.original?.model?.width || undefined}
            />
            {/** Tablet */}
            <source
                media={`(min-width: ${breakpointWidthMap.tablet}px)`}
                srcSet={rewrittenTabletImage}
                height={tabletImage?.original?.model?.height || undefined}
                width={tabletImage?.original?.model?.width || undefined}
            />
            {/** Mobile */}
            <source
                media={`(min-width: ${breakpointWidthMap.mobile}px)`}
                srcSet={rewrittenMobileImage}
                height={mobileImage?.original?.model?.height || undefined}
                width={mobileImage?.original?.model?.width || undefined}
            />

            {/** Global (also acting as fallback for older browsers) */}
            <img
                className={classNames(`${componentName}__img`, className)}
                src={rewrittenDesktopImage}
                alt={alt}
                loading={isVisible ? 'eager' : 'lazy'}
                style={style}
            />
        </picture>
    );
}
