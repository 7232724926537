/* eslint-disable id-length */
export default {
    test: 'Bonjour test en français',
    account: {
        test: 'Hallo {name} auf Deutsch im Account',
        subscribeButton: "s'abonner à la newsletter",
        unSubscribeButton: 'Désabonner la newsletter',
        unsubscribeSuccess: 'Vous avez réussi à vous désinscrire de la newsletter.',
    },
    deal: {
        days: 'jours',
        hours: 'heures',
        minutes: 'minutes',
        seconds: 'secondes',
        dealOver: 'l’offre est échue',
        countdownEnd: 'Cette offre se termine dans',
        specialDealOver: 'Expiré',
    },
    mainNavigation: {
        greeting: 'Salut, {name}',
        account: 'Mon compte',
        logout: 'Se déconnecter',
        search: 'Rechercher des produits',
        loggedout: 'Déconnecté!',
        searchSuggestion: 'Suggestions de recherche',
        productSuggestion: 'Suggestions de produits',
        showResults: '{count, plural, =1 {Afficher {text} résultat} other {Afficher tous les {text} résultats}}',
        resultSearch: '{count} {count, plural, one {résultat} other {résultats}} pour {query}',
        logoLinkLabel: "Page d'accueil",
        accountLinkLabel: 'compte client',
        content: 'Contenus',
    },
    product: {
        cheaperSet: '2ème pièce seul. ',
        setTypeXplusY: '2ème pièce seul. ',
        quantityAdvantage: 'Avantage de quantité: ',
        quantityAdvantageActive: 'Avantage quantitatif actif!',
        ratingCount: '{count} {count, plural, one {Évaluation} other {Évaluation}}',
        unitPriceSuffix: '\u202f/\u202f{baseUnitMultiplier}\u00a0{referenceUnit}',
        priceHint: 'TVA incluse dans les prix',
        deliveryHint: `Informations sur les frais d'expédition et les frais de transaction`,
        savedMoneySuffix: 'Économies',
        savedMoneyBadge: 'économisé',
        buyNow: 'Commandez',
        addToCart: 'Dans le panier',
        goToPdp: 'vers la page de détails du produit',
        showDetails: 'Choisir la variante',
        descriptionShowMore: 'Afficher plus...',
        descriptionShowLess: 'Afficher moins...',
        detailsShowMore: 'Afficher plus...',
        detailsShowLess: 'Afficher moins...',
        variantSelectorTitle: 'Sélectionnez:',
        emptyVariant: 'Sélectionnez',
        chooseVariant: ' choisir',
        emptyVariantError: 'Séléctionnez',
        suggestedPriceShort: 'PVC',
        uvp: 'PVC',
        statt: 'Au lieu de',
        variantSize: 'taille',
        variantColor: 'couleur',
        badges: {
            installments: '{count}x {count, plural, one {Versement\n} other {Versements\nchacun}} {price}',
            installmentsVariants: 'dès {count}x {count, plural, one {Versement\n} other {Versements\nchacun}} {price}',
            installmentsVariantsAsAlternative:
                'ou à partir de {count}x {count, plural, one {Versement} other {Versements chacun}} {price}',
            installmentsAsAlternative: 'ou {count}x {count, plural, one {Versement} other {Versements chacun}} {price}',
            installmentsInFinalSummary: '{count}x {count, plural, one {Versement} other {Versements chacun}}',
            installmentsInCart: '{count}x {count, plural, one {Versement} other {Versements chacun}} {price}',
            xplusygratis: '{text} GRATUIT',
            savings: '{count}% économisé',
            savingsPDP: '-{count}%',
            deal: 'Promotion -{count}%',
            mobile: {
                gratisZubehoer: '+Accessoires',
            },
        },
        socialMediaShareHeadline: '{shopName}',
        socialMediaShareText: 'regarde ce que j’ai trouvé:',
        attributes: {
            headline: 'Information produit',
            subheadline: 'Spécifications techniques',
            true: 'Oui',
            false: 'Non',
            maxOrderQuantity: 'Max. Bestellmenge',
            deliverables: 'Leistungen',
            name: 'Name',
            description: 'Beschreibung',
            usp: 'USP',
            productDetails: 'détails du produit',
            claim: 'Claim',
            weightGross: 'Gewicht',
            articleType: 'Artikeltyp',
            articleIsBuyable: 'Kaufbar',
            erpCategory1: 'Kategorie',
            erpCategory2: 'Unterkategorie',
            crmArticleBundleNo: 'Artikelbündel Nr.',
            webSubtitle: 'Web Untertitel',
            countryLicensesAvailable: 'Länderverfügbarkeit',
            articleDimensions: 'Abmessungen',
            textilMaterialComposition: 'Material',
            relatedUpsell: 'Upsell',
            relatedUpgrade: 'Zubehör',
            subscriptionAvailable: 'Abonnement',
            deliveryTimeFrom: 'Min. Lieferzeit',
            deliveryTimeUntil: 'Max. Lieferzeit',
            medicalProduct: 'Medizinprodukt',
            productChemicalIngredients: 'Inhaltsstoffe',
            signalWord: 'Signalwort',
            ean: 'EAN',
            eanNl: 'EAN NL',
            prices: 'Preise',
            biocidalProduct: 'Biozidprodukte',
            shippingTimeCode: 'Lieferzeit Code',
            expiryDate: 'Ablaufdatum',
            navBulkyItem: 'Nav Bulky Item',
            productSafety: 'Sécurité des produits',
            subscriptionSelection: {
                day: '<b>Intervalle de livraison</b>: {amount} {amount, plural, one {Jour} other {Jours}}',
                month: '<b>Intervalle de livraison</b>: {amount} {amount, plural, one {Mois} other {Mois}}',
                week: '<b>Intervalle de livraison</b>: {amount} {amount, plural, one {Semaine} other {Semaine}}',
            },
            perDelivery: 'par livraison',
        },
        addedToCart: "Bon choix! L'article a été ajouté.",
        cantAddToCart: "Impossible d'ajouter l'article.",
        productNumber: 'Réf.',
        productRelation: 'Produits assortis',
        payOnce: 'ou une fois',
        payOnceSum: 'Prix total',
        payOnceSumMobile: 'Total',
        next: 'suivante',
        previous: 'précédente',
        deliveryProductCard: 'Délai de livrasion: {from}-{until} jours ouvrables',
        downloadFor: 'Télécharger le manuel pour ',
        topReview: 'Meilleure note',
        seeMore: 'Voir plus',
        seeLess: 'Voir moins',
        totalReviews: '{formattedCount} {count, plural, one {Évaluation} other {Commentaires}}',
        noReviews: 'Aucune donnée disponible.',
        videoThumbnailText: 'Video',
        subscription: {
            oneTimeDelivery: 'Achat unique',
            abo: 'Abonnement',
            emptyOption: 'Intervalle de livraison:',
            intervalUnit: {
                day: '{amount} {amount, plural, one {Jour} other {Jours}}',
                month: '{amount} {amount, plural, one {Mois} other {Mois}}',
                year: '{amount} {amount, plural, one {Année} other {Années}}',
            },
            delivery: 'Livraison',
            recommendedInterval: 'recommandé',
        },
        exceededMaxOrderQuantity: 'Quantité maximale de commande ({amount}) dépassée pour cet article.',
        pageTitle: '{title}{store, select, EMPTY {} other { | {store}}}',
        productUnavailable: {
            headline: 'Produit non disponible actuellement!',
            text: 'Choisissez plutôt une alternative dans notre assortiment!',
        },
        bundleTitle: 'Disponible également en set:',
    },
    preCheckout: {
        successMessage: 'bon choix! l’article a été ajouté',
        ctaMessage: 'ajouter au panier et',
        ctaMessageBold: 'commander sans frais de livraison',
        accessories: 'accessoire correspondant',
        goBackBtn: 'continuer les achats',
        goToCartBtn: 'vers le panier',
        addToCartSingle: 'Details',
        addToCart: 'Avec commande',
        addToCartUpgrade: 'Mise à niveau',
        relations: 'commander en même temps',
        toProduct: 'au produit',
        deliverables: 'Contenu de la livraison: ',
        clubSuggestion: 'Économiser en plus?',
        clubCardAlreadyInCart: 'Vous avez déjà une carte client dans votre panier.',
    },
    orderSuccessPage: {
        orderSuccess: 'merci beaucoup pour vos achats!',
        goToShop: 'retour à la boutique',
        accountQuestion: 'Voulez-vous définir encore un mot de passe et profiter des avantages du compte client?',
        accountAdvantagesHeader: 'les avantages du compte client',
        accountAdvantageOne: 'Aperçu de toutes vos commandes',
        accountAdvantageTwo: 'Conclusion plus rapide des commandes',
        accountAdvantageThree: 'Gestion des données de vos clients',
        password: 'mot de passe',
        passwordReq: 'au moins 8 caractères',
        passwordReqDescription: 'entrez un mot de passe pour protéger vos données',
        passwordReqDescTitle: 'votre mot de passe doit',
        passwordReqDescOne: 'contenir au moins 8 caractères ',
        passwordReqDescTwo: 'au moins 1 chiffre',
        passwordReqDescThree: 'au moins 1 lettre majuscule',
        passwordReqDescFour: 'au moins 1 lettre minuscule',
        checkboxText: 'je souhaite être informé des offres actuelles de {shopName}',
        checkboxInfo: 'une révocation gratuite est possible à tout moment',
        passwordSaveBtn: 'Enregistrer',
    },
    cart: {
        noReservation: 'Les articles dans le panier ne sont pas réservés',
        headline: 'Panier',
        articlesWithCount: '{count} article',
        addons: {
            headline: 'A commander en même temps',
            add: 'Commander',
        },
        subtotal: ' Sous-total ',
        deliveryCost: ' Envoi & livraison',
        total: ' Montant total',
        allTotal: 'Montant total',
        shippedFreeOne: 'cette livraison est',
        shippedFreeTwo: 'GRATUIT',
        shippedFreeThree: 'expédiée',
        vatIncluded: 'TVA incluse',
        savings: 'Économies en total',
        toCheckout: 'Passer en caisse',
        free: 'gratuit',
        savingsText: 'vous économisez au total',
        goSafeToCheckout: 'VERS LE PAIEMENT',
        xArticle: '({amount} articles)',
        voucher: 'Coupon',
        redeemVoucher: 'ajoutez un code promotionnel',
        codePlaceholder: "Code de bon d'achat (facultatif)",
        redeemCTA: 'valider',
        voucherCodeSuccess: 'code promotionnel "{code}" validé',
        voucherCodeErrorCodes: {
            alreadyPresent: 'Code de bon "{code}" déjà utilisé',
            nonApplicable: "Ce bon n'est plus actif ou ne peut pas être utilisé pour votre panier actuel!",
        },
        emptyCart: 'Votre panier est vide',
        emptyCartMessage:
            'Remplissez votre panier avec les dernières aides à la vie quotidienne ' +
            'et les solutions aux problèmes.',
        emptyCartButton: 'retourner au magasin',
        emptyCartButtonMessage: 'Continuer mes achats',
        voucherModal: {
            headline: 'Votre bon a été activé avec succès!',
            headlineGiftLineItem: 'Votre cadeau gratuit a été activé!',
            text: '{totalSavings} économies à partir de {minValue} minimum de commande',
            continue: 'ACHETEZ MAINTENANT',
        },
        wkoProductTitle: 'Votre cadeau gratuit',
        wkoModalHeadline: "Commander en même temps les accessoires correspondants et s'assurer un cadeau",
        wkoDeclineOffer: 'Non merci',
        wkoAddToCartSuccess: "Bon choix!\nL'article a été ajouté.",
        restoreCartError: "Votre panier n'a pas pu être récupéré.",
        clubSavings: {
            clubSavingsText: 'Économiser avec le club clients {savings} !',
            clubSavingsLink: 'Cliquez {clubSavingsUnderline} pour plus de détails',
            clubSavingsUnderline: 'ici',
        },
        clubExtensionCta: 'Prolonger',
        freeShippingBanner: {
            freeShipping: 'livraison gratuite',
            headline: "Seulement {onlyLeftPrice} jusqu'à la {freeShipping} à partir de {fromPrice}",
            continue: 'Continuer ',
            shopping: 'les achats',
            order: 'Cette commande sera ',
            freeDelivery: "livrée sans frais d'envoi!",
        },
    },
    category: {
        showMore: 'Voir plus',
        breadcrumbHomeAriaLabel: 'Accueil',
    },
    voucher: {
        codes: {
            notActive: "Le bon n'est pas actif.",
            notValid: "Le bon d'achat n'est plus valable.",
            doesNotMatchCart: "Le bon d'achat ne remplit pas les conditions requises.",
            maxApplicationReached: 'Le bon ne peut plus être utilisé.',
            applicationStoppedByPreviousDiscount:
                "Le bon d'achat ne peut pas être utilisé en raison d'un autre bon d'achat.",
            matchesCart: 'Le bon est valable',
        },
        modal: {
            dontShowLegalText: 'Masquer les conditions du bon',
            displayLegalText: 'Afficher les conditions du bon',
            savings: '{amount} Économies',
            headline: 'Quel bon souhaitez-vous conserver?',
            minimalOrderAmount: ' de {amount} Valeur minimale de commande',
        },
        priceGiftLineItem: 'Cadeau gratuit: -{price}',
    },
    checkout: {
        minicartEdit: 'éditer',
        dividerText: 'Ou',
        allowRecurrence: {
            checkbox: 'Autoriser les paiements récurrents',
            error: 'Vous devez accepter les paiements récurrents.',
        },
        catalogBillingAddressHeadline: 'Adresse de facturation',
        editBillingAddress: "Modifier l'adresse de facturation",
        billingAddressHeadline: '1. Adresse de facturation',
        guestInfo:
            'Vos données ne sont collectées que pour cette commande et doivent être à nouveau indiquées pour ' +
            "les commandes suivantes. Pour profiter des avantages d'un compte client, vous avez la possibilité de " +
            'créer un compte client gratuit après avoir terminé votre commande.',
        paymentHeadline: '2. Paiement',
        summaryHeadline: '3. Résumé',
        nextStep: 'Continuer',
        checkOrder: 'Vérifier la commande',
        nextStepPayment: 'Continuer le paiement',
        lastStep: 'dernier pas',
        buyNow: 'Acheter',
        cart: 'Panier',
        lineItem: {
            quantity: 'Quantité',
        },
        freeDelivery: 'Cette livraison est expédiée gratuitement!',
        enterAddress:
            "Veuillez d'abord saisir vos données d'adresse," +
            'puis confirmer en cliquant sur "Vers le paiement".' +
            "\n\nÀ l'étape suivante, vous verrez les méthodes de paiement disponibles",
        addresses: {
            headline: 'données et expédition',
            customerLogin: 'connexion compte client ',
            guestOrder: 'commander en tant qu’invité /<br>créer un compte client',
            register: 'Créer un compte client',
        },
        payment: {
            paymentFee: 'frais de paiement: {price}',
            noPaymentFee: 'sans frais de paiement',
            noPaymentFeeForNachnahme: 'sans frais de paiement',
            networkError:
                `Malheureusement, nous ne pouvons pas vous proposer d'achat sur facture pour le moment.` +
                'Veuillez choisir un autre mode de paiement.',
            nachnahme: 'Nom',
            tryOtherNetworkError:
                `Malheureusement, nous ne pouvons pas vous proposer d'achat sur facture pour le moment. ` +
                'Veuillez choisir un autre mode de paiement.',
            installmentNetworkError:
                'Malheureusement, nous ne pouvons pas vous proposer de paiement échelonné pour le moment. ' +
                'Veuillez choisir un autre mode de paiement.',
            invoiceNetworkError:
                "Nous ne pouvons malheureusement pas proposer l'achat sur facture " +
                'pour cette commande. Veuillez choisir une autre option de paiement pour finaliser votre commande.',
            invoiceCheckDateOfBirth:
                "Les données que vous avez transmises n'ont pas pu être vérifiées avec succès. " +
                "Veuillez contrôler les données saisies pour la date de naissance et vous assurer qu'elles " +
                'sont correctes et complètes.',
            invoiceAddressError:
                "Veuillez noter que l'adresse de livraison et l'adresse de facturation " +
                "doivent être identiques pour l'identification lors de l'achat sur facture. " +
                "Vos données d'adresse actuelles ne répondent pas à cette exigence, c'est pourquoi l'achat sur " +
                "facture n'est pas possible. " +
                'Veuillez choisir une autre option de paiement pour finaliser votre commande.',
            genericError:
                'Le paiement a échoué. Vérifiez vos détails de facturation et de paiement avant de ' +
                'réessayer ou utilisez un autre mode de paiement.',
            unavailable: 'moyen de paiement momentanément indisponible',
            invoice: 'achat sur facture',
            invoiceoffline: 'achat sur facture',
            mastercard: 'Mastercard',
            paypal: 'PayPal',
            paypalexpress: 'PayPal Express',
            postfinancecard: 'PostFinance',
            visa: 'VISA',
            sofortueberweisung: 'virement instantané',
            installment: 'Paiement échelonné',
            installmentActivateCTA: 'Vérifiez maintenant',
            installmentCheckbox:
                'Vous acceptez la convention de paiement échelonné et pouvez télécharger le projet ' +
                'de contrat de crédit échelonné {linkStart}ici{linkEnd}.',
            afterpayinvoice: 'AfterPay Riverty',
            idealr: 'iDEAL',
            mistercashR: 'Bancontact',
            unavailableShippingCountryTitle: 'Remarque',
            unavailableShippingCountry:
                'La commande ne peut pas être envoyée au lieu de livraison que vous avez choisi.',
            descriptions: {
                payPal:
                    'Paiement facile via votre compte PayPal. À la fin de votre commande, vous serez ' +
                    "directement redirigé vers PayPal. Ce n'est qu'après avoir effectué le paiement là-bas " +
                    'que votre commande est terminée.',
                invoiceOffline:
                    'Achat sur facture: Achetez facilement sur facture. Vous recevrez la facture avec ' +
                    'la livraison.',
            },
        },
        shippingAddressHeadline: ' Adresse de livraison ',
        summary: {
            headline: ' Résumé ',
            usps: {
                moneyBack: 'garantie de remboursement',
                freeDelivery: 'Livraison gratuite',
                refund: '14 jour de droit de retour',
                freeReturn: ' Retours sans frais ',
                securePayment: ' paiement sécurisé ',
            },
            subtotal: 'Sous-total',
            deliveryCost: 'Frais de livraison',
            bulkyDeliveryCost: 'Expédition de marchandises volumineuses',
            transactionFee: 'Frais de paiement',
            total: ' Montant total ',
            taxIncluded: 'TVA',
            totalSavings: 'Économies en total',
            privacy: 'Nous utilisons vos données uniquement conformément à notre {termsDetails}.',
            legal: 'En cliquant sur "acheter maintenant", vous acceptez nos {termsDetails}.',
            legalDetails: {
                privacyText: 'déclaration de confidentialité',
                privacyLink: '/protection-donnees',
                termsText: 'conditions générales de vente',
                termsLink: '/cgv',
            },
            permissionEmailPhonePost:
                'Ich stimme zu, dass meine Daten - wie in der ' +
                '<link>Datenschutzerklärung</link> beschrieben - zur Information von neuen Produkten ' +
                'und Dienstleistungen per E-Mail, Telefon oder Post verarbeitet werden. Diese Zustimmung ' +
                'kann ich jederzeit widerrufen.',
            permissionEmailPhonePostDetails: {
                link: '/Intimité',
            },
            buyNow: 'Acheter',
            deliveryTime: 'Livrable dans un délai de {time} jours.',
            newsletterSubscription:
                'Je souhaite recevoir gratuitement par e-mail la newsletter de {shopName} contenant des offres, ' +
                "des avantages et des bons d'achat. Il est possible de se désabonner à tout moment en cliquant " +
                'sur le lien figurant dans les e-mails de la newsletter.',
            comment: 'Message au livreur',
        },
        orderNotSavedYet: `Votre commande n'a pas encore été enregistrée. Voulez-vous vraiment quitter ce site?`,
        resetPassword: {
            title: 'Remarque',
            textBold: 'Êtes-vous déjà client et aviez-vous déjà accès à notre ancienne boutique en ligne?',
            textNormal: '{link} maintenant et continuez à utiliser votre compte client existant.',
            textCta: 'Réinitialisez votre ancien mot de passe',
            resetButton: 'réinitialiser le mot de passe',
            closeButton: 'Conclure',
        },
        multiPage: {
            goToCartModal: {
                headline: 'Attention',
                description: 'Êtes-vous sûr de vouloir revenir à votre panier?',
                dismiss: 'Rester dans le processus de caisse',
                submit: 'Modifier le panier',
            },
            summary: {
                amount: 'Quantité: {amount}',
                billingAddress: 'Adresse de facturation',
                billingAndDelivery: 'Facture & livraison',
                change: 'changer',
                lineItemTotalPrice: 'Prix total: {price}',
                paymentType: 'Mode de paiement',
                shippingAddress: 'Adresse de livraison',
                title: 'Résumé',
                yourOrder: 'Votre commande',
            },
            headlines: {
                headline: 'Commander en toute sécurité en 3 étapes',
                address: 'Adresse de facturation',
                payment: 'Paiement',
                summary: 'Résumé',
            },
            nextButton: 'Continuer',
            checkDataButton: 'Vérifier les données',
        },
        customerClubExtensionModal: {
            title: 'Attention',
            text:
                'Vous êtes déjà membre du club clients. La carte de fidélité a été supprimée de votre panier. ' +
                'Souhaitez-vous acheter à la place une prolongation de votre adhésion existante ?',
            addToCart: 'Acheter une prolongation',
            closeButton: 'Fermer',
        },
        subscriptionInfo:
            "Pour souscrire un abonnement, vous avez besoin d'un compte client. Connectez-vous avec votre compte " +
            'client existant ou créez simplement un nouveau compte client.',
    },
    totalPrice: {
        taxIncluded: 'TVA incluse',
    },
    address: {
        sectorPlaceholder: 'Sec',
        blockPlaceholder: 'Bl',
        stairwayPlaceholder: 'Sc',
        floorPlaceholder: 'Et',
        apartmentPlaceholder: 'Ap',
        billingAddress: 'Adresse de facturation*',
        requiredField: "Tous les champs marqués d'un * sont obligatoires.",
        emailLabel: 'adresse e-mail',
        emailPlaceholder: 'Adresse E-Mail',
        emailErrorText: 'S’il vous plaît, mettez l’adresse E-Mail valide.',
        salutationLabel: 'Civilité*',
        salutationPlaceholder: 'Choisir un titre',
        salutations: {
            ms: 'Madame',
            mr: 'Monsieur',
            none: 'sans indication',
        },
        customerTypeLabel: 'Type de client',
        customerType: {
            private: 'Person privée',
            corporate: 'd’entreprise',
        },
        contactLabel: 'Contact*',
        contactPerson: 'Contact',
        contactInfo: 'Information contact',
        firstnamePlaceholder: 'Prénom',
        firstnameErrorText: 'S’il vous plaît, mettez un prénom valide.',
        lastnamePlaceholder: 'Nom',
        lastnameErrorText: 'S’il vous plaît, mettez un nom valide.',
        nameLengthErrorText: '200 caractères maximum autorisés.',
        phonePlaceholder: 'Numéro de téléphone',
        phoneErrorText: ` 'S’il vous plaît, mettez le numéro de téléphone suivant {examplePhoneNumber}.`,
        company: 'd’entreprise',
        companyPlaceholder: 'd’entreprise',
        companyErrorText: 'S’il vous plaît, mettez d’entreprise valide.',
        vatIdPlaceholder: 'TCA',
        vatIdErrorText: 'S’il vous plaît, mettez numéro d’identification TVA valide.',
        address: ' Adresse ',
        streetPlaceholder: 'Rue',
        streetErrorText: 'S’il vous plaît, mettez la rue valide.',
        streetLengthErrorText: '100 caractères maximum autorisés.',
        streetLengthErrorTextCZ: '40 caractères maximum autorisés.',
        streetNumberPlaceholder: ' N° ',
        streetNumberErrorText: 'S’il vous plaît, mettez le numéro valide.',
        zipCodePlaceholder: ' CP ',
        zipCodeErrorText: 'S’il vous plaît, mettez le CP valide.',
        cityPlaceholder: 'Localité',
        cityErrorText: 'S’il vous plaît, mettez la localité valide.',
        country: 'Pays',
        countryLabel: 'Pays',
        countryPlaceholder: 'Choisissez un pays',
        countyLabel: 'District',
        countyPlaceholder: 'Choisissez un district',
        packingStationPlaceholder: `livraison à une station d'emballage:*`,
        packingStation: {
            yes: 'Oui',
            no: ' Non ',
        },
        shippingAddressHeadline: 'Adresse de livraison:*',
        shippingAddressPlaceholder: 'Est-ce que votre adresse de livraison ?:*',

        shippingAddress: {
            yes: 'Oui',
            no: 'Non',
        },
        dayPlaceholder: 'Jour',
        monthPlaceholder: 'Mois',
        yearPlaceholder: 'Anée ',
        birthdateTitle: 'date d’anniversaire (en option)',
        birthdatePlaceholder: '1990-01-23',
        birthdateErrorText: 'S’il vous plaît, mettez la date valide.',
        birthdateTooYoungErrorText: 'Vous devez avoir au moins 18 ans.',
        birthdateDayErrorText: '*Invalide',
        birthdateMonthErrorText: '*Invalide',
        birthdateYearErrorText: '*Invalide',
        birthdayTitle: 'date d’anniversaire',
        birthDayError: 'L’âge ne doit pas dépasser 100 ans.',
        loqatePlaceholder: "Entrez l'adresse",
        loqateManualAddress: "Entrez l'adresse manuellement",
        loqateManualAddressHint: 'ou recherchez facilement une adresse:',
        loqateSearchAddress: 'alternative pour rechercher d‘adresse?',
    },
    checkoutSuccess: {
        headline: 'Nous vous remercions de votre commande',
        headlineError: 'Une erreur est survenue',
        textFirst: `Nous avons reçu votre commande et nous vous envoyer la commande à <link>{email}</link>.`,
        textSecond: `Dès que vos marchandises auront quitté notre entrepôt,
        vous recevrez un autre message électronique.`,
        textSecondPending:
            'votre commande est traitée. Nous vous avertissons par e-mail dès que votre commande est prête.',
        textError: 'S’il vous plaît, pour récévoir le statut de votre command, contactez notre service clients.',
        noEmailPlaceholder: 'Votre adresse E-Mail',
        newsletter: {
            headline: "Bulletin d'information",
            text:
                'Je souhaite être informé gratuitement des offres actuelles de {shopName} par le biais de ' +
                'la newsletter.',
            feedbackText:
                'Inscription à la newsletter réussie ! Vous allez bientôt recevoir un e-mail avec un lien ' +
                'pour confirmer votre adresse e-mail.',
            error: "L'inscription à la newsletter n'est actuellement pas possible.",
        },
        catalog: {
            headline: 'Catalogue',
            text:
                'Je souhaite recevoir le catalogue {shopName} gratuitement et confortablement par la poste ' +
                'à mon domicile.',
            feedbackText: 'Le catalogue sera envoyé prochainement à votre adresse.',
            error: "La commande par catalogue n'est actuellement pas possible.",
        },
    },
    county: {
        alba: 'Alba',
        arad: 'Arad',
        arges: 'Arges',
        bacau: 'Bacau',
        bihor: 'Bihor',
        bistritaNasaud: 'Bistrita Nasaud',
        botosani: 'Botosani',
        braila: 'Braila',
        brasov: 'Brasov',
        bucuresti: 'Bucuresti',
        buzau: 'Buzau',
        calarasi: 'Calarasi',
        carasSeverin: 'Caras Severin',
        cluj: 'Cluj',
        constanta: 'Constanta',
        covasna: 'Covasna',
        dambovita: 'Dambovita',
        dolj: 'Dolj',
        galati: 'Galati',
        giurgiu: 'Giurgiu',
        gorj: 'Gorj',
        harghita: 'Harghita',
        hunedoara: 'Hunedoara',
        ialomita: 'Ialomita',
        iasi: 'Iasi',
        ilfov: 'Ilfov',
        maramures: 'Maramures',
        mehedinti: 'Mehedinti',
        mures: 'Mures',
        neamt: 'Neamt',
        olt: 'Olt',
        prahova: 'Prahova',
        salaj: 'Salaj',
        satuMare: 'Satu Mare',
        sibiu: 'Sibiu',
        suceava: 'Suceava',
        teleorman: 'Teleorman',
        timis: 'Timis',
        tulcea: 'Tulcea',
        valcea: 'Valcea',
        vaslui: 'Vaslui',
        vrancea: 'Vrancea',
    },
    country: {
        select: 'changer de pays',
        AT: 'L’Autriche',
        DE: 'Allemagne',
        CH: 'Suisse',
        LI: 'Liechtenstein',
        HU: 'Hongrie',
        IT: 'Italie',
        BE: 'Belgique',
        NL: 'Pays-Bas',
        BG: 'Bulgarie',
        HR: 'Croatie',
        CY: 'Chypre',
        CZ: 'République tchèque',
        DK: 'Danemark',
        EE: 'Estonie',
        FI: 'Finlande',
        FR: 'France',
        GR: 'Grèce',
        IE: 'Irlande',
        LV: 'Lettonie',
        LT: 'Lituanie',
        LU: 'Luxembourg',
        MK: 'Macédoine',
        MT: 'Malte',
        PL: 'Pologne',
        PT: 'Portugal',
        RO: 'Roumanie',
        SM: 'Saint-Marin',
        SK: 'Slovaquie',
        SI: 'Slovénie',
        ES: 'Espagne',
        SE: 'Suède',
    },
    socialMedia: {
        facebook: 'Partager sur Facebook',
        twitter: 'Partager sur Twitter',
        whatsapp: 'Partager sur Whatsapp',
        native: 'Partager',
    },
    productListing: {
        noProducts: 'Aucun produit trouvé',
        products: '{count} {count, plural, one {produit} other {Des produits}}',
        loadMore: 'charger plus',
        from: 'de',
        articles: 'article',
        title: '{categoryName} | {shopName}{count, plural, one {} other { - page {count}}}',
    },
    pagination: {
        ariaPage: 'Page {pageNumber}',
        next: 'Continuer',
        previous: 'Revenir',
    },
    menu: {
        logout: 'Déconnecter',
        logoutHeader: 'attention',
        logoutText: 'voulez-vous vraiment vous déconnecter?',
        logoutButtonText: 'effacer',
    },
    login: {
        password: 'mot de passe',
        submitButtonLabel: 'Se connecter en toute sécurité',
        emailPlaceholder: 'adresse e-mail',
        passwordLabel: 'entrer le mot de passe',
        usernameEmptyError: 'le nom d’utilisateur ne doit pas être vide',
        usernameEmailError: 'le nom d’utilisateur doit être une adresse e-mail valide',
        userLoginError: 'nom d’utilisateur ou mot de passe incorrect',
        passwordForgotten: 'vous avez oublié votre mot de passe?',
        loginSuccess: 'Vous êtes connecté',
        passwordEmptyError: 'le mot de passe',
        existingUser: 'je suis déjà client, me connecter',
        resetPassword: 'réinitialiser le mot de passe',
        resetEmailSent: 'nous avons envoyé un e-mail à votre adresse (e-mail)',
        resetErrorQuestions: 'vous n’avez pas reçu de message?',
        resetErrorAnswerOne: 'vérifier s’il vous plaît vos spams',
        resetErrorAnswerTwo: 'veuillez vérifier vos données une nouvelle fois',
        resetErrorAnswerThree: 'attendez s’il vous plaît 15 minutes avant de réessayer',
        newPassword: 'nouveau mot de passe',
        passwordNotMatchingReq: 'le mot de passe ne semble pas correspondre aux critères',
        passwordsNotIdentical: 'Les nouveaux mots de passe ne correspondent pas',
        repeatPassword: 'confirmer le mot de passe',
        changePassword: 'changer le mot de passe',
        passwordChanged: 'Le mot de passe a été changé avec succès',
        emailConfirmed: 'E-mail confirmé. Vous pouvez maintenant vous connecter',
        processToken: "Une erreur s'est produite lors du traitement du jeton, veuillez réessayer plus tard",
        generalError: 'Il y avait une erreur. Veuillez réessayer plus tard',
        authorizeStoreTitle: 'Lier le compte client',
        authorizeStoreText: 'Souhaitez-vous utiliser votre compte client existant pour {shopName}?',
        yes: 'Oui',
        no: 'Non',
        emailUnconfirmed: "Veuillez d'abord confirmer votre adresse e-mail.",
    },
    orders: {
        order: 'commande',
        total: 'somme',
        details: 'voir les détails',
        back: 'revenir',
        shippingAddress: 'Adresse de livraison',
        paymentMethod: 'Mode de paiement',
        paymentMethodNotApplicable: 'mode de paiement invalide',
        quantity: 'quantité: {quantity}',
        reorder: 'racheter',
        reOrderSuccess: 'Article ajouté avec succès',
        reOrderFail: 'L’article n’a pas pu être ajouté au panier',
        date: 'Date',
        progress: '{items} sur {total} commandes',
    },
    customer: {
        mr: 'Monsieur',
        mrs: 'Madame',
        customerNumber: 'Numéro client',
        billingAddress: 'Adresse de facturation',
        email: 'Adresse e-mail',
        phone: 'Téléphone',
        password: 'Mot de passe',
        passwordText: 'le mot de passe ne peut être affiché pour des raisons de sécurité',
        passwordLinkText: 'oublié le mot de passe?',
        tokenNotReceived: 'cette adresse e-mail n’existe pas dans nos donnnées',
        emailInvalid: 'Adresse e-mail non conforme',
        dataUpdated: 'Données mises à jour',
        wrongPassword: "Le mot de passe n'a pas pu être modifié",
        couldNotResetPassword: 'Réinitialisation du mot de passe impossible.',
        customerClub: {
            cardNumber: 'Numéro de carte : {number}',
            validFrom: 'Membre depuis : {date}',
            validThru: 'Valable jusqu’au: {date}',
            expiryWarning:
                'Attention : votre carte perdra sa validité dans {days} {days, plural, one {jour} other {jours}}.',
            cardExpired: 'Attention : votre carte est expirée.',
            offerCardRenewal: 'Commander une nouvelle carte',
        },
        customerCardLink: {
            headline: 'Reliez votre compte en ligne à votre carte de club',
            subHeadline:
                'Profitez également des avantages du club clients lors de vos achats dans notre boutique en ligne.',
            cardNumber: 'Numéro de carte',
            clubEmail: 'Adresse e-mail du club clients',
            cta: 'Relier',
            success: 'Votre carte de club client a été associée avec succès à votre compte.',
            error: {
                mykeneNotFound: "Aucune carte de club client n'a pu être trouvée avec le numéro indiqué.",
                mykeneInvalidCombination: "L'adresse e-mail ne correspond pas à la carte du club client.",
                mykeneError: "Une erreur s'est produite. Veuillez réessayer plus tard.",
                cardExistsForStore: 'Votre carte club client a déjà été ajoutée à un compte.',
                unknownError: "Une erreur s'est produite. Veuillez réessayer plus tard.",
            },
        },
    },
    register: {
        passwordEmptyError: 'Le mot de passe ne doit pas être vide',
        passwordMinError: 'Le mot de passe doit contenir au moins 8 caractères',
        passwordNotValidError: 'Au moins 1 chiffre, 1 lettre majuscule, 1 lettre minuscule',
        passwordConfirmationError: 'Les mots de passe sont différents',
        passwordLabel: 'Créez un mot de passe',
        passwordConfirmationLabel: 'Répétez le mot de passe',
        passwordCriteriaHeader: 'Votre mot de passe doit ...',
        passwordCriteria: {
            numberOfCharacters: '✔ comporter au moins 8 caractères',
            hasNumber: '✔ contenir au moins 1 chiffre',
            hasUppercaseLetter: '✔ contenir au moins 1 lettre majuscule',
            hasLowercaseLetter: '✔ contenir au moins 1 lettre minuscule',
        },
        acceptGDPRError: 'Vous devez accepter la déclaration de confidentialité.',
        nextStepPayment: 'Continuer le paiement',
        error: 'Malheureusement une erreur est survenue, veuillez réessayer plus tard',
        successMessage: "Succès de l'inscription! Veuillez d'abord confirmer votre adresse e-mail.",
        mainError: 'Merci de corriger les erreurs!',
        change: 'CHANGER',
        accountVerificationTitle: 'Vérification du compte',
        accountVerificationText:
            'Vous recevrez sous peu un e-mail contenant un lien ' +
            "pour activer votre compte client. Veuillez noter que ce lien n'est valable que 24 heures. " +
            'Les comptes clients non vérifiés seront automatiquement supprimés après 24 heures.',
        accountVerificationCTA: 'OK',
        existingAccountModal: {
            text:
                "Cette adresse e-mail est déjà utilisée. Si vous avez un compte pour l'une des boutiques " +
                "suivantes, veuillez vous connecter avec vos données d'accès connues.",
            shops: '<ul><li>haus-hobby.ch</li><li>maison-hobby.ch</li><li>haus-hobby.com</li></ul>',
            accept: 'Connexion',
            close: 'Fermeture',
        },
        newsletterSubscription: 'Je souhaite être informé des offres actuelles de {shopName}.',
    },
    countrySwitcher: {
        headline: 'Adapter la langue et les options de livraison',
        country: 'Livraison en',
        language: 'Langue',
        button: 'Enregistrer',
        modal: {
            headline: 'Attention',
            description:
                'Vous êtes en train de changer votre région en {newSelectedCountry}. Pour que vous puissiez ' +
                "commander quelque chose, votre adresse d'expédition doit se trouver dans cette région. Si vous ne " +
                'payez pas dans la devise de la région, votre banque peut vous facturer des frais de change. Tous ' +
                'les articles de votre panier seront supprimés.',
            submitText: 'Continuer',
            closeText: 'Annuler',
        },
    },
    subcategoryFilter: {
        all: 'Tous',
        reset: 'Supprimer la catégorie',
    },
    personalInfo: {
        newEmail: 'Nouvelle adresse e-mail',
        newEmailConfirm: 'Confirmez nouvelle adresse e-mail',
        passwordConfirm: 'Confirmez le mot de passe',
        currentPassword: 'Mot de passe actuel',
        newPassword: 'Nouveau mot de passe',
        newPasswordConfirm: 'Confirmer le nouveau mot de passe',
        actualEmail: 'Adresse e-mail actuelle',
    },
    newsletter: {
        inputPlaceholder: 'Adresse e-mail',
        success: 'Vous recevrez sous peu un e-mail contenant un lien de confirmation.',
        invalidEmail: "S'il vous plaît, mettez une adresse email valide.",
        error: "Malheureusement, il y a eu un problème d'inscription à la newsletter.",
        emailEmptyError: 'Veuillez entrer une adresse courriel.',
        registered: 'Vous êtes inscrit à la newsletter.',
        notRegistered: "Vous n'êtes pas abonné à la newsletter.",
        subscribe: "S'abonner",
        unsubscribe: 'Se désabonner',
        alreadySubscribed: "l'adresse e-mail est déjà abonnée à la newsletter",
        successAccount: 'Vous avez réussi à vous désinscrire de la newsletter.',
        alreadyRegisteredQuestion: 'Déjà enregistré ? ',
        unsubscribeHere: ' Vous pouvez vous désabonner ici',
        successUnsubscribe: 'Vous avez été désabonné avec succès',
        unsubscribeError: "Une erreur s'est produite. Veuillez réessayer plus tard.",
    },
    cartChangeModal: {
        headline: 'Attention',
        description: 'Le panier a été mis à jour, veuillez sélectionner à nouveau le mode de paiement.',
        submitText: 'OK',
    },
    infoBanner: {
        closeButton: 'Fermer',
    },
    slider: {
        prevButton: 'Revenir',
        nextButton: 'Prochain',
        activePagination: 'diapositive active',
        inactivePagination: 'diapositive inactive',
    },
    specialDeal: {
        days: '{count, plural, one {Jour} other {Jours}}',
        hours: 'Heu',
        minutes: 'Min.',
        seconds: 'Sec.',
        specialDealOver: 'Cette action est malheureusement terminée.',
    },
    userManuals: {
        headline: 'Manuels',
        search: "Recherche par produit, numéro d'article ou code",
        resetSearch: 'Réinitialiser la recherche',
        table: {
            sku: "Numéro de l'article",
            code: 'Code',
            productTitle: 'Produit',
            type: 'Taper',
            types: {
                /* eslint-disable camelcase */
                instruction_manual: "Mode d'emploi",
                recipe_booklet: 'Livret de recettes',
                training_plan: "Plan d'entraînement",
                nutrition_plan: 'Plan alimentaire',
                /* eslint-enable camelcase */
            },
        },
        noResults: 'Aucun manuel trouvé.',
    },
    price: {
        from: 'dès\u00a0{price}',
        reference: '{price}\u00a0/\u00a0{baseUnitMultiplier}\u00a0{referenceUnit}',
        statt: 'Au lieu de {price}',
        uvp: 'PVC\u00a0{price}',
    },
    emarsysFieldWriter: {
        success: 'Le changement a été effectué avec succès.',
        error: "Une erreur s'est produite. Veuillez réessayer plus tard.",
    },
    subscription: {
        amount: 'Quantité:',
        deliveryInterval: 'Intervalle de livraison:',
        nextDelivery: 'Prochaine livraison:',
        ordered: 'Commandé:',
        status: 'Statut:',
        cancelSubscription: "Annuler l'abonnement",
        state: {
            active: 'Actif',
            paused: 'En pause',
            cancelled: 'Quitter',
            problems: 'Problèmes',
        },
        intervalUnit: {
            day: '{amount} {amount, plural, one {Jour} other {Jours}}',
            month: '{amount} {amount, plural, one {Mois} other {Mois}}',
            year: '{amount} {amount, plural, one {Année} other {Années}}',
        },
    },
};
