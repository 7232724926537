import OriginalPrice from '@mediashop/catalog-base/pattern/atom/OriginalPrice';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { Variant } from '@mediashop/app/api/types/ClientProduct';
import Badge from '@mediashop/catalog-base/pattern/atom/Badge';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { BadgeObject } from '@mediashop/catalog-base/types/badges';
import { Price } from '@mediashop/base/pattern/molecule/Price';
import { Money } from '@mediashop/app/api/types/Money';
import { useCheaperSet } from '@mediashop/app/hooks/useCheaperSet';

type SpecialDealPriceBoxProps = BaseProps & {
    className: string;
    activeVariant: Variant;
    showSavings?: boolean;
    hasVariants?: boolean;
    detailBadges?: BadgeObject[];
    topBadges: BadgeObject[];
    showCheapestPrice: boolean;
    showFrom: boolean;
};

export default function SpecialDealPriceBox({
    className,
    activeVariant,
    detailBadges,
    topBadges,
    showCheapestPrice,
    showFrom,
}: SpecialDealPriceBoxProps): JSX.Element {
    const savingsBadge = detailBadges?.find((badge) => badge.type === 'SAVINGS');
    const saleBadge = topBadges?.find((badge) => badge.type === 'SPARSET');

    const { showCheaperSetPrice, cheaperSetPrice } = useCheaperSet(activeVariant);

    let price: Money;
    if (showCheaperSetPrice && cheaperSetPrice) {
        price = cheaperSetPrice;
    } else {
        price =
            showCheapestPrice && activeVariant?.cheapestPrice
                ? activeVariant?.cheapestPrice ?? activeVariant?.price
                : activeVariant?.price;
    }
    return (
        <div className={`${className}__infos`}>
            {savingsBadge ? <Badge key={savingsBadge.badgeProps.text} {...savingsBadge.badgeProps} /> : SKIP_RENDER}

            {saleBadge && !savingsBadge ? (
                <Badge key={saleBadge.badgeProps.text} {...saleBadge.badgeProps} style="savings-special-deal" />
            ) : (
                SKIP_RENDER
            )}

            <div className={`${className}__price`}>
                <Price price={price} showFrom={showFrom && activeVariant?.existsOtherThenCheapestPrices} />
            </div>

            <OriginalPrice
                originalPrice={activeVariant.originalPrice}
                price={activeVariant.price}
                className={`${className}__old-price`}
            />
        </div>
    );
}
