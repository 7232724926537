import { lazy, ReactElement } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const JotformLazy = lazy(() => lazyRetry(() => import('./Jotform')));

export type JotformProps = BaseProps & {
    formId: string;
};

const Jotform = (props: JotformProps): ReactElement => (
    <LazyComponent>
        <JotformLazy {...props} />
    </LazyComponent>
);

export default Jotform;
