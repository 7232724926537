import { ProductWithActiveVariant, Variant } from '@mediashop/app/api/types/ClientProduct';

export const getVariantWithPrice = (product: ProductWithActiveVariant): Variant | undefined =>
    product.activeVariant ?? product?.variants?.find((variant) => variant.price);

export const getVariant = (product: ProductWithActiveVariant): Variant | undefined =>
    getVariantWithPrice(product) ?? product?.variants?.[0];

export const getSearchParam = (product: ProductWithActiveVariant): string =>
    product.activeVariant?.sku ? `variant=${product.activeVariant.sku}` : '';
