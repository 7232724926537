import { Category } from '@mediashop/app/api/types/ClientCategory';
import { getCategorySlugsFromUrl } from '@mediashop/app/helper/categoryHelpers';
import findCategoryById from '@mediashop/app/helper/findCategoryById';
import { SlugProductEntity } from '@mediashop/app/store/reducer/product';

/**
 * Function to replace the current category slugs in the path name with slugs for the new locale
 * @param params required data for replacement
 * @returns path with new slugs
 */
export const getTargetCategoryPath = ({
    categories,
    currentLocale,
    nextLocale,
    pathname,
    selectedCategoryId,
}: {
    categories: Category[];
    currentLocale: string;
    nextLocale: string;
    pathname: string;
    selectedCategoryId: string;
}): string => {
    let nextPathname = pathname;

    const selectedCategory = findCategoryById(categories, selectedCategoryId);
    const slugAllLocales = selectedCategory?.slugAllLocales;

    const url = new URL(window.location.href);
    const slugs = getCategorySlugsFromUrl(url);

    // Replace subcategory slug
    if (slugs?.subcategorySlug) {
        const parentCategorySlugs = findCategoryById(categories, selectedCategory?.parentId ?? '')?.slugAllLocales;
        const subCategorySlugs = findCategoryById(categories, selectedCategory?.id ?? '')?.slugAllLocales;

        if (parentCategorySlugs) {
            nextPathname = nextPathname.replace(parentCategorySlugs[currentLocale], parentCategorySlugs[nextLocale]);
        }

        if (subCategorySlugs) {
            nextPathname = `${nextPathname}${url.search}`;
        }
    }

    // Replace category slug
    if (slugAllLocales) {
        nextPathname = nextPathname.replace(slugAllLocales[currentLocale], slugAllLocales[nextLocale]);
    }

    return nextPathname;
};

/**
 * Function to replace the current product slug in the path name with the slug for the new locale
 * @param params required data for replacement
 * @returns path with new slug
 */
export const getTargetProductPath = ({
    currentLocale,
    nextLocale,
    pathname,
    product,
}: {
    currentLocale: string;
    nextLocale: string;
    pathname: string;
    product?: SlugProductEntity;
}): string => {
    let nextPathname = pathname;

    const slugAllLocales = product?.product?.slugAllLocales;
    if (slugAllLocales) {
        nextPathname = nextPathname.replace(slugAllLocales[currentLocale], slugAllLocales[nextLocale]);
    }

    return nextPathname;
};
