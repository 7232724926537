import { PayPalButtons } from '@paypal/react-paypal-js';
import LoadingIndicator from '@mediashop/base/pattern/atom/LoadingIndicator';
import { useInitiateExpressCheckout } from '@mediashop/app/queries/checkout/useInitiateExpressCheckout';
import { useSetAddressExpressCheckout } from '@mediashop/app/queries/checkout/useSetAddressExpressCheckout';
import { usePayPal } from '@mediashop/app/payment/payPal/usePayPal';
import { usePaymentMethodAllowed } from '@mediashop/app/payment/hooks/usePaymentMethodAllowed';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useCartContainsSubscription } from '@mediashop/app/hooks/useCartContainsSubscription';
import { useNavigate } from '@mediashop/app/hooks/useNavigate';

const componentName = 'paypal-express';

interface PayPalExpressProps {
    disabled?: boolean;
}

export default function PayPalExpress({ disabled = false }: PayPalExpressProps): JSX.Element | null {
    const navigate = useNavigate();

    const isAllowedPaymentMethod = usePaymentMethodAllowed('PAYPAL_EXPRESS');
    const cartContainsSubscription = useCartContainsSubscription();

    const { isLoading, isResolved } = usePayPal({ isEnabled: isAllowedPaymentMethod });

    const { mutateAsync: initExpressCheckout } = useInitiateExpressCheckout();
    const { mutateAsync: setAddress } = useSetAddressExpressCheckout();

    if (!isLoading && !isResolved) {
        return SKIP_RENDER;
    }

    if (!isAllowedPaymentMethod || cartContainsSubscription) {
        return SKIP_RENDER;
    }

    return !isResolved ? (
        <LoadingIndicator className={componentName} />
    ) : (
        <div className={componentName}>
            <PayPalButtons
                style={{ layout: 'vertical', shape: 'rect', color: 'white', height: 39, label: 'checkout' }}
                disabled={disabled}
                fundingSource="paypal"
                createOrder={async () => {
                    const { payPalOrderId } = await initExpressCheckout();
                    return payPalOrderId;
                }}
                onApprove={async () => {
                    await setAddress();
                    navigate('/checkout');
                }}
            />
            <PayPalButtons
                style={{ layout: 'vertical', shape: 'rect', color: 'white', height: 39, label: 'checkout' }}
                disabled={disabled}
                fundingSource="paylater"
                createOrder={async () => {
                    const { payPalOrderId } = await initExpressCheckout();
                    return payPalOrderId;
                }}
                onApprove={async () => {
                    await setAddress();
                    navigate('/checkout');
                }}
            />
        </div>
    );
}
