import Theme from '@mediashop/app/Theme';
import VerticalBrandsTheme from '@mediashop/vertical-brands-base';
import './pattern/template/Meta/index.tsx';
import icons from './icons';

const PowerXlTheme: Theme = {
    name: 'powerxl',
    extends: VerticalBrandsTheme,
    icons,
};

export default PowerXlTheme;
