import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxImageSetImpl, BrxUsp } from '@mediashop/app/bloomreach/types';
import { Money } from '@mediashop/app/api/types/Money';
import { Cart, CartLineItem, CustomLineItem, ShippingInfo } from '@mediashop/app/api/types/ClientCart';
import { DiscountCodeItem, TotalPriceDiscount } from '@mediashop/app/api/types/Discount';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { InfoBannerCustomProps } from '@mediashop/base/pattern/molecule/InfoBannerCustom';

const CartSummaryMolecule = lazy(() => lazyRetry(() => import('./CartSummary')));

export type CartSummaryProps = BaseProps & {
    shippingInfo?: ShippingInfo;
    productTotal: Money;
    totalPrice: Money;
    isSticky?: boolean;
    customLineItems: CustomLineItem[];
    lineItems: CartLineItem[];
    discountCodes: DiscountCodeItem[];
    discountOnTotalPrice?: TotalPriceDiscount;
    usps?: BrxUsp[];
    paymentLogos: BrxImageSetImpl[];
    customFields?: Cart['customFields'];
    isLoading?: boolean;
    customerIsMember?: boolean;
    handleToCheckoutClick: () => void;
    showCheckoutButton?: boolean;
    showSummaryDetails?: boolean;
    showVoucherInput?: boolean;
    showSeparator?: boolean;
    showFooter?: boolean;
    showTotalSavings?: boolean;
    showClubCardMemberSavings?: boolean;
    cartMarketingInfo?: InfoBannerCustomProps;
    boxShadow?: boolean;
};

const CartSummary = (props: CartSummaryProps) => (
    <LazyComponent>
        <CartSummaryMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.CartSummary', CartSummary, 'catalog-base');
