export default function UndefinedContainer({ component }: { component: any }) {
    // eslint-disable-next-line no-console
    console.warn(
        `Component ${component?.model?.label} is not defined.\n` +
            `Please add it to "frontend/themes/{themeName}/bloomreach/index.tsx"`
    );
    return (
        <div className="container-undefined container-wrapper">Component {component?.model?.label} is not defined.</div>
    );
}
