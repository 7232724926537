import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import { BackgroundProps, BaseProps, BrxRelationType } from '@mediashop/app/bloomreach/types';
import { ProductWithActiveVariant, ProductWithDeeplink } from '@mediashop/app/api/types/ClientProduct';
import { CroppedImageVariant } from '@mediashop/base/types/imageVariants';
import getInstallmentPrice from '@mediashop/catalog-base/utils/getInstallmentPrice';
import { useBadges } from '@mediashop/catalog-base/hooks/useBadges';
import ProductImage from './ProductImage/ProductImage';
import { getSearchParam, getVariant } from './helper';
import ProductData from './ProductData/ProductData';
import { useSetPartnerId } from '@mediashop/app/hooks/api/useSetPartnerId';
import { useNavigate } from '@mediashop/app/hooks/useNavigate';

const componentName = 'product';
const productTop = `${componentName}__product-top`;

type ProductProps = BaseProps & {
    product: ProductWithActiveVariant & ProductWithDeeplink;
    status: string;
    backgroundColor?: BackgroundProps;
    backgroundColorHex?: string;
    dealOfTheDay?: boolean;
    description?: string;
    disabled?: boolean;
    imageVariant?: CroppedImageVariant;
    partnerId?: string;
    productRelationType?: BrxRelationType;
    roundedImages?: boolean;
    handleDisableAllButtons?: (disable: boolean) => void;
    onClick?: () => void;
};

function Product({
    backgroundColor = 'default',
    backgroundColorHex,
    className,
    dealOfTheDay = false,
    description,
    disabled = false,
    imageVariant,
    partnerId: nextPartnerId,
    product,
    productRelationType,
    roundedImages,
    status,
    handleDisableAllButtons,
    onClick,
}: ProductProps) {
    const { mutateAsync: setPartnerId } = useSetPartnerId();
    const navigate = useNavigate();
    const currentVariant = getVariant(product);

    const priceForInstallmentPrice = currentVariant?.cheapestPrice;
    const installmentPrice = currentVariant && getInstallmentPrice(priceForInstallmentPrice ?? currentVariant?.price);

    // we only get the first 2 badges
    const { topBadges, bottomBadges, detailBadges, hasSaleBadge } = useBadges({
        product,
        variant: currentVariant,
        installmentPrice,
    });

    const goToProduct = (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (product.deepLink) {
            window.location.href = product.deepLink!;
            return;
        }

        if (nextPartnerId) {
            setPartnerId(nextPartnerId);
        }

        onClick && onClick();

        navigate({
            pathname: `p/${product?.slug}`,
            search: getSearchParam(product),
        });
    };

    return (
        <div
            onClick={goToProduct}
            className={classNames(componentName, className, {
                [`${componentName}--${backgroundColor}`]: backgroundColor && !backgroundColorHex,
                [`background--${backgroundColor}`]: backgroundColor && !backgroundColorHex,
            })}
            style={backgroundColorHex ? { backgroundColor: backgroundColorHex } : {}}
        >
            <div className={productTop}>
                <ProductImage
                    imageVariant={imageVariant}
                    product={product}
                    roundedImages={roundedImages}
                    topBadges={topBadges}
                    bottomBadges={bottomBadges}
                />
            </div>

            <ProductData
                product={product}
                status={status}
                backgroundColor={backgroundColor}
                className={className}
                dealOfTheDay={dealOfTheDay}
                description={description}
                disabled={disabled}
                goToProduct={goToProduct}
                handleDisableAllButtons={handleDisableAllButtons}
                partnerId={nextPartnerId}
                productRelationType={productRelationType}
                hasSaleBadge={hasSaleBadge}
                detailBadges={detailBadges}
            />
        </div>
    );
}

export default injectComponent('pattern.molecule.Product', Product);
