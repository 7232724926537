import AvailableContentSpace from '@mediashop/catalog-base/pattern/molecule/AvailableContentSpace';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import ContentWrapper from '../ContentWrapper';
import BackgroundColorWrapper from '../BackgroundColorWrapper';
import {
    BackgroundProps,
    BaseProps,
    BrxAnchor,
    BrxTextCompound,
    HeadlineStyleProps,
} from '@mediashop/app/bloomreach/types';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import Headlines, { HeadlineProps } from '../Headlines';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import TextCompound from '../TextCompound';
import { emptyDesktopTextCompound, isTextCompoundAvailable } from '../TextCompound/functions';

const componentName = 'text';

export type TextProps = BaseProps & {
    anchor?: BrxAnchor;
    availableContentSpace: string;
    headline: string;
    headlineStyle: HeadlineStyleProps;
    background: BackgroundProps;
    alignment: 'left' | 'right' | 'center' | 'justify';
    headlines?: HeadlineProps;
    backgroundColorHex?: string;
    textCompound: BrxTextCompound;
    noBottomPadding: boolean;
};

/**
 * @param anchor anchor id for contentNavigation
 * @param availableContentSpace Available width for content
 * @param headline Headline text to be displayed
 * @param headlineStyle Style / variant of the headline (h1, h2, ...)
 * @param content html text content
 * @param background background style
 * @param alignment alignment of text
 * @returns {JSX.Element}
 * @constructor
 */
// eslint-disable-next-line complexity
function Text({
    anchor,
    availableContentSpace,
    headline,
    headlineStyle,
    background = 'primary',
    alignment = 'left',
    headlines,
    backgroundColorHex,
    textCompound,
    noBottomPadding,
}: TextProps): JSX.Element | null {
    const CustomTag = headlineStyle?.toLowerCase() || 'h2';

    const [device, setDevice] = useState('desktop');
    const deviceType = useDeviceType();

    useEffect(() => {
        setDevice(deviceType);
    }, [deviceType]);

    const isMobileDevice = device === 'mobile';

    const isDesktopTextEmpty = emptyDesktopTextCompound(isMobileDevice, textCompound);
    if (
        (!isTextCompoundAvailable(textCompound) && !headlines?.headline) ||
        (isDesktopTextEmpty && !headlines?.headline)
    ) {
        return SKIP_RENDER;
    }

    return (
        <BackgroundColorWrapper hexBackgroundColor={backgroundColorHex} backgroundColor={background}>
            <ContentWrapper id={anchor?.id}>
                <AvailableContentSpace contentSpace={availableContentSpace}>
                    <div
                        className={classNames(componentName, {
                            [`${componentName}--no-bottom-padding`]: noBottomPadding,
                        })}
                    >
                        {headlines?.headline ? (
                            <Headlines className={`${componentName}__headline`} {...headlines} />
                        ) : (
                            headline && (
                                <div className={`${componentName}--align-${alignment}`}>
                                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                    {/* @ts-ignore  */}
                                    <CustomTag className={`${componentName}__headline`}>{headline}</CustomTag>
                                </div>
                            )
                        )}
                        <TextCompound {...textCompound} className={`${componentName}__content`} />
                    </div>
                </AvailableContentSpace>
            </ContentWrapper>
        </BackgroundColorWrapper>
    );
}

export default injectComponent('pattern.atom.Text', Text);
