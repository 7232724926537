/* eslint-disable id-length */
export default {
    test: 'Hallo Test auf Deutsch',
    account: {
        test: 'Hallo {name} auf Deutsch im Account',
        subscribeButton: 'Abonați-vă la newsletter',
        unSubscribeButton: 'Dezabonare newsletter',
        unsubscribeSuccess: 'V-ați dezabonat cu succes de la newsletter.',
    },
    deal: {
        days: 'Z',
        hours: 'O',
        minutes: 'M',
        seconds: 's',
        dealOver: 'Oferta a expirat.',
        countdownEnd: 'Această ofertă se încheie:',
    },
    mainNavigation: {
        greeting: 'Bună ziua, {name}',
        account: 'Contul meu',
        logout: 'Deconectați-vă',
        search: 'Căutare produs',
        loggedout: 'Delogat!',
        searchSuggestion: 'Sugestii de căutare',
        productSuggestion: 'Sugestii de produse',
        showResults: '{count, plural, =1 {{text} Afișați rezultatul} other {Afișați toate rezultatele {text}}}',
        resultSearch: '{count} {count, plural, one {Rezultat} other {Rezultate}} pentru "{query}"',
        logoLinkLabel: 'Pagina principală',
        accountLinkLabel: 'Contul de client',
        content: 'Cuprins',
    },
    product: {
        cheaperSet: 'prețul la 2. doar ',
        setTypeXplusY: 'prețul la 2. doar ',
        quantityAdvantage: 'Avantaj cantitativ: ',
        quantityAdvantageActive: 'Reducere pentru cantități!',
        ratingCount: '{count} {count, plural, one {Recenzie} other {Recenzii}}',
        unitPriceSuffix: '\u202f/\u202f{baseUnitMultiplier}\u00a0{referenceUnit}',
        priceHint: 'Prețul include TVA-ul.',
        deliveryHint: 'Informații privind costurile de livrare și taxele de tranzacție',
        savedMoneySuffix: 'Economie',
        savedMoneyBadge: 'Economie',
        buyNow: 'Comandați acum',
        addToCart: 'Adăugați în coș',
        goToPdp: 'Descrierea produsului',
        showDetails: 'Detalii',
        descriptionShowMore: 'Afișați mai multe...',
        descriptionShowLess: 'Afișați mai puțin...',
        detailsShowMore: 'Mai multe detalii',
        detailsShowLess: 'Mai puține detalii',
        variantSelectorTitle: 'Alegeți o opțiune:',
        emptyVariant: 'Alegeți o opțiune',
        chooseVariant: ' selectați',
        emptyVariantError: 'Vă rugăm să selectați',
        suggestedPriceShort: 'RRP',
        uvp: 'RRP',
        statt: 'în loc de',
        variantSize: 'Mărime',
        variantColor: 'Culoare',
        badges: {
            installments: '{count}x {count, plural, one {detalii} other {detalii}} {price}',
            installmentsVariants: 'de la {count}x {count, plural, one {detalii} other {detalii}} {price}',
            installmentsVariantsAsAlternative:
                'sau din {count}x {count, plural, one {detalii} other {detalii}} {price}',
            installmentsAsAlternative: 'sau {count}x {count, plural, one {detalii} other {detalii}} {price}',
            installmentsInFinalSummary: '{count}x {count, plural, one {detalii} other {detalii}}',
            installmentsInCart: '{count}x {count, plural, one {detalii} other {detalii}} {price}',
            xplusygratis: '{text} GRATIS',
            savings: '{count}% reducere',
            savingsPDP: '-{count}%',
            deal: 'REDUCERE -{count}%',
        },
        socialMediaShareHeadline: '{shopName}',
        socialMediaShareText: 'Priviți ce am găsit:',
        attributes: {
            headline: 'Informații despre produs',
            subheadline: 'Date tehnice',
            true: 'Da',
            false: 'Nu',
            maxOrderQuantity: 'Cantitatea maximă de comandă',
            deliverables: 'Servicii',
            name: 'Nume',
            description: 'Descriere',
            usp: 'USP',
            productDetails: 'Detalii despre produs',
            claim: 'Claim',
            weightGross: 'Greutate',
            articleType: 'Tipul produsului',
            articleIsBuyable: 'Disponibil',
            erpCategory1: 'Categorie',
            erpCategory2: 'Subcategorie',
            crmArticleBundleNo: 'Artikelbündel Nr.',
            webSubtitle: 'Subtitrări web',
            countryLicensesAvailable: 'Disponibilitate în țară',
            articleDimensions: 'Dimensiuni',
            textilMaterialComposition: 'Material',
            relatedUpsell: 'Upsell',
            relatedUpgrade: 'Accesorii',
            subscriptionAvailable: 'Abonament',
            subscriptionSelection: {
                day: '<b>Timp de livrare</b>: {amount} {amount, plural, one {Zi} other {Zile}}',
                month: '<b>Timp de livrare</b>: {amount} {amount, plural, one {Lună} other {Luni}}',
                week: '<b>Timp de livrare</b>: {amount} {amount, plural, one {Săptămâna} other {Săptămâni}}',
            },
            deliveryTimeFrom: 'Termenul minim de livrare',
            deliveryTimeUntil: 'Termenul maxim de livrare',
            medicalProduct: 'Produs de sănătate',
            productChemicalIngredients: 'Ingrediente',
            signalWord: 'Signalwort',
            ean: 'EAN',
            eanNl: 'EAN NL',
            prices: 'Preț',
            biocidalProduct: 'Produse biocide',
            shippingTimeCode: 'Cod termen de livrare',
            expiryDate: 'Data expirării',
            navBulkyItem: 'Nav Bulky Item',
            productSafety: 'Siguranța produselor',
            perDelivery: 'pe livrare',
        },
        addedToCart: 'Bună alegere! Produsul a fost adăugat.',
        cantAddToCart: 'Produsul nu a putut fi adăugat.',
        productNumber: 'Nr. art.',
        productRelation: 'S-ar putea să vă intereseze și',
        payOnce: 'sau o singură dată',
        payOnceSum: 'Preț total',
        payOnceSumMobile: 'Total',
        next: 'următorul',
        previous: 'anterior',
        deliveryProductCard: 'Timp de livrare: {from}-{until} zile lucrătoare',
        downloadFor: 'Manual descărcabil',
        topReview: 'Top Recenzii',
        seeMore: 'mai multe',
        seeLess: 'mai puțin',
        totalReviews: '{formattedCount} {count, plural, one {Recenzie} other {Recenzii}}',
        noReviews: 'Nu există niciun element de afișat.',
        videoThumbnailText: 'Video',
        subscription: {
            oneTimeDelivery: 'Achiziție unică',
            abo: 'Abonament',
            emptyOption: 'Intervalul de livrare:',
            intervalUnit: {
                day: '{amount} {amount, plural, one {Zi} other {Zile}}',
                month: '{amount} {amount, plural, one {Lună} other {Luni}}',
                year: '{amount} {amount, plural, one {An} other {Ani}}',
            },
            delivery: 'livrare',
            recommendedInterval: 'recomandat',
        },
        exceededMaxOrderQuantity: 'Cantitatea maximă de comandă ({amount}) pentru acest articol a fost depășită.',
        pageTitle: '{title}{store, select, EMPTY {} other { | {store}}}',
        productUnavailable: {
            headline: 'Produsul nu este momentan disponibil!',
            text: 'Alegeți în schimb o alternativă din gama noastră!',
        },
        bundleTitle: 'Disponibil și în set:',
    },
    preCheckout: {
        successMessage: 'Bună alegere! Produsul a fost adăugat.',
        ctaMessage: 'adăugați în coș și',
        ctaMessageBold: 'comandați fără costuri de livrare!',
        accessories: 'Accesorii utile',
        goBackBtn: 'Continuați cumpărăturile',
        goToCartBtn: 'Către coșul de cumpărături',
        addToCartSingle: 'Detalii',
        addToCart: 'Adaugați în coș',
        addToCartUpgrade: 'Actualizare',
        relations: 'Comandați imediat',
        toProduct: 'la produs',
        deliverables: 'Conținutul livrării: ',
        clubSuggestion: 'Economisiți suplimentar?',
        clubCardAlreadyInCart: 'Aveți deja un card de client în coșul de cumpărături.',
    },
    orderSuccessPage: {
        orderSuccess: 'Vă mulțumim pentru comanda dumneavoastră!',
        goToShop: 'Înapoi la magazin!',
        accountQuestion: 'Doriți să setați rapid o parolă și să beneficiați de avantajele contului de client?',
        accountAdvantagesHeader: 'Avantajele dvs. cu un cont de client:',
        accountAdvantageOne: 'Prezentare generală a tuturor comenzilor dvs',
        accountAdvantageTwo: 'Finalizarea mai rapidă a comenzilor',
        accountAdvantageThree: 'Gestionarea datelor clienților dumneavoastră',
        password: 'Parolă',
        passwordReq: 'min. 8 caractere',
        passwordReqDescription: 'Introduceți o parolă!',
        passwordReqDescTitle: 'Parola dvs. trebuie să conține:',
        passwordReqDescOne: 'o lungime de cel puțin 8 caractere',
        passwordReqDescTwo: 'cel puțin 1 număr',
        passwordReqDescThree: 'cel puțin 1 literă majusculă',
        passwordReqDescFour: 'cel puțin 1 literă mică',
        checkboxText: 'Aș dori să fiu informat cu privire la ofertele curente de la {shopName}.',
        checkboxInfo: 'Vă puteți dezabona gratuit în orice moment.',
        passwordSaveBtn: 'Salvați',
    },
    cart: {
        noReservation: 'Articolele din coșul de cumpărături nu sunt rezervate',
        headline: 'Coș de cumpărături',
        articlesWithCount: '{count} Produs',
        addons: {
            headline: 'Comandați imediat',
            add: 'Comandați, de asemenea',
        },
        subtotal: 'Subtotal',
        deliveryCost: 'Expediere și livrare',
        total: 'Total',
        allTotal: 'Suma totală',
        shippedFreeOne: 'Această comandă va fi livrată ',
        shippedFreeTwo: 'GRATUIT',
        shippedFreeThree: ' !',
        vatIncluded: 'incl. TVA',
        free: 'Gratis',
        savings: 'Economisiți în total',
        savingsText: 'În total, ați economisit ',
        toCheckout: 'În total, ați economisit',
        goSafeToCheckout: 'FINALIZAREA COMENZII',
        xArticle: ' ',
        voucher: 'Cupon',
        redeemVoucher: 'Introduceți codul cuponului',
        codePlaceholder: 'Cod cupon (opțional)',
        redeemCTA: 'Utilizați',
        voucherCodeSuccess: 'Felicitări, codul cuponului "{code}" a fost agaugat!',
        voucherCodeErrorCodes: {
            alreadyPresent: 'Codul cupon "{code}" deja este adaugat!',
            nonApplicable: 'Acest cupon nu mai este activ sau nu poate fi aplicat în coșul de cumpărături curent!',
        },
        emptyCart: 'Coșul este gol',
        emptyCartMessage: 'Selectați produsele preferate și adăugați-le în coșul dvs.',
        emptyCartButton: 'Înapoi la magazin',
        emptyCartButtonMessage: 'Continuați cumpărăturile',
        voucherModal: {
            headline: 'Cuponul a fost activat cu succes!',
            headlineGiftLineItem: 'Cadoul tău gratuit a fost activat!',
            text: '{totalSavings} de economii de la {minValue} valoarea minimă a comenzii',
            continue: 'Înapoi la magazin',
        },
        wkoProductTitle: 'Cadoul dvs. gratuit',
        wkoModalHeadline: 'Comandați în același timp accesoriile corespunzătoare și obțineți un cadou.',
        wkoDeclineOffer: 'Nu, mulțumesc.',
        wkoAddToCartSuccess: 'Bună alegere!',
        restoreCartError: 'Coșul dvs. de cumpărături nu a putut fi restaurat.',
        clubSavings: {
            clubSavingsText: 'Economisiți încă {savings} cu Clubul de Fidelitate!',
            clubSavingsLink: 'Click {clubSavingsUnderline} pentru detalii!',
            clubSavingsUnderline: 'aici',
        },
        clubExtensionCta: 'Prelungire',
        freeShippingBanner: {
            freeShipping: 'livrare gratuită',
            headline: 'A mai rămas doar {onlyLeftPrice} până {freeShipping}!',
            continue: 'Continuați ',
            shopping: 'cumpărăturile',
            order: 'Această comandă va fi ',
            freeDelivery: 'livrată gratuit!',
        },
    },
    category: {
        showMore: 'Arată mai mult',
        breadcrumbHomeAriaLabel: 'Pagina de start',
    },
    voucher: {
        codes: {
            notActive: 'Acest cupon nu este activ.',
            notValid: 'Acest cupon nu mai este valabil.',
            doesNotMatchCart: 'Cuponul nu îndeplinește condițiile.',
            maxApplicationReached: 'Acest cupon a fost deja utilizat.',
            applicationStoppedByPreviousDiscount: 'Cuponul nu poate fi utilizat din cauza unui alt cupon activ.',
            matchesCart: 'Voucherul este valabil',
        },
        modal: {
            dontShowLegalText: 'Ascundeți condițiile cuponului',
            displayLegalText: 'Afișați condițiile cuponului',
            savings: '{amount} Reducere',
            headline: 'Care cupon doriți să păstrați?',
            minimalOrderAmount: ' la comenzile plasate cu valoare minimă de {amount}',
        },
        priceGiftLineItem: 'Cadou gratuit: -{price}',
    },
    checkout: {
        minicartEdit: 'editare',
        dividerText: 'sau',
        allowRecurrence: {
            checkbox: 'Permiteți plățile recurente',
            error: 'Trebuie să fiți de acord cu plățile recurente.',
        },
        catalogBillingAddressHeadline: 'Adresă de facturare',
        editBillingAddress: 'Editați adresă de facturare',
        billingAddressHeadline: '1. Adresă de facturare',
        guestInfo:
            'Datele dumneavoastră vor fi colectate doar pentru ' +
            'această comandă și trebuie introduse din nou pentru ' +
            'comenzi ulterioare. Pentru a beneficia de avantajele ' +
            'unui cont de client, aveți opțiunea de a crea un cont ' +
            'de client gratuit după finalizarea comenzii.',
        paymentHeadline: '2. Modalitate de plată',
        summaryHeadline: '3. Rezumat',
        nextStep: 'Următorul pas',
        checkOrder: 'Verificați comanda',
        nextStepPayment: 'Continuați cu modalitățile de plată',
        lastStep: 'Pasul înapoi',
        buyNow: 'Finalizare comandă',
        cart: 'Coșul de cumpărături',
        lineItem: {
            quantity: 'Cantitate',
        },
        freeDelivery: 'Această comandă va fi livrată GRATUIT!',
        enterAddress:
            'Vă rugăm să introduceți adresa. ' +
            'Apoi faceți click pe "Următorul pas".". ' +
            'alegeți metoda de plată.',
        addresses: {
            headline: 'Date',
            customerLogin: 'Autentificare',
            guestOrder: 'Comandați fără înregistrare',
            register: 'Creați un cont de client',
        },
        payment: {
            paymentFee: 'Costuri de tranzacție: {price}',
            noPaymentFee: 'Fără costuri de tranzacție',
            noPaymentFeeForNachnahme: 'Fără alte costuri suplimentare',
            networkError:
                'Din păcate, în prezent nu putem oferi plata în avans. ' +
                'Vă rugăm să alegeți o altă metodă de plată!',
            tryOtherNetworkError:
                'Din păcate, în prezent nu putem oferi plata în avans. ' +
                'Vă rugăm să alegeți o altă metodă de plată!',
            installmentNetworkError: 'Vă rugăm să selectați o altă metodă de plată.',
            invoiceNetworkError:
                'Din păcate, nu putem oferi plăți în rate pentru această comandă. ' +
                'Vă rugăm să alegeți o altă metodă de plată pentru a vă ' +
                'finaliza comanda.',
            invoiceCheckDateOfBirth:
                'Datele furnizate de dvs. nu au putut fi verificate cu succes. ' +
                'Vă rugăm să verificați datele introduse pentru data nașterii ' +
                'și să vă asigurați că acestea sunt corecte și complete.',
            invoiceAddressError:
                'Vă rugăm să rețineți că adresa de livrare și cea de facturare trebuie să coincidă în scopul ' +
                'identificării atunci când efectuați achiziții în cont. Datele actuale ale adresei dvs. ' +
                'nu îndeplinesc această cerință și, prin urmare, achiziția în cont nu este posibilă. ' +
                'Vă rugăm să selectați o opțiune de plată alternativă pentru a vă finaliza comanda.',
            genericError:
                'Plata dvs. a eșuat. Verificați detaliile dvs. de facturare și de plată înainte de a ' +
                'încerca din nou sau utilizați o altă metodă de plată',
            unavailable: 'Momentan acest metodă de plată este indisponibil.',
            invoice: 'Kauf auf Rechnung',
            invoiceoffline: 'Kauf auf Rechnung',
            mastercard: 'Mastercard',
            paypal: 'PayPal',
            paypalexpress: 'PayPal Express',
            postfinancecard: 'PostFinance',
            visa: 'VISA',
            sofortueberweisung: 'Transfer bancar',
            nachnahme: 'Plata la livrare',
            installment: 'Plata în rate',
            installmentActivateCTA: 'Verificați',
            installmentCheckbox:
                'Sunteți de acord cu contractul de credit în rate și puteți ' +
                'descărca proiectul de contract de credit în rate {linkStart}aici{linkEnd}.',
            afterpayinvoice: 'AfterPay',
            idealr: 'iDEAL',
            mistercashR: 'Bancontact',
            unavailableShippingCountryTitle: 'Observații',
            unavailableShippingCountry: 'Comanda nu poate fi livrată în țara pe care ați selectat-o.',
            descriptions: {
                visa: 'Plata securizată prin VISA Secure.',
                mastercard: 'Plata securizată prin Mastercard',
                payPal:
                    'Plată ușoară prin contul dvs. de PayPal. La finalul ' +
                    'procesului de comandă, veți fi redirecționat direct ' +
                    'către PayPal. Comanda dvs. va fi finalizată numai ' +
                    'după ce plata a fost procesată.',
            },
        },
        shippingAddressHeadline: 'Adresa de livrare',
        summary: {
            headline: 'Rezumat',
            usps: {
                moneyBack: 'Garanția de returnare a banilor',
                freeDelivery: 'livrare gratuită',
                refund: '14 zile drept de returnare',
                freeReturn: 'returnare gratuită',
                securePayment: 'plată securizată',
            },
            subtotal: 'Subtotal',
            deliveryCost: 'Taxă de livrare',
            bulkyDeliveryCost: 'Taxă de livrare voluminoase',
            transactionFee: 'Costuri de tranzacție',
            total: 'Total',
            taxIncluded: 'incl. TVA',
            totalSavings: 'Economie totală',
            privacy: 'Utilizăm datele dvs. exclusiv în conformitate cu {termsDetails}.',
            legal: 'Făcând click pe butonul "Comandă acum", sunteți de acord și acceptați {termsDetails} a companiei {shopName}.',
            legalDetails: {
                privacyText: 'Politica noastră de confidențialitate',
                privacyLink: '/politica-de-confidentialitate',
                termsText: 'Termenii și condițiile generale',
                termsLink: '/termeni-si-conditii',
            },
            permissionEmailPhonePost:
                'Sunt de acord ca adresa mea de e-mail,' +
                'numărul de telefon și adresa poștală furnizate aici să poată fi folosite de către ' +
                'Mediashop - Telemarketing Internaional Kft. în scopul de a mă contacta, contactându-mă ' +
                'și să o prelucreze până la retragerea consimțământului meu',
            permissionEmailPhonePostDetails: {
                link: '/Protecția datelor',
            },
            buyNow: 'Comandați acum',
            deliveryTime: 'Disponibil în {time} zile lucrătoare',
            newsletterSubscription:
                'Aș dori să primesc newsletter gratuit ' +
                'cu oferte, reduceri și cupoane de la {shopName} prin e-mail. ' +
                'Vă puteți dezabona în orice moment făcând click pe linkul din ' +
                'newsletter.',
            comment: 'Mesaj către eliberator',
        },
        orderNotSavedYet: 'Comanda dvs. nu a fost încă salvată. Chiar doriți să părăsiți site-ul?',
        resetPassword: {
            title: 'Observații',
            textBold: 'Sunteți deja clientul nostru și ați avut deja cont la vechiul nostru magazin online?',
            textNormal: '{link} și utilizați contul dvs. de client existent.',
            textCta: 'Resetați parola anterioară',
            resetButton: 'Resetare parolă',
            closeButton: 'Închideți',
        },
        multiPage: {
            goToCartModal: {
                headline: 'Atenție',
                description: 'Sunteți sigur că doriți să vă întoarceți la coșul dvs.?',
                dismiss: 'Rămâneți în cadrul procesului de plată',
                submit: 'Editați coșul',
            },
            summary: {
                amount: 'Cantitate: {amount}',
                billingAddress: 'Adresa de facturare',
                billingAndDelivery: 'Facturare și livrare',
                change: 'modificare',
                lineItemTotalPrice: 'Suma totală: {price}',
                paymentType: 'Metoda de plată',
                shippingAddress: 'Adresa de livrare',
                title: 'Sumar',
                yourOrder: 'Comanda dvs.',
            },
            headlines: {
                headline: 'Comandă securizată în 3 pași',
                address: 'Adresă',
                payment: 'Metoda de plată',
                summary: 'Verificați și trimiteți',
            },
            nextButton: 'Continuare',
            checkDataButton: 'Verificarea datelor',
        },
        customerClubExtensionModal: {
            title: 'Atenție',
            text:
                'Sunteți deja membru al clubului de clienți. Cardul de loialitate a fost eliminat din coșul de cumpărături. ' +
                'Doriți să cumpărați în schimb o reînnoire a abonamentului existent?',
            addToCart: 'Cumpărați reînnoirea',
            closeButton: 'Close',
        },
        subscriptionInfo:
            'Pentru a achiziționa un abonament aveți nevoie de un cont de client. Fie conectați-vă cu contul dvs. ' +
            'de client existent, fie pur și simplu creați un nou cont de client.',
    },
    totalPrice: {
        taxIncluded: 'TVA inclus',
    },
    address: {
        sectorPlaceholder: 'Sec',
        blockPlaceholder: 'Bl',
        stairwayPlaceholder: 'Sc',
        floorPlaceholder: 'Et',
        apartmentPlaceholder: 'Ap',
        billingAddress: 'Adresa de facturare*',
        requiredField: '*Câmpuri obligatorii',
        emailLabel: 'Adresa de e-mail',
        emailPlaceholder: 'e-mail',
        emailErrorText: 'Vă rugăm să introduceți o adresă de e-mail validă.',
        salutationLabel: 'Salut*',
        salutationPlaceholder: 'Salut',
        salutations: {
            ms: 'Dna',
            mr: 'Dl',
            none: 'Nicio specificație',
        },
        customerTypeLabel: 'Tip de facturare',
        customerType: {
            private: 'Persoană fizică',
            corporate: 'Persoană juridică',
        },
        contactLabel: 'Contact*',
        contactPerson: 'Persoană de contact',
        contactInfo: 'Contact',
        firstnamePlaceholder: 'Prenume',
        firstnameErrorText: 'Vă rugăm să introduceți un prenume valid.',
        lastnamePlaceholder: 'Nume de familie',
        lastnameErrorText: 'Vă rugăm să introduceți un nume de familie valid.',
        nameLengthErrorText: 'Sunt permise maximum 200 de caractere.',
        phonePlaceholder: 'Numărul de telefon',
        phoneErrorText: 'Vă rugăm să introduceți un număr de telefon valid.',
        company: 'Companie',
        companyPlaceholder: 'Denumirea Companiei',
        companyErrorText: 'Vă rugăm să introduceți denumirea Companiei corect.',
        vatIdPlaceholder: 'Cod fiscal',
        vatIdErrorText: 'Vă rugăm să furnizați date reale!',
        address: 'Strada',
        streetPlaceholder: 'Strada',
        streetErrorText: 'Vă rugăm să furnizați date reale!',
        streetLengthErrorText: 'Sunt permise maximum 100 de caractere.',
        streetLengthErrorTextCZ: 'Sunt permise maximum 40 de caractere.',
        streetNumberPlaceholder: 'Numărul de casă',
        streetNumberErrorText: 'Vă rugăm să furnizați date reale!',
        zipCodePlaceholder: 'Cod poștal',
        zipCodeErrorText: 'Vă rugăm să furnizați date reale!',
        cityPlaceholder: 'Localitate',
        cityErrorText: 'Vă rugăm să furnizați date reale!',
        country: 'Țara',
        countryLabel: 'Țara',
        countryPlaceholder: 'Selectați țara',
        countyLabel: 'Județul',
        countyPlaceholder: 'Selectați Județul',
        packingStationPlaceholder: 'Doriți livrarea și ridicarea coletului din Packstation?*',
        packingStation: {
            yes: 'Da',
            no: 'Nu',
        },
        shippingAddressHeadline: 'Adresa de livrare:*',
        shippingAddressPlaceholder: 'Aceasta este și adresa de livrare:*',
        shippingAddress: {
            yes: 'Da',
            no: 'Nu',
        },
        dayPlaceholder: 'Ziua',
        monthPlaceholder: 'Luna',
        yearPlaceholder: 'Anul',
        birthdateTitle: 'Data nașterii',
        birthdatePlaceholder: '1990-01-23',
        birthdateErrorText: 'Vă rugăm să furnizați date reale!',
        birthdateTooYoungErrorText: 'Doar pentru persoanele peste 18 ani.',
        birthdateDayErrorText: '*Invalid',
        birthdateMonthErrorText: '*Invalid',
        birthdateYearErrorText: '*Invalid',
        birthdayTitle: 'Data nașterii',
        birthDayError: 'Vârsta nu trebuie să depășească 100 de ani.',
        loqatePlaceholder: 'Introduceți adresa',
        loqateManualAddress: 'Introduceți adresa manual',
        loqateManualAddressHint: 'sau prin simpla căutare a unei adrese:',
        loqateSearchAddress: 'poate căutați după adresă?',
    },
    checkoutSuccess: {
        headline: 'Vă mulțumim pentru comandă!',
        headlineError:
            'Ne cerem scuze, a intervenit o eroare.' +
            'Vă rugăm să încercați din nou sau să contactați serviciul nostru de relații cu clienți.',
        textFirst: 'Am primit comanda dumneavoastră și vă vom trimite și o confirmare la <link>{email}</link>.',
        textSecond: 'Vă informăm din nou prin e-mail când comanda Dvs. v-a fi predat la curier.',
        textSecondPending:
            'Comanda dvs. este in curs de procesare.' +
            'Vă vom anunța prin e-mail de îndată ce comanda dvs. a fost creată.',
        textError: 'Pentru a afla statutul comenzii contactați serviciul nostru de relații cu clienții.',
        noEmailPlaceholder: 'Adresa de e-mail',
        newsletter: {
            headline: 'Newsletter',
            text:
                'Doresc să fiu informat gratuit cu privire la ofertele actuale de la {shopName} prin intermediul ' +
                'unui buletin informativ.',
            feedbackText:
                'Înregistrarea la newsletter a fost un succes! Veți primi în scurt timp un e-mail cu un ' +
                'link pentru a confirma adresa de e-mail.',
            error: 'Înregistrarea la newsletter nu este momentan posibilă.',
        },
        catalog: {
            headline: 'Catalogul',
            text:
                'Aș dori să primesc gratuit catalogul {shopName} și să îl primesc la domiciliu prin ' +
                'poștă, în mod convenabil.',
            feedbackText: 'Catalogul va fi trimis în curând la adresa dvs.',
            error: 'Comanda prin catalog nu este posibilă în prezent.',
        },
    },
    county: {
        alba: 'Alba',
        arad: 'Arad',
        arges: 'Arges',
        bacau: 'Bacau',
        bihor: 'Bihor',
        bistritaNasaud: 'Bistrita Nasaud',
        botosani: 'Botosani',
        braila: 'Braila',
        brasov: 'Brasov',
        bucuresti: 'Bucuresti',
        buzau: 'Buzau',
        calarasi: 'Calarasi',
        carasSeverin: 'Caras Severin',
        cluj: 'Cluj',
        constanta: 'Constanta',
        covasna: 'Covasna',
        dambovita: 'Dambovita',
        dolj: 'Dolj',
        galati: 'Galati',
        giurgiu: 'Giurgiu',
        gorj: 'Gorj',
        harghita: 'Harghita',
        hunedoara: 'Hunedoara',
        ialomita: 'Ialomita',
        iasi: 'Iasi',
        ilfov: 'Ilfov',
        maramures: 'Maramures',
        mehedinti: 'Mehedinti',
        mures: 'Mures',
        neamt: 'Neamt',
        olt: 'Olt',
        prahova: 'Prahova',
        salaj: 'Salaj',
        satuMare: 'Satu Mare',
        sibiu: 'Sibiu',
        suceava: 'Suceava',
        teleorman: 'Teleorman',
        timis: 'Timis',
        tulcea: 'Tulcea',
        valcea: 'Valcea',
        vaslui: 'Vaslui',
        vrancea: 'Vrancea',
    },
    country: {
        select: 'Land ändern',
        AT: 'Österreich',
        DE: 'Deutschland',
        CH: 'Schweiz',
        LI: 'Liechtenstein',
        HU: 'Ungarn',
        IT: 'Italien',
        BE: 'Belgien',
        NL: 'Niederlande',
        BG: 'Bulgarien',
        HR: 'Kroatien',
        CY: 'Zypern',
        CZ: 'Tschechien',
        DK: 'Dänemark',
        EE: 'Estland',
        FI: 'Finnland',
        FR: 'Frankreich',
        GR: 'Griechenland',
        IE: 'Irland',
        LV: 'Lettland',
        LT: 'Litauen',
        LU: 'Luxemburg',
        MK: 'Mazedonien',
        MT: 'Malta',
        PL: 'Polen',
        PT: 'Portugal',
        RO: 'România',
        SM: 'San Marino',
        SK: 'Slowakei',
        SI: 'Slowenien',
        ES: 'Spanien',
        SE: 'Schweden',
    },
    socialMedia: {
        facebook: 'Postare pe Facebook',
        twitter: 'Tweet pe Twitter',
        whatsapp: 'Împărtășire prin Whatsapp',
        native: 'Împărtășire',
    },
    productListing: {
        noProducts: 'Nu s-au găsit produse',
        products: '{count} {count, plural, one {produs} other {produse}}',
        loadMore: 'Încarcă mai multe',
        from: 'din',
        articles: 'Articole',
        title: '{categoryName} | {shopName}{count, plural, one {} other { - pagina {count}}}',
    },
    pagination: {
        ariaPage: '{pageNumber}. pagină',
        next: 'următorul',
        previous: 'anterior',
    },
    menu: {
        logout: 'Deconectare',
        logoutHeader: 'Atenție!',
        logoutText: 'Chiar doriți să vă deconectați?',
        logoutButtonText: 'Deconectare',
    },
    login: {
        password: 'Parolă',
        submitButtonLabel: 'autentificați-vă în siguranță',
        emailPlaceholder: 'Adresa de E-Mail',
        passwordLabel: 'Introduceți parola',
        usernameEmptyError: 'Numele de utilizator nu trebuie să fie gol',
        usernameEmailError: 'Numele de utilizator trebuie să fie o adresă de e-mail validă',
        userLoginError: 'Numele de utilizator sau parola sunt incorecte',
        passwordForgotten: 'Ați uitat parola?',
        loginSuccess: 'Sunteți conectat',
        passwordEmptyError: 'Parola nu trebuie să fie goală',
        existingUser: 'Sunt deja un client înregistrat, continuați să vă Logați',
        resetPassword: 'Resetarea parolei',
        resetEmailSent: 'V-am trimis un e-mail la {email}.',
        resetErrorQuestions: 'Nu ați primit niciun email?',
        resetErrorAnswerOne: 'Vă rugăm să verificați fișierul spam din căsuța dvs. de e-mail.',
        resetErrorAnswerTwo: 'Vă rugăm să verificați din nou datele dvs.',
        resetErrorAnswerThree: 'Vă rugăm să așteptați 15 minute înainte de a încerca din nou.',
        newPassword: 'Parolă nouă',
        passwordNotMatchingReq: 'Parola nu corespunde cu cerințele',
        passwordsNotIdentical: 'Noile parole nu se potrivesc',
        repeatPassword: 'Confirmați parola',
        changePassword: 'Modificați parola',
        passwordChanged: 'Parola a fost schimbată cu succes',
        emailConfirmed: 'Adresa dvs. de e-mail a fost confirmat cu succes. Acum vă puteți autentifica cu ușurință.',
        processToken: 'A apărut o eroare. Vă rugăm să încercați din nou.',
        generalError: 'A apărut o eroare. Vă rugăm să încercați din nou.',
        authorizeStoreTitle: 'Conectarea conturilor clienților',
        authorizeStoreText: 'Doriți să utilizați contul dvs. de client existent pentru {shopName}?',
        yes: 'Da',
        no: 'Nu',
        emailUnconfirmed: 'Vă rugăm să confirmați mai întâi adresa de e-mail.',
    },
    orders: {
        order: 'Comandați',
        total: 'Total',
        details: 'Detalii',
        back: 'Înapoi',
        shippingAddress: 'Adresa de Livrare',
        paymentMethod: 'Modalitate de plată',
        paymentMethodNotApplicable: 'N/A',
        quantity: 'Cantitate: {quantity}',
        reorder: 'Comandați din nou',
        reOrderSuccess: 'Produsul adăugat cu succes',
        reOrderFail: 'Produsul nu a putut fi adăugat în coșul de cumpărături',
        date: 'Data',
        progress: '{items} din {total} comenzi',
    },
    customer: {
        mr: 'Dl',
        mrs: 'Dna',
        customerNumber: 'Număr de client',
        billingAddress: 'Adresa de facturare',
        email: 'Adresa de E-mail',
        phone: 'Telefon',
        password: 'Parola',
        passwordText: 'Parola nu se poate afișa din motive de securitate.',
        passwordLinkText: 'Ați uitat parola?',
        tokenNotReceived:
            'E-mailul dvs. nu există în baza noastră de date.' +
            'Vă rugăm să încercați din nou sau să contactați serviciul nostru de relații cu clienți.',
        emailInvalid: 'Adresa de e-mail invalidă',
        dataUpdated: 'Datele sunt actualizate',
        wrongPassword: 'Parola nu a putut fi schimbată',
        couldNotResetPassword: 'Nu este posibilă resetarea parolei.',
        customerClub: {
            cardNumber: 'Numărul cardului: {number}',
            validFrom: 'Valabil de la: {date}',
            validThru: 'Valabil până la: {date}',
            expiryWarning:
                'Atenție: cardul dvs. își pierde valabilitatea în {days} {days, plural, one {zi} other {zile}}.',
            cardExpired: 'Atenție: Cardul dumneavoastră a expirat.',
            offerCardRenewal: 'Comandați un card nou',
        },
        customerCardLink: {
            headline: 'Asociați-vă contul online cu cardul dvs. de client fidel!',
            subHeadline: 'Beneficiați de avantajele apartenenței la club la fiecare cumpărătură online!',
            cardNumber: 'Numărul cardului',
            clubEmail: 'Adresa de email a clientului fidel',
            cta: 'Asociați',
            success: 'Cardul dumneavoastră de club al clientului a fost conectat cu succes la contul dumneavoastră.',
            error: {
                mykeneNotFound: 'Nu a putut fi găsit niciun card club client cu numărul specificat.',
                mykeneInvalidCombination:
                    'Adresa de e-mail nu se potrivește cu cea de pe cardul de club al clientului.',
                mykeneError: 'S-a produs o eroare. Vă rugăm să încercați din nou mai târziu.',
                cardExistsForStore: 'Cardul dvs. de client club a fost deja adăugat la un cont.',
                unknownError: 'S-a produs o eroare. Vă rugăm să încercați din nou mai târziu.',
            },
        },
    },
    register: {
        passwordEmptyError: 'Parola nu trebuie să fie goală',
        passwordMinError: 'Parola trebuie să aibă cel puțin 8 caractere',
        passwordNotValidError: 'Cel puțin 1x caracter special, 1x literă majusculă, 1x literă mică',
        passwordConfirmationError: 'Parolele nu se potrives',
        passwordLabel: 'Creați parola',
        passwordConfirmationLabel: 'Repetați parola',
        passwordCriteriaHeader: 'Parola dumneavoastră trebuie să conține ...',
        passwordCriteria: {
            numberOfCharacters: '✔ o lungime de cel puțin 8 caractere',
            hasNumber: '✔ cel puțin 1 număr',
            hasUppercaseLetter: '✔ cel puțin 1 literă majusculă',
            hasLowercaseLetter: '✔ cel puțin 1 literă mică',
        },
        acceptGDPRError: 'Trebuie să acceptați declarația privind protecția datelor.',
        nextStepPayment: 'Treceți la plată!',
        error: 'Din păcate, a apărut o eroare. Vă rugăm să încercați din nou.',
        successMessage: 'Înregistrare reușită. Vă rugăm să confirmați mai întâi adresa de e-mail.',
        mainError: 'Vă rugăm să corectați greșelile!',
        change: 'MODIFICARE',
        accountVerificationTitle: 'Verificarea contului',
        accountVerificationText:
            'În scurt timp veți primi un e-mail cu un link către ' +
            'pentru activarea contului dumneavoastră de client.' +
            'Vă rugăm să rețineți că acest link este valabil doar în următorele 24 de ore. ' +
            'Conturile clienților neverificați sunt șterse automat după 24 de ore.',
        accountVerificationCTA: 'Accept',
        existingAccountModal: {
            text:
                'Această adresă de e-mail este deja utilizată. Dacă aveți un cont pentru unul dintre următoarele' +
                ' Magazine, vă rugăm să vă conectați cu datele de acces cunoscute.',
            shops: '<ul><li>haus-hobby.ch</li><li>maison-hobby.ch</li><li>haus-hobby.com</li></ul>',
            accept: 'Logare',
            close: 'Închideți',
        },
        newsletterSubscription: 'Aș dori să primesc informații despre ofertele curente de la {shopName}.',
        existingAccountToast: 'Această adresă de e-mail este deja utilizată.',
    },
    countrySwitcher: {
        headline: 'Personalizați Țara și limba',
        country: 'Țara',
        language: 'Română',
        button: 'Salvare',
        modal: {
            headline: 'Atenție!',
            description:
                'Modificați setările regiunii. {newSelectedCountry}. Pentru a putea comanda, ' +
                'adresa de expediere trebuie să se afle în regiunea respectivă. ',
            submitText: 'Mai departe',
            closeText: 'Anulare',
        },
    },
    subcategoryFilter: {
        all: 'Toate',
        reset: 'Anulează selecția',
    },
    personalInfo: {
        newEmail: 'Noua adresă de email',
        newEmailConfirm: 'Confirmați noua adresă de e-mail',
        passwordConfirm: 'Confirmați parola',
        currentPassword: 'Parola actuală',
        newPassword: 'Parolă nouă',
        newPasswordConfirm: 'Confirmați noua parolă',
        actualEmail: 'Adresa de e-mail curentă',
    },
    newsletter: {
        inputPlaceholder: 'Adresa de e-mail',
        success: 'În scurt timp veți primi un e-mail cu un link de confirmare.',
        invalidEmail: 'Vă rugăm să introduceți o adresă de e-mail validă.',
        error: 'Din păcate, a apărut o problemă la înregistrarea pentru newsletter.',
        emailEmptyError: 'Vă rugăm introduceți adresă de e-mail',
        registered: 'V-ați abonat cu succes la newsletter.',
        notRegistered: 'Dvs. nu ați abonat la newsletter.',
        subscribe: 'Abonare',
        unsubscribe: 'Dezabonare',
        alreadySubscribed: 'Adresa de e-mail este deja înregistrată pentru newsletter',
        successAccount: 'V-ați abonat cu succes la newsletter.',
        alreadyRegisteredQuestion: 'Sunteți deja înregistrat? ',
        unsubscribeHere: ' Vă puteți dezabona aici',
        successUnsubscribe: 'V-ați dezabonat cu succes',
        unsubscribeError: 'S-a produs o eroare. Vă rugăm să încercați din nou mai târziu.',
    },
    cartChangeModal: {
        headline: 'Atenție!',
        description: 'Coșul de cumpărături a fost actualizat, vă rugăm să selectați din nou metoda de plată.',
        submitText: 'OK',
    },
    clubCustomer: {
        priceMessage: 'Preţ cu Card de Fidelitate: {price}',
        modal: {
            bannerHeadline: 'DEVENIȚI MEMBRU AL CLUBUL CLIENȚILOR FIDELI?',
            bannerSubHeadline: 'REDUCERI ȘI CUPOANE PERSONALIZATE VĂ AȘTEAPTĂ!',
            headline: 'DE CE MERITĂ DEVENIȚI MEMBRU AL CLUBUL CLIENȚILOR FIDELI?',
            text:
                'Cu Obținerea unui Card de Fidelitate veți beneficia de un ' +
                'abonament pe 1 an de zile în Clubul nostru de clienți fideli, ' +
                'unde o să aveți multe beneficii și de fiecare dată veți ' +
                'economisi mai mulți bani! De asemenea, garantăm LIVRAREA ' +
                'GRATUITĂ la toate comenzile pe perioada valabilitășii ' +
                'cardului de fidelitate. Există multe alte reduceri speciale ' +
                'care vă așteaptă în Clubul nostru: *** acum la fiecare ' +
                'înregistrare nouă vă oferim un cadou de 320 lei sub formă ' +
                'de cupoane! *** Cupoanele vor fi trimise prin poștă împreună ' +
                'cu cardul dvs., astfel după primirea cupoanelor le puteți ' +
                'folosi la următoarea comandă în magazinul nostru online ' +
                'sau la comenzi telefonice. Cardului de Fidelitate este de ' +
                'doar 119 lei pe an.Atentie: odată cu înregistrarea și ' +
                'adaugarea Cardului de Fidelitate în coș, instantaneu veți ' +
                'avea dreptul la Prețuri de Fidelitate + LIVRARE GRATUITĂ, ' +
                'astfel încât veți economisi mai mulți bani imediat! Merită ' +
                'să comandați cardul de fidelitate odata cu efectuarea ' +
                'cumpărăturilor!',
            button: 'DORESC SĂ DEVIN MEMBRU!',
        },
    },
    infoBanner: {
        closeButton: 'Închide',
    },
    slider: {
        prevButton: 'Înapoi',
        nextButton: 'Continuați',
        activePagination: 'derulare activă',
        inactivePagination: 'derulare inactivă',
    },
    specialDeal: {
        days: '{count, plural, one {Ziua} other {Zile}}',
        hours: 'Ore',
        minutes: 'Min.',
        seconds: 'Sec.',
        specialDealOver: 'Din păcate, această promoție nu mai este disponibilă.',
    },
    userManuals: {
        headline: 'Manuale',
        search: 'Căutare după produs, număr articol sau cod',
        resetSearch: 'Resetare căutare',
        table: {
            sku: 'Numarul articolului',
            code: 'Code',
            productTitle: 'Produs',
            type: 'Tip',
            types: {
                /* eslint-disable camelcase */
                instruction_manual: 'Instrucțiuni de utilizare',
                recipe_booklet: 'Carte de rețete',
                training_plan: 'Planul de antrenament',
                nutrition_plan: 'Planul de nutriție',
                /* eslint-enable camelcase */
            },
        },
        noResults: 'Nu s-au găsit manuale.',
    },
    price: {
        from: 'de\u00a0la\u00a0{price}',
        reference: '{price}\u00a0/\u00a0{baseUnitMultiplier}\u00a0{referenceUnit}',
        statt: 'în loc de {price}',
        uvp: 'RRP\u00a0{price}',
    },
    emarsysFieldWriter: {
        success: 'Schimbarea a fost făcută cu succes.',
        error: 'S-a produs o eroare. Vă rugăm să încercați din nou mai târziu.',
    },
    subscription: {
        amount: 'Cantitate:',
        deliveryInterval: 'Intervalul de livrare:',
        nextDelivery: 'Următoarea livrare:',
        ordered: 'Ordonat:',
        status: 'Stare:',
        cancelSubscription: 'Anuleaza abonarea',
        state: {
            active: 'Activ',
            paused: 'Întrerupt',
            cancelled: 'Părăsi',
            problems: 'Probleme',
        },
        intervalUnit: {
            day: '{amount} {amount, plural, one {Zi} other {Zile}}',
            month: '{amount} {amount, plural, one {Lună} other {Luni}}',
            year: '{amount} {amount, plural, one {An} other {Ani}}',
        },
    },
};
