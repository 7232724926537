import { Variant } from '@mediashop/app/api/types/ClientProduct';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';

type ShippingTimeProps = BaseProps & {
    variant?: Variant;
    className: string;
};
export default function ShippingTime({ className, variant }: ShippingTimeProps): JSX.Element | null {
    const showDeliveryTime =
        variant && Boolean(variant?.attributes?.deliveryTimeFrom) && Boolean(variant?.attributes?.deliveryTimeUntil);
    const markGreen = variant?.attributes?.shippingTime?.split(':');
    const isDeviceTypeMobile = useDeviceType() === 'mobile';

    if (!showDeliveryTime) {
        return SKIP_RENDER;
    }

    return (
        <div className={`${className}__delivery`}>
            {isDeviceTypeMobile ? (
                <span className={`${className}__delivery--mobile`}>
                    {variant?.attributes?.shippingTime.split(' ')[0]}
                    <span className={`${className}__delivery-days`}>
                        {variant?.attributes?.shippingTime.split(':')[1]}
                    </span>
                </span>
            ) : (
                <div>
                    <span className={`${className}__shipping-time`}>
                        {markGreen && markGreen?.length > 1 && markGreen[0]}:{' '}
                    </span>
                    <span className={`${className}__delivery-days`}>{markGreen?.length > 1 ? markGreen[1] : ''}</span>
                </div>
            )}
        </div>
    );
}
