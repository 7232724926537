import useRoute from '../../hooks/useRoute';
import BaseUrlHelper from '../../helper/BaseUrlHelper';
import { useProject } from '../../hooks/useProject';

export const usePaymentCallbackUrls = () => {
    const { basePath } = useProject();
    const routeOrderCreate = useRoute('Frontend.Master.OrderCreate');
    const routeErrorPayment = useRoute('Frontend.Master.ErrorPayment');
    const routeOrderPaymentAbort = useRoute('Frontend.Master.OrderPaymentAbort');

    const returnUrl = BaseUrlHelper.addBasePathToPath(routeOrderCreate, window.location.origin + basePath);
    const errorUrl = BaseUrlHelper.addBasePathToPath(routeErrorPayment, window.location.origin + basePath);
    const cancelUrl = BaseUrlHelper.addBasePathToPath(routeOrderPaymentAbort, window.location.origin + basePath);

    return {
        errorUrl,
        returnUrl,
        cancelUrl,
    };
};
