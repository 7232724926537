import { lazy } from 'react';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const CustomerClubCardExtensionModalLazy = lazy(() =>
    lazyRetry(() => import('./CustomerClubCardExtensionModal'))
);

export type CustomerClubCardExtensionModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const CustomerClubCardExtensionModal = (props: CustomerClubCardExtensionModalProps): JSX.Element => (
    <LazyComponent>
        <CustomerClubCardExtensionModalLazy {...props} />
    </LazyComponent>
);

export default CustomerClubCardExtensionModal;
