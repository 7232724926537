import ClubPrice from '@mediashop/base/pattern/molecule/ClubPrice';
import { useMemo } from 'react';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import classNames from 'classnames';
import OriginalPrice from '../../../../atom/OriginalPrice';
import { Price } from '@mediashop/base/pattern/molecule/Price';
import { FormattedMessage } from 'react-intl';
import Modal from '@mediashop/base/pattern/molecule/Modal';
import BloomreachHtml, { BrxHtml } from '@mediashop/app/components/BloomreachHtml';
import Icon from '@mediashop/base/pattern/atom/Icon';
import Badge from '@mediashop/catalog-base/pattern/atom/Badge';
import { DetailedProduct } from '@mediashop/app/api/types/ClientProductDetailed';
import { BadgeObject } from '@mediashop/catalog-base/types/badges';
import { Variant } from '@mediashop/app/api/types/ClientProduct';
import { Money as MoneyInterface } from '@mediashop/app/api/types/Money';
import { useCart } from '@mediashop/app/hooks/useCart';
import { useCheaperSetPrice } from '@mediashop/app/hooks/api/useCheaperSetPrice';

type ProductDetailsPriceProps = {
    activeVariant: Variant;
    componentName: string;
    detailBadges: BadgeObject[];
    hasSaleBadge: boolean;
    infoTextForPaymentByInstallments?: BrxHtml;
    installmentPrice?: MoneyInterface;
    isInstallmentTextAvailable: boolean;
    isVariantSelected: boolean;
    priceClass: string;
    product?: DetailedProduct;
    productHasMultipleVariants?: boolean | number;
    setShowInstallmentModal: (value: boolean) => void;
    showInstallmentModal: boolean;
    variant: Variant;
};

// eslint-disable-next-line complexity
export default function ProductDetailsPrice({
    activeVariant,
    componentName,
    detailBadges,
    hasSaleBadge,
    infoTextForPaymentByInstallments,
    installmentPrice,
    isInstallmentTextAvailable,
    isVariantSelected,
    priceClass,
    product,
    productHasMultipleVariants,
    setShowInstallmentModal,
    showInstallmentModal,
    variant,
}: Readonly<ProductDetailsPriceProps>): JSX.Element {
    const { cart } = useCart();
    const { cheaperSetPrice } = useCheaperSetPrice({ variant: activeVariant });
    const showCheaperSetPrice = useMemo(
        () =>
            activeVariant?.mightHaveCheaperSets &&
            cheaperSetPrice &&
            cart?.lineItems.some((lineItem) => lineItem.variant.sku === activeVariant?.sku),
        [cart, activeVariant, cheaperSetPrice]
    );

    return (
        <div className={`${componentName}__price-container`}>
            <div className={`${componentName}__price-container-price`}>
                <OriginalPrice
                    originalPrice={activeVariant.originalPrice}
                    price={activeVariant.price}
                    className={classNames(`product__old-price`, `${componentName}__price-container-original`)}
                />

                <div className={classNames(`${componentName}__price-wrapper`, priceClass)}>
                    {variant?.existsOtherThenCheapestPrices &&
                    !isVariantSelected &&
                    product?.variants[0]?.cheapestPrice ? (
                        <div>
                            <FormattedMessage
                                id="price.from"
                                values={{
                                    price: (
                                        <span className={classNames(`${componentName}__price`, `${priceClass}`)}>
                                            <Price price={product?.variants[0]?.cheapestPrice} />
                                        </span>
                                    ),
                                }}
                            />
                        </div>
                    ) : (
                        SKIP_RENDER
                    )}
                    {(!variant?.existsOtherThenCheapestPrices || isVariantSelected) &&
                        (variant?.price || installmentPrice) && (
                            <span
                                className={classNames(`${componentName}__price`, {
                                    [`${componentName}__price--sale`]:
                                        hasSaleBadge || (showCheaperSetPrice && cheaperSetPrice),
                                })}
                            >
                                <Price
                                    price={showCheaperSetPrice && cheaperSetPrice ? cheaperSetPrice : variant.price}
                                />
                            </span>
                        )}
                </div>

                <div className={`${componentName}__price-container-badge`}>
                    {detailBadges.map((badge) =>
                        badge.type !== 'RATENZAHLUNG' && badge.type !== 'RATENZAHLUNGDEAL' ? (
                            <Badge {...badge.badgeProps} key={badge.badgeProps.text} />
                        ) : (
                            SKIP_RENDER
                        )
                    )}
                </div>
            </div>

            {variant?.price && installmentPrice && (
                <>
                    <div
                        className={classNames(`${componentName}__pay-once`, priceClass, {
                            [`${componentName}__pay-once--clickable`]: isInstallmentTextAvailable,
                        })}
                        onClick={isInstallmentTextAvailable ? () => setShowInstallmentModal(true) : undefined}
                    >
                        <FormattedMessage
                            id={
                                productHasMultipleVariants &&
                                !isVariantSelected &&
                                variant?.existsOtherThenCheapestPrices
                                    ? 'product.badges.installmentsVariantsAsAlternative'
                                    : 'product.badges.installmentsAsAlternative'
                            }
                            values={{
                                count: installmentPrice.installments,
                                price: <Price price={installmentPrice} />,
                            }}
                        />
                        <Icon name="Info" />
                    </div>
                    {isInstallmentTextAvailable ? (
                        <Modal
                            isOpen={showInstallmentModal}
                            onRequestClose={() => setShowInstallmentModal(false)}
                            overlayClassName="modal__overlay-bg"
                        >
                            <BloomreachHtml
                                className={`${componentName}__description`}
                                html={infoTextForPaymentByInstallments}
                            />
                        </Modal>
                    ) : (
                        SKIP_RENDER
                    )}
                </>
            )}

            {activeVariant?.cheapestCustomerPrice && activeVariant.customerPrice ? (
                <ClubPrice
                    className={`${componentName}__club-modal`}
                    price={isVariantSelected ? activeVariant.customerPrice : activeVariant?.cheapestCustomerPrice}
                    size="medium"
                    showFrom={!isVariantSelected && activeVariant?.existsOtherThenCheapestCustomerPrices}
                    displayModalIndicator
                />
            ) : (
                SKIP_RENDER
            )}
        </div>
    );
}
