import { Product, ProductWithActiveVariant } from '@mediashop/app/api/types/ClientProduct';
import useNostoSearchAnalytics from '@mediashop/app/hooks/useNostoSearchAnalytics';
import { useCallback, useEffect, useState } from 'react';
import Headlines from '@mediashop/base/pattern/atom/Headlines';
import { useIntl } from 'react-intl';
import Pagination from '../../Pagination';
import { PRODUCT_GRID_PAGE_LIMIT } from '@mediashop/app/constants/api';
import { BaseProps, BrxRelationType, ContentBackgroundProps } from '@mediashop/app/bloomreach/types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import FullScreenLoadingIndicator from '@mediashop/base/pattern/atom/FullScreenLoadingIndicator';
import Grid from '../Grid';
import { useSearchProducts } from '@mediashop/app/queries/search/useSearchProducts';
import { useLocation } from 'react-router-dom';
import { useNavigate } from '@mediashop/app/hooks/useNavigate';

const componentName = 'product-grid-search';
const URL_PARAM_PAGE = 'page';
const URL_PARAM_SEARCH_QUERY = 'q';

type ProductGridSearchProps = BaseProps & {
    query: string;
    productRelationType: BrxRelationType;
    limit?: number;
    contentBackground?: ContentBackgroundProps;
};

export function ProductGridSearch({
    query,
    productRelationType,
    limit = PRODUCT_GRID_PAGE_LIMIT,
    contentBackground,
}: ProductGridSearchProps): JSX.Element {
    const intl = useIntl();
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const queryParams = new URLSearchParams(search);

    const [pageIndex, setPageIndex] = useState(parseInt(queryParams.get('page') ?? '1', 10) - 1);

    const { trackSearch, trackProductClick } = useNostoSearchAnalytics();

    const { products, totalProductCount, isFetching } = useSearchProducts({
        searchText: query,
        nextPageIndex: pageIndex,
        limit,
    });

    useEffect(() => {
        if (!isFetching) {
            const isKeyword = queryParams.has('keyword');
            trackSearch({
                type: isKeyword ? 'autocomplete' : 'serp',
                query,
                products,
                totalProductCount,
                limit,
                offset: pageIndex * limit,
                isKeyword,
            });
        }
    }, [products, isFetching]);

    /**
     * ---
     * Syncs the query params in the URL.
     */
    const syncParamsWithURL = useCallback(
        (params: Record<string, string | undefined>) => {
            const urlParams = new URLSearchParams();
            Object.entries(params).forEach(([key, value]) => value && urlParams.set(key, value));

            navigate({ pathname, search: urlParams.toString() });
        },
        [navigate, pathname]
    );

    /**
     * Load specific page
     * @param pageNumber number of the page (not the index) to be loaded
     */
    const transitionToPage = (pageNumber: number) => {
        syncParamsWithURL({
            [URL_PARAM_SEARCH_QUERY]: query,
            [URL_PARAM_PAGE]: pageNumber > 1 ? pageNumber.toString() : undefined,
        });

        setPageIndex(pageNumber - 1);
        scrollTo({ top: 0, behavior: 'smooth' });
    };

    const gridProducts: Array<ProductWithActiveVariant> = products.map((product) => {
        const foundVariant = product.variants.find((variant) => variant.sku === query);
        if (foundVariant) {
            return { ...product, activeVariant: foundVariant };
        }

        return product;
    });

    const handleProductClick = (product: Product) => trackProductClick('serp', product);

    const totalNumberPages = Math.ceil(totalProductCount / limit);

    return (
        <div className={`${componentName}__search-wrapper`}>
            {isFetching ? (
                <FullScreenLoadingIndicator />
            ) : (
                /** Headline */
                <Headlines
                    headline={intl.formatMessage(
                        { id: 'mainNavigation.resultSearch' },
                        { count: totalProductCount, query }
                    )}
                    headlineStyle="h1"
                    textStyle="normalCase"
                    className={`${componentName}__headlines`}
                />
            )}

            {/** Grid */}
            <Grid
                products={gridProducts}
                productRelationType={productRelationType}
                contentBackground={contentBackground}
                onProductClick={handleProductClick}
            />

            {/** Pagination */}
            {totalProductCount > 0 && totalNumberPages > 1 ? (
                <div className={`${componentName}__pagination-container`}>
                    <Pagination
                        className={`${componentName}__pagination`}
                        pageCount={totalNumberPages}
                        selectedPage={pageIndex + 1}
                        onPageChange={transitionToPage}
                    />
                </div>
            ) : (
                SKIP_RENDER
            )}
        </div>
    );
}
