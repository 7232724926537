import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '../../constants/queryKeys';
import { useLoader } from '../../store/loader';
import { queries } from '..';
import { noop } from 'lodash';

interface UseDeleteCartProps {
    onSuccess: () => void;
}

export const useDeleteCart = ({ onSuccess }: UseDeleteCartProps = { onSuccess: noop }) => {
    const { cartLoader } = useLoader();
    const queryClient = useQueryClient();

    const deleteCart = () => cartLoader.deleteCart();

    const onSuccessInternal = () => {
        // remove payment query
        queryClient.removeQueries({ queryKey: queries.payment.get.queryKey });
        queryClient.removeQueries({ queryKey: [QueryKeys.FETCH_CART] });

        onSuccess();
    };

    return useMutation({
        mutationFn: deleteCart,
        onSuccess: onSuccessInternal,
    });
};
