import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useEffect, useRef, type KeyboardEvent } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import Icon from '@mediashop/base/pattern/atom/Icon';

const componentName = 'search-input';

type Props = {
    searchQuery: string;
    debouncedSearchQuery: string;
    setSearchQuery: (searchQuery: string) => void;
    clearSearch?: () => void;
    searchSubmit: (event?: KeyboardEvent<HTMLInputElement>) => void;
    autoCompleteSuggestion: string;
    isMobileSearchPortal?: boolean;
};

const SearchInput = ({
    clearSearch,
    searchQuery,
    debouncedSearchQuery,
    setSearchQuery,
    searchSubmit,
    autoCompleteSuggestion,
    isMobileSearchPortal = false,
}: Props): JSX.Element => {
    const intl = useIntl();
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (searchQuery.length > 0) {
            inputRef.current?.focus();
        }
    }, [searchQuery]);

    const onClickSubmit = () => {
        if (searchQuery.length > 1) {
            searchSubmit();
        } else {
            inputRef.current?.focus();
        }
    };

    return (
        <>
            {/* Search */}
            <input
                ref={inputRef}
                className={classNames(`${componentName}__input`, {
                    [`${componentName}__input--open-portal`]: isMobileSearchPortal,
                })}
                value={searchQuery}
                onInput={(event) => setSearchQuery((event.target as HTMLInputElement).value.trimStart())}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => searchSubmit(event)}
                type="text"
                id="main-navigation-search"
                placeholder={intl.formatMessage({ id: 'mainNavigation.search' })}
                name="search"
            />

            {/* This second input is to display the autocomplete text */}
            <input
                type="text"
                className={classNames(`${componentName}__input ${componentName}__input--autocomplete`, {
                    [`${componentName}__input--open-portal`]: isMobileSearchPortal,
                })}
                disabled
                value={debouncedSearchQuery === searchQuery ? autoCompleteSuggestion : ''}
            />

            {clearSearch && searchQuery.length ? (
                <div
                    onClick={() => clearSearch()}
                    className={classNames(`${componentName}__icon-button`, `${componentName}__icon-button-clear`, {
                        [`${componentName}__icon-button--open-portal`]: isMobileSearchPortal,
                    })}
                >
                    <Icon name="Close" />
                </div>
            ) : (
                SKIP_RENDER
            )}

            {/* Search button */}
            <div
                onClick={() => onClickSubmit()}
                className={classNames(`${componentName}__icon-button`, `${componentName}__icon-button-search`, {
                    [`${componentName}__icon-button--open-portal`]: isMobileSearchPortal,
                })}
            >
                <Icon name="Search" />
            </div>
        </>
    );
};

export default SearchInput;
