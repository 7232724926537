/* eslint-disable camelcase */
import { usePartner } from '@mediashop/app/hooks/usePartner';
import { FormEvent, useMemo, useState } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@mediashop/base/pattern/atom/Button';
import { EMPTY_STRING, SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useAddDiscountCode } from '@mediashop/app/hooks/api/useAddDiscountCode';
import { ApiError } from '@mediashop/app/api/ApiError';
import { ApiErrorV2 } from '@mediashop/app/api/ApiErrorV2';
import { getDiscountFromLineItems } from '@mediashop/base/utils/discounts';
import { useCart } from '@mediashop/app/hooks/useCart';
import { useShopContext } from '@mediashop/app/hooks/useShopContext';
import Input from '../../atom/Input';

const componentName = 'voucher-redemption';

type DiscountErrorCode =
    | 'already_present'
    | 'non_applicable'
    | 'DISCOUNT_ALREADY_PRESENT'
    | 'DISCOUNT_CODE_NOT_APPLICABLE';

const DEFAULT_DISCOUNT_ERROR_MESSAGE_ID = 'cart.voucherCodeErrorCodes.nonApplicable';

const DiscountCodeErrorMessages: Record<DiscountErrorCode, string> = {
    already_present: 'cart.voucherCodeErrorCodes.alreadyPresent',
    non_applicable: 'cart.voucherCodeErrorCodes.nonApplicable',
    DISCOUNT_ALREADY_PRESENT: 'cart.voucherCodeErrorCodes.alreadyPresent',
    DISCOUNT_CODE_NOT_APPLICABLE: 'cart.voucherCodeErrorCodes.nonApplicable',
};

/**
 * Renders a voucher redemption
 * @param voucherCodes
 * @param isSticky
 */
function VoucherRedemption() {
    const intl = useIntl();

    const { partnerId } = usePartner();

    const [currentDiscountCode, setCurrentDiscountCode] = useState(EMPTY_STRING);
    const [errorMessage, setErrorMessage] = useState(EMPTY_STRING);

    const { cart } = useCart();
    const { currency } = useShopContext();

    const discounts = useMemo(() => {
        if (cart?.lineItems) {
            return Object.values(getDiscountFromLineItems(cart.lineItems, cart.discountCodes, currency));
        }
        return [];
    }, [cart, currency]);

    const onAddDiscountCodeError = (discountError: ApiError | ApiErrorV2) => {
        let errorCode;
        if ('error' in discountError) {
            errorCode = discountError.error.error;
        } else {
            errorCode = discountError.errors[0].code;
        }

        setErrorMessage(
            intl.formatMessage(
                {
                    id: DiscountCodeErrorMessages[errorCode] ?? DEFAULT_DISCOUNT_ERROR_MESSAGE_ID,
                },
                { code: currentDiscountCode }
            )
        );
    };

    const onAddDiscountCodeSuccess = () => {
        setCurrentDiscountCode(EMPTY_STRING);
        setErrorMessage(EMPTY_STRING);
    };

    const { mutate: addDiscountCode, isPending } = useAddDiscountCode({
        onError: onAddDiscountCodeError,
        onSuccess: onAddDiscountCodeSuccess,
    });

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        if (currentDiscountCode) {
            const isDiscountCodeAlreadyApplied = discounts.find((discount) => discount.code === currentDiscountCode);
            if (!isDiscountCodeAlreadyApplied) {
                addDiscountCode({ discountCode: currentDiscountCode, partnerId });
            } else {
                const discountError = {
                    error: {
                        error: 'DISCOUNT_ALREADY_PRESENT',
                    },
                } as ApiErrorV2;
                onAddDiscountCodeError(discountError);
            }
        }
    };

    return (
        <>
            <form className={`${componentName}__body`} onSubmit={handleSubmit}>
                <Input
                    className={`${componentName}__body-code`}
                    id="voucherCode"
                    label={intl.formatMessage({ id: 'cart.codePlaceholder' })}
                    name="voucherCode"
                    type="text"
                    value={currentDiscountCode}
                    onChange={(event) => setCurrentDiscountCode(event.target.value)}
                    placeholder={intl.formatMessage({ id: 'cart.codePlaceholder' })}
                    hideCheckIcon
                />
                <Button
                    type="submit"
                    disabled={!currentDiscountCode}
                    className={`${componentName}__body-btn`}
                    loading={isPending}
                >
                    <FormattedMessage id="cart.redeemCTA" />
                </Button>
            </form>

            {/** Error info */}
            {errorMessage ? <span className={`${componentName}__msg-error`}>{errorMessage}</span> : SKIP_RENDER}
        </>
    );
}

export default injectComponent('pattern.molecule.VoucherRedemption', VoucherRedemption);
