import { BaseProps, BrxLink } from '@mediashop/app/bloomreach/types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useCustomer } from '@mediashop/app/hooks/api/useCustomer';
import { useCart } from '@mediashop/app/hooks/useCart';
import useReactRouterLink from '@mediashop/app/hooks/useReactRouterLink';
import useRoute from '@mediashop/app/hooks/useRoute';
import Icon from '@mediashop/base/pattern/atom/Icon';
import CountrySwitch from '@mediashop/base/pattern/molecule/CountrySwitch';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { LoginDropdown } from '../LoginDropdown';

const componentName = 'user-menu';

export type UserMenuProps = BaseProps & {
    linksForLoggedInUsers: BrxLink[];
    userIconLinkLoggedIn: BrxLink;
    userIconLinkLoggedOut: BrxLink;
    closeMenu?: () => void;
};

const UserMenu = ({
    className,
    linksForLoggedInUsers,
    userIconLinkLoggedIn,
    userIconLinkLoggedOut,
    closeMenu,
}: UserMenuProps): JSX.Element => {
    const intl = useIntl();
    const customer = useCustomer();
    const { cart } = useCart();
    const { formatLinkBase } = useReactRouterLink();

    const isLoggedInCustomer = Boolean(customer);
    const loginOrAccountOverviewLink = isLoggedInCustomer
        ? formatLinkBase(userIconLinkLoggedIn.reference)
        : formatLinkBase(userIconLinkLoggedOut.reference);

    const cartRoute = useRoute('Frontend.Master.Cart');
    const cartLink = formatLinkBase(cartRoute);

    return (
        <div className={classNames(componentName, className)}>
            <CountrySwitch showTitle={false} icon="ChevronDown" />

            <div className={`${componentName}__account`} onClick={closeMenu && closeMenu}>
                <Link
                    to={loginOrAccountOverviewLink}
                    aria-label={intl.formatMessage({ id: 'mainNavigation.accountLinkLabel' })}
                >
                    <Icon name={isLoggedInCustomer ? 'AccountFilled' : 'Account'} />
                </Link>
                {isLoggedInCustomer ? <LoginDropdown links={linksForLoggedInUsers} /> : SKIP_RENDER}
            </div>

            <Link className={`${componentName}__cart-link`} to={cartLink} onClick={closeMenu && closeMenu}>
                <Icon name="Cart" />
                <div className={`${componentName}__cart-item-amount`}>{cart?.totalLineItemQuantity ?? 0}</div>
            </Link>
        </div>
    );
};

export default UserMenu;
