import Theme from '@mediashop/app/Theme';
import './pattern/organism/MainNavigationVerticalBrand/index.tsx'; import './pattern/organism/MainNavigationVerticalBrand/Menu/index.tsx';
import './style/index.scss';
import icons from './icons';
import CatalogBaseTheme from '@mediashop/catalog-base';
import components from './bloomreach';

const VerticalBrandBase: Theme = {
    name: 'vertical-brand-base',
    brComponents: components,
    extends: CatalogBaseTheme,
    icons,
};

export default VerticalBrandBase;
