/* eslint-disable id-length */
import { RefObject, useEffect, useImperativeHandle, useMemo, useRef, useState, type ReactElement } from 'react';

import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import * as Yup from 'yup';
import classNames from 'classnames';
import { useFormik, FormikHelpers, setNestedObjectValues } from 'formik';
import { AddressField, AddressFieldSet } from '@mediashop/app/api/types/AddressConfiguration';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import DynamicFormikFieldset from './DynamicFormikFieldset';
import DynamicFormikErrorFocus from './DynamicFormikErrorFocus';
import LoadingIndicator from '../../atom/LoadingIndicator';
import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';
import { EMAIL_REGEX } from '@mediashop/app/constants/emailRegex';

const componentName = 'dynamic-form';

/** Overwrite yup email validation */
Yup.addMethod(Yup.string, 'email', function validateEmail(message) {
    // eslint-disable-next-line no-invalid-this
    return this.matches(EMAIL_REGEX, {
        message,
        name: 'email',
        excludeEmptyString: true,
    });
});

function getInitialValues<T>(
    reducedFormFields: AddressField[],
    formikValues: Record<string, string>,
    defaultValues?: T
): Record<string, string> {
    const values = {};
    reducedFormFields.forEach((formField) => {
        const { name } = formField;

        if (formikValues[name]) {
            values[name] = formikValues[name];
        } else if (defaultValues?.[name]) {
            values[name] = defaultValues[name];
        } else if (formField.defaultValue) {
            values[name] = formField.defaultValue;
        } else if (formField.type === 'checkbox') {
            values[name] = false;
        } else {
            values[name] = EMPTY_STRING;
        }
    });
    return values;
}

const createYupSchemaValidator = (schema: Yup.Schema, config: AddressField) => {
    const { validations = [] } = config;

    validations.forEach((validation) => {
        const { params, type } = validation;
        if (!schema[type]) {
            return;
        }

        schema = schema[type](...params);
    });

    return schema;
};

export const createYupSchema = (schema: Yup.ObjectShape, config: AddressField): Yup.ObjectShape => {
    const { name, validationType = '', validationCondition, validationConditions } = config;
    if (!Yup[validationType]) {
        return schema;
    }

    let validator: Yup.Schema;

    if (validationCondition) {
        validator = Yup[validationType]();
        validator = validator.when(validationCondition.name, {
            is: validationCondition.value,
            then: (schema) => createYupSchemaValidator(schema, config),
        });
    } else if (validationConditions) {
        validator = Yup[validationType]();
        validator = validator.when(validationConditions[0].name, {
            is: validationConditions[0].value,
            then: (schema) =>
                schema.when(validationConditions[1].name, {
                    is: validationConditions[1].value,
                    then: (innerSchema) => createYupSchemaValidator(innerSchema, config),
                }),
        });
    } else {
        validator = createYupSchemaValidator(Yup[validationType](), config);
    }

    schema[name] = validator;
    return schema;
};

export const getReduceFormFieldSets = (formFieldSets: AddressFieldSet[]): AddressField[] =>
    formFieldSets.reduce((acc: AddressField[], curVal) => acc.concat(curVal.fields), []);

export type FormSubmitHandle = {
    submit: () => void;
};

type DynamicFormikProps<T> = BaseProps & {
    id?: string;
    refId?: RefObject<FormSubmitHandle>;
    defaultValues?: T;
    formFieldSets?: AddressFieldSet[];
    errorFocus?: boolean;
    onChange?: ({ values, validated }: { values: Record<string, any>; validated: boolean }) => void;
    onSubmit?: (
        values: Record<string, string>,
        formikHelpers: FormikHelpers<Record<string, string>>
    ) => void | Promise<any>;
    submitButton?: ReactElement;
    validateOnMount?: boolean;
};

/**
 * Formik Form-generator component which builds a form from the addresses config.
 */
export default function DynamicFormik<T>({
    refId,
    id,
    className,
    defaultValues,
    formFieldSets,
    errorFocus = false,
    onChange,
    onSubmit = noop,
    submitButton,
    validateOnMount = false,
}: DynamicFormikProps<T>): JSX.Element {
    const mounted = useRef(false);

    const [initialValues, setInitialValues] = useState<Record<string, string>>({});

    const reducedFormFields = useMemo(
        () => (formFieldSets ? getReduceFormFieldSets(formFieldSets) : []),
        [formFieldSets]
    );

    const validationSchema = useMemo(
        () => Yup.object().shape(reducedFormFields.reduce(createYupSchema, {} as Yup.ObjectShape)),
        [reducedFormFields]
    );

    const formik = useFormik<Record<string, string>>({
        enableReinitialize: true,
        validateOnMount,
        validateOnChange: true,
        initialValues,
        validationSchema,
        onSubmit,
    });

    useEffect(() => {
        const validate = async () => {
            const validationErrors = await formik.validateForm();
            if (Object.keys(validationErrors).length > 0) {
                formik.setTouched(setNestedObjectValues(validationErrors, true));
            }
        };

        if (validateOnMount) {
            validate();
        }
        // if we add formik here it causes an endless loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validateOnMount, formik.errors]);

    useEffect(() => {
        if (reducedFormFields) {
            const initialValues = getInitialValues<T>(reducedFormFields, formik.values, defaultValues);
            setInitialValues(initialValues);
        }
    }, [reducedFormFields]);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else if (!isEmpty(validationSchema)) {
            let isValid = validationSchema.isValidSync(formik.values);
            if (isValid && formik.values.isBirthdateValid !== undefined) {
                isValid = Boolean(formik.values.isBirthdateValid);
            }
            if (onChange) {
                onChange({
                    values: formik.values,
                    validated: isValid,
                });
            }
        }
    }, [formik.values, validationSchema]);

    useImperativeHandle(refId, () => ({
        submit: async () => {
            await formik.submitForm();
            formik.setSubmitting(false);
        },
    }));

    return formFieldSets ? (
        <form className={classNames(componentName, className)} id={id} onSubmit={formik.handleSubmit}>
            {formFieldSets.map((singleFieldset, index) => (
                <DynamicFormikFieldset key={index} formik={formik} {...singleFieldset} />
            ))}

            {errorFocus && (
                <DynamicFormikErrorFocus
                    isSubmitting={formik.isSubmitting}
                    isValidating={formik.isValidating}
                    errors={formik.errors}
                />
            )}

            {submitButton}
        </form>
    ) : (
        <LoadingIndicator />
    );
}
