import { AddressField, AddressFieldColumnsConfiguration } from '@mediashop/app/api/types/AddressConfiguration';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import { FormattedMessage } from 'react-intl';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import DynamicFormikField from '../DynamicFormikField';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { FormikProps } from 'formik';

type Props = BaseProps & {
    labelId?: string;
    data: AddressField[];
    formik: FormikProps<Record<string, string>>;
    columns?: AddressFieldColumnsConfiguration;
    doesConditionMatch: (field: AddressField) => boolean;
};

const componentName = 'loqate-wrapper';

const LoqateAddressField = ({ labelId, data, formik, columns, doesConditionMatch }: Props): JSX.Element => {
    const hasLoqateName = data.find((field) => field.name.includes('hasLoqate'))!.name;
    const loqate = data.filter((field) => field.type === 'loqate');
    const formFields = data.filter((field) => field.type !== 'loqate' && Boolean(field.type));

    const hideLinkForManualAddressInputs = data.find((field) => field.name === 'hideLinkForManualAddressInputs');

    const setShowLoqate = () => {
        formik.setFieldValue(hasLoqateName, formik.values[hasLoqateName] === 'true' ? 'false' : 'true');
    };

    return (
        <div className={componentName}>
            {labelId && <FormattedMessage id={labelId} />}
            {loqate.length ? (
                <>
                    <div className={`${componentName}__action`}>
                        <div onClick={() => setShowLoqate()} className={`${componentName}__action-link`}>
                            <FormattedMessage id="address.loqateManualAddress" />
                        </div>
                        <span>
                            <FormattedMessage id="address.loqateManualAddressHint" />
                        </span>
                    </div>
                    <div className="dynamic-fieldset__fields">
                        {loqate.map((field) => {
                            if (doesConditionMatch(field)) {
                                return null;
                            }
                            return <DynamicFormikField key={field.name} formik={formik} {...field} />;
                        })}
                    </div>
                </>
            ) : (
                SKIP_RENDER
            )}

            {formFields.length ? (
                <>
                    <div
                        className={classNames(
                            'dynamic-fieldset__fields',
                            `dynamic-fieldset__fields--cols-mobile-${columns?.mobile}`,
                            `dynamic-fieldset__fields--cols-tablet-${columns?.tablet}`,
                            `dynamic-fieldset__fields--cols-desktop-${columns?.desktop}`
                        )}
                    >
                        {formFields &&
                            formFields.map((field) => {
                                if (doesConditionMatch(field)) {
                                    return null;
                                }

                                return <DynamicFormikField key={field.name} formik={formik} {...field} />;
                            })}
                    </div>
                    {!hideLinkForManualAddressInputs ? (
                        <div className={`${componentName}__action`}>
                            <span onClick={() => setShowLoqate()} className={`${componentName}__action-link`}>
                                <FormattedMessage id="address.loqateSearchAddress" />
                            </span>
                        </div>
                    ) : (
                        SKIP_RENDER
                    )}
                </>
            ) : (
                SKIP_RENDER
            )}
        </div>
    );
};

export default injectComponent('pattern.molecule.DynamicFormik.LoqateAddressField', LoqateAddressField);
