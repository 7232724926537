/* eslint-disable id-length */
export default {
    test: 'Hello Test in English',
    account: {
        test: 'Hallo {name} in English with Account',
    },
    deal: {
        days: 'd',
        hours: 'h',
        minutes: 'm',
        seconds: 's',
        dealOver: 'The offer has expired.',
    },
    mainNavigation: {
        greeting: 'Hi, {name}',
        account: 'My account',
        logout: 'Log out',
        search: 'Search for products',
        content: 'Contents',
    },
    product: {
        quantityAdvantage: 'Quantity advantage: ',
        quantityAdvantageActive: 'Quantity advantage active!',
        ratingCount: '{count} {count, plural, one {Rating} other {Ratings}}',
        unitPriceSuffix: '\u202f/\u202f{baseUnitMultiplier}\u00a0{referenceUnit}',
        priceHint: 'Price including VAT',
        deliveryHint: 'Information regarding shippingcosts',
        savedMoneySuffix: ' Savings',
        buyNow: 'Buy now',
        addToCart: 'Add to cart',
        goToPdp: 'Go to productdetailpage',
        showDetails: 'Show details',
        descriptionShowMore: 'Show more..',
        descriptionShowLess: 'Show less...',
        variantSelectorTitle: 'Pick an option:',
        emptyVariant: 'Pick an option',
        chooseVariant: ' selection',
        emptyVariantError: 'Please select an option',
        variantSize: 'Size',
        variantColor: 'Color',
        badges: {
            installments: '{count} x {count, plural, one {Installment} other {Installments}}',
            xplusygratis: '{text} FREE',
            savings: '{count}% discount',
            savingsPDP: '-{count}%',
        },
        payOnce: 'or one time',
        payOnceSum: 'Total price',
        payOnceSumMobile: 'Total',
        bundleTitle: 'Also available as a set:',
    },
    preCheckout: {
        successMessage: 'Good choice! The article was added.',
        ctaMessage: 'add to cart and',
        ctaMessageBold: 'order with free shipping!',
        accessories: 'Suitable accessories',
        goBackBtn: 'Continue shopping',
        goToCartBtn: 'Go to Cart',
    },
    cart: {
        headline: 'Basket',
        addons: {
            headline: 'Nice addons',
            add: 'Add to order',
        },
        subtotal: 'Subtotal',
        deliveryCost: 'Delivery Cost',
        total: 'Total',
        shippedFreeOne: 'This order will be shipped ',
        shippedFreeTwo: 'FREE',
        shippedFreeThree: '!',
        vatIncluded: 'Tax included',
        savings: 'Total Savings',
        toCheckout: 'Checkout',
        goSafeToCheckout: 'Checkout',
    },
    checkout: {
        dividerText: 'Or',
        billingAddressHeadline: '1. Billing Address',
        paymentHeadline: '2. Payment Method',
        summaryHeadline: '3. Summary',
        nextStep: 'Next',
        buyNow: 'Buy Now',
        cart: 'Cart',
        lineItem: {
            quantity: 'Quantity',
        },
        freeDelivery: 'This order will be delivered FREE!',
        enterAddress: 'Please enter your address before selecting a payment-method.',
        summary: {
            headline: 'Summary',
            usps: {
                moneyBack: 'Money back guarantee',
                freeDelivery: 'free shipping',
                refund: '30 day refund',
                freeReturn: 'free return',
                securePayment: 'Secure payment',
            },
            subtotal: 'Subtotal',
            deliveryCost: 'Delivery Cost',
            total: 'Total',
            taxIncluded: 'incl. Tax',
            totalSavings: 'Total Savings',
            buyNow: 'Buy now',
            deliveryTime: 'Will be delivered in {time} workdays.',
            comment: 'Message to the deliverer',
        },
        orderNotSavedYet: 'Your order has not been saved, yet. Do you really want to leave the page?',
        customerClubExtensionModal: {
            title: 'Attention',
            text:
                'You are already a member of the customer club. The customer card has been removed from your shopping cart.' +
                'Would you like to buy an extension of your existing membership instead?',
            addToCart: 'Buy extension',
            closeButton: 'Close',
        },
        subscriptionInfo:
            'You need a customer account to purchase a subscription. Either log in with your existing customer ' +
            'account or simply create a new customer account.',
    },
    totalPrice: {
        taxIncluded: 'Tax incl.',
    },
    address: {
        sectorPlaceholder: 'Sec',
        blockPlaceholder: 'Bl',
        stairwayPlaceholder: 'Sc',
        floorPlaceholder: 'Et',
        apartmentPlaceholder: 'Ap',
        emailPlaceholder: 'Email Address',
        salutationPlaceholder: 'Salutation',
        salutations: {
            ms: 'Ms.',
            mr: 'Mr.',
            none: 'None',
        },
        firstnamePlaceholder: 'Firstname',
        lastnamePlaceholder: 'Lastname',
        phonePlaceholder: 'Phone',
        streetPlaceholder: 'Street',
        streetNumberPlaceholder: 'Streetno',
        zipCodePlaceholder: 'ZIP',
        cityPlaceholder: 'City',
        birthdateTitle: 'Birthday',
        birthdatePlaceholder: '1990-01-23',
    },
    checkoutSuccess: {
        headline: 'Thank You for your order',
        headlineError: 'An error has occurred',
        textFirst: 'We have received your order and will send you an additional confirmation to <link>{email}</link>.',
        textSecond: 'As soon as your order leaves our shipping-center, we will inform you once more per email.',
        textSecondPending:
            'Your order is being processed. We will notify you via E-Mail as soon as your order is created.',
        textError: 'Please contact our customer service regarding the status of your order.',
        noEmailPlaceholder: 'Your email address',
    },
    country: {
        DE: 'Germany',
        AT: 'Austria',
    },
    socialMedia: {
        facebook: 'Share on Facebook',
        twitter: 'Share on Twitter',
        whatsapp: 'Share on WhatsApp',
    },
    unused: {
        FirstNameErrorText: '...',
        LastNameErrorText: '...',
    },
    productListing: {
        noProducts: 'No products found',
        products: '{count} {count, plural, one {product} other {products}}',
        loadMore: 'Load more',
        from: 'of',
        articles: 'Articles',
        title: '{categoryName} | {shopName}{count, plural, one {} other { - page {count}}}',
    },
    pagination: {
        ariaPage: 'Page {pageNumber}',
        next: 'Next',
        previous: 'Back',
    },
    menu: {
        logout: 'Logout',
        logoutHeader: 'Attention',
        logoutText: 'Are you sure you want to logout?',
        logoutButtonText: 'Logout',
    },
    login: {
        emailPlaceholder: 'E-Mail Address',
        passwordLabel: 'Enter Password',
        usernameEmptyError: 'Username should not be empty',
        usernameEmailError: 'Username should be a valid Email address',
        passwordEmptyError: 'Password should not be empty',
        userLoginError: 'Username or Password are incorrect',
        authorizeStoreTitle: 'Authorize Existing Credentials',
        authorizeStoreText: 'Would you like to authorize your the usage of your existing credentials ?',
        yes: 'Yes',
        no: 'No',
    },
    orders: {
        order: 'Order',
        total: 'Total',
        details: 'Details',
        back: 'Back',
        shippingAddress: 'Shipping Address',
        paymentMethod: 'Payment method',
        paymentMethodNotApplicable: 'N/A',
        quantity: 'Quantity: {quantity}',
        reorder: 'Buy again',
        reOrderSuccess: 'Item Added Successfully',
        reOrderFail: 'Item Could not be added to the cart',
    },
    customer: {
        customerNumber: 'Customer number',
        billingAddress: 'Billing address',
        email: 'E-mail address',
        phone: 'Phone',
        password: 'Password',
        passwordText: 'The password is not displayed for security reasons.',
        passwordLinkText: 'Forgot Password?',
        customerCardLink: {
            headline: 'Link your online account with your club card',
            subHeadline: 'Use the advantages of the customer club also for your purchases in our online store',
            cardNumber: 'Card number',
            clubEmail: 'Customer club e-mail address',
            cta: 'Connect',
            success: 'Your customer club card has been successfully linked to your account.',
            error: {
                mykeneNotFound: 'No customer club card with the specified number could be found.',
                mykeneInvalidCombination: 'The email address does not match the customer club card.',
                mykeneError: 'An error has occurred. Please try again later.',
                cardExistsForStore: 'Your customer club card has already been added to an account.',
                unknownError: 'An error has occurred. Please try again later.',
            },
        },
    },
    register: {
        passwordEmptyError: 'Password cannot be empty',
        passwordLabel: 'Enter your password',
        passwordCriteriaHeader: 'Your password must ...',
        passwordCriteria: {
            numberOfCharacters: '✔ consist of at least 8 characters',
            hasNumber: '✔ contain 1 number',
            hasUppercaseLetter: '✔ contain 1 uppercase letter',
            hasLowercaseLetter: '✔ contain 1 lowercase letter',
        },
        passwordNotValidError: 'Password is not valid',
        acceptGDPRError: 'You must accept the terms and conditions',
        existingAccountModal: {
            text:
                'This e-mail address is already used. If you are having already an account for one of the following' +
                ' shops, please log in with your credentials.',
            shops: '<ul><li>haus-hobby.ch</li><li>maison-hobby.ch</li><li>haus-hobby.com</li></ul>',
            accept: 'Login',
            close: 'Close',
        },
    },
    countrySwitcher: {
        headline: 'Change language and shipping options',
        country: 'Delivery to',
        language: 'Language',
    },
    subcategoryFilter: {
        all: 'Alle',
        reset: 'Clear filter',
    },
    userManuals: {
        headline: 'Manuals',
        search: 'Search by product, article number or CEE-code',
        resetSearch: 'Reset search',
        table: {
            sku: 'Article number',
            code: 'Code',
            productTitle: 'Product',
            type: 'Type',
            types: {
                /* eslint-disable camelcase */
                instruction_manual: 'Instruction manual',
                recipe_booklet: 'Recipe booklet',
                training_plan: 'Training plan',
                nutrition_plan: 'Nutrition plan',
                /* eslint-enable camelcase */
            },
        },
        noResults: 'No manuals found.',
    },
    price: {
        from: 'from\u00a0{price}',
        reference: '{price}\u00a0/\u00a0{baseUnitMultiplier}\u00a0{referenceUnit}',
        statt: 'instead of {price}',
        uvp: 'UVP\u00a0{price}',
    },
};
