/**
 * Try parsing a string using JSON.parse()
 * @param json string representation of the JSON
 * @param fallbackValue value to fallback if parsing failed
 * @returns parsed JSON or fallback value
 */
export const tryJsonParse = <T>(json: string | null, fallbackValue: T): T => {
    if (!json) {
        return fallbackValue;
    }

    try {
        return JSON.parse(json);
    } catch (error) {
        return fallbackValue;
    }
};
