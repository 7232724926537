import { useCallback, useState } from 'react';
import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';
import { Variant } from '@mediashop/app/api/types/ClientProduct';
import { ProductWithRelation } from '../types/productRelations';
import { EmarsysCommand } from '@mediashop/app/analytics/emarsys/commants';
import { EmarsysRecommendResponse } from '@mediashop/app/analytics/emarsys/types';
import { triggerEmarsysEvent } from '@mediashop/app/analytics/emarsys/triggerEmarsysEvent';
import { useMappedCountry } from '@mediashop/app/hooks/useMappedCountry';
import { useLoader } from '@mediashop/app/store/loader';

type HookProps = {
    isCartPage?: boolean;
    variant?: Variant;
    containerId: string;
};

type HookReturnType = {
    isLoading: boolean;
    recommendedProducts: ProductWithRelation[];
    triggerRecommend: () => void;
};

export const useEmarsysRecommendations = ({ variant, containerId, isCartPage = false }: HookProps): HookReturnType => {
    const mappedCountry = useMappedCountry();
    const country = mappedCountry.toLowerCase();
    const { productClient } = useLoader();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [recommendedProducts, setRecommendedProducts] = useState<ProductWithRelation[]>([]);

    const handleCrosssellAiRecommendations = useCallback(
        async (productKeys: string[]) => {
            try {
                const relatedSku = variant?.sku ?? EMPTY_STRING;
                const products = await productClient.getProductsByKey(productKeys, 'STANDARD', relatedSku);

                const processedProducts: ProductWithRelation[] = products.map((product) => ({
                    ...product,
                    relationType: 'crosssell_AI',
                }));
                setRecommendedProducts(processedProducts);
            } finally {
                setIsLoading(false);
            }
        },
        [productClient, variant?.sku]
    );

    const triggerRecommend = useCallback(() => {
        triggerEmarsysEvent({ command: 'availabilityZone', data: country });
        triggerEmarsysEvent({
            command: EmarsysCommand.RECOMMEND,
            data: {
                logic: isCartPage ? 'CART' : `RELATED_${country}`,
                containerId,
                success: (recommendations: EmarsysRecommendResponse) => {
                    const productKeys = recommendations.page.products?.map((product) => product.id);
                    handleCrosssellAiRecommendations(productKeys);
                },
            },
        });
    }, [handleCrosssellAiRecommendations, containerId, country, isCartPage]);

    return {
        isLoading,
        recommendedProducts,
        triggerRecommend,
    };
};
