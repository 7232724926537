import { NostoCategory } from '@nosto/nosto-react';
import React from 'react';
import { SKIP_RENDER } from '../constants/semanticConstants';
import useGetCategoryPath from '../hooks/useGetCategoryPath';

const NostoCategoryWrapper = () => {
    const categoryPath = useGetCategoryPath();

    if (!categoryPath) {
        return SKIP_RENDER;
    }

    return <NostoCategory category={categoryPath} />;
};

export default NostoCategoryWrapper;
