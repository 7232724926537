import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CheckoutState {
    isActive: boolean;
    step: number;
    type?: 'OnePage' | 'MultiPage';
    isAddressValid?: boolean;
}

const initialState: CheckoutState = {
    isActive: false,
    step: 0,
    isAddressValid: undefined,
};

const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        checkoutStarted(state, action: PayloadAction<{ checkoutType: 'MultiPage' | 'OnePage'; activeTab: string }>) {
            const { checkoutType } = action.payload;
            return {
                ...state,
                isActive: true,
                type: checkoutType,
                step: 1,
            };
        },
        paymentsViewed(state) {
            return {
                ...state,
                step: 2,
            };
        },
        orderSummaryViewed(state) {
            return {
                ...state,
                step: 3,
            };
        },
        setIsAddressValid(state, action: PayloadAction<{ isAddressValid: boolean }>) {
            const { isAddressValid } = action.payload;
            return {
                ...state,
                isAddressValid,
            };
        },
    },
});

export const { checkoutStarted, paymentsViewed, orderSummaryViewed, setIsAddressValid } = checkoutSlice.actions;

export default checkoutSlice.reducer;
