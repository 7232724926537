/* eslint-disable id-length */
export default {
    test: 'Hallo Test in het Nederlands',
    account: {
        test: 'Hallo {name} in het Nederlands in het account',
        subscribeButton: 'Inschrijven voor de nieuwsbrief',
        unSubscribeButton: 'Afmelden voor de nieuwsbrief',
        unsubscribeSuccess: 'U heeft zich succesvol afgemeld voor de nieuwsbrief.',
    },
    deal: {
        days: 'D',
        hours: 'U',
        minutes: 'M',
        seconds: 's',
        dealOver: 'Het aanbod is verlopen.',
        countdownEnd: 'Deze aanbieding eindigt op',
        specialDealOver: 'Niet meer geldig',
    },
    mainNavigation: {
        greeting: 'Hallo, {name}',
        account: 'Mijn account',
        logout: 'Afmelden',
        search: 'Product zoeken',
        loggedout: 'Afgemeld!',
        searchSuggestion: 'Zoeksuggesties',
        productSuggestion: 'Productsuggesties',
        showResults: '{count, plural, =1 {Toon {text} resultaat} other {Toon alle {text} resultaten}}',
        resultSearch: '{count} {count, plural, one {Resultaat} other {Resultaten}} voor {query}',
        logoLinkLabel: 'Startpagina',
        accountLinkLabel: 'Klantenaccount',
        content: 'Inhoud',
    },
    product: {
        cheaperSet: '2e artikel slechts ',
        setTypeXplusY: '2e artikel slechts ',
        quantityAdvantage: 'Bundelvoordeel: ',
        quantityAdvantageActive: 'Volume voordeel actief!',
        ratingCount: '{count} {count, plural, one {Evaluatie} other {Recensies}}',
        unitPriceSuffix: '\u202f/\u202f{baseUnitMultiplier}\u00a0{referenceUnit}',
        priceHint: 'Prijs inclusief BTW.',
        deliveryHint: 'Informatie over verzendkosten en transactiekosten',
        savedMoneySuffix: 'besparingen',
        savedMoneyBadge: 'bespaard',
        buyNow: 'bestel nu',
        addToCart: 'In winkelwagen',
        goToPdp: 'naar de productpagina',
        showDetails: 'Kies variant',
        descriptionShowMore: 'Laat meer zien...',
        descriptionShowLess: 'Laat minder zien...',
        detailsShowMore: 'meer details',
        detailsShowLess: 'minder details',
        variantSelectorTitle: 'selecteer uw keuze:',
        emptyVariant: 'selecteer uw keuze',
        chooseVariant: ' kiezen',
        emptyVariantError: 'maak een keuze',
        suggestedPriceShort: 'adviesprijs',
        uvp: 'adviesprijs',
        statt: 'van',
        variantSize: 'maat',
        variantColor: 'kleur',
        badges: {
            installments: '{count}x {count, plural, one {Termijn\n} other {Termijnen\nelk}} {price}',
            installmentsVariants: 'vanaf {count}x {count, plural, one {Termijn\n} other {Termijnen\nelk}} {price}',
            installmentsVariantsAsAlternative:
                'of vanaf {count}x {count, plural, one {Termijn\n} other {Termijnen\nelk}} {price}',
            installmentsAsAlternative: 'of {count}x {count, plural, one {Termijn} other {Termijnen elk}} {price}',
            installmentsInFinalSummary: '{count}x {count, plural, one {Termijn} other {Termijnen elk}}',
            installmentsInCart: '{count}x {count, plural, one {Termijn} other {Termijnen elk}} {price}',
            xplusygratis: '{text} GRATIS',
            savings: '{count}% bespaard',
            savingsPDP: '-{count}%',
            deal: 'DEAL -{count}%',
            mobile: {
                gratisZubehoer: '+Accessoires',
            },
        },
        socialMediaShareHeadline: '{shopName}',
        socialMediaShareText: 'Kijk wat ik heb gevonden:',
        attributes: {
            headline: 'productinformatie',
            subheadline: 'technische specificaties',
            true: 'Ja',
            false: 'Nee',
            maxOrderQuantity: 'Max. bestelhoeveelheid',
            deliverables: 'diensten',
            name: 'Naam',
            description: 'beschrijving',
            usp: 'USP',
            productDetails: 'Productdetails',
            claim: 'claim',
            weightGross: 'Gewicht',
            articleType: 'Artikeltype',
            articleIsBuyable: 'Koopbaar',
            erpCategory1: 'categorie',
            erpCategory2: 'ondercategorie',
            crmArticleBundleNo: 'Artikelbundel Nr.',
            webSubtitle: 'Web ondertitel',
            countryLicensesAvailable: 'Beschikbaarheid per land',
            articleDimensions: 'Dimensies',
            textilMaterialComposition: 'Materiaal',
            relatedUpsell: 'Upsell',
            relatedUpgrade: 'accessoires',
            subscriptionAvailable: 'Abonnement',
            deliveryTimeFrom: 'Min. levertijd',
            deliveryTimeUntil: 'Max. levertijd',
            medicalProduct: 'Medisch apparaat',
            productChemicalIngredients: 'Ingrediënten',
            signalWord: 'Enkel woord',
            ean: 'EAN',
            eanNl: 'EAN NL',
            prices: 'Prijs',
            biocidalProduct: 'Biociden',
            shippingTimeCode: 'Levertijd code',
            expiryDate: 'Vervaldatum',
            productSafety: 'Productveiligheid',
            navBulkyItem: 'Omvangrijk item',
            subscriptionSelection: {
                day: '<b>Leveringsinterval</b>: {amount} {amount, plural, one {Dag} other {Dagen}}',
                month: '<b>Leveringsinterval</b>: {amount} {amount, plural, one {Mand} other {Manden}}',
                week: '<b>Leveringsinterval</b>: {amount} {amount, plural, one {Week} other {Week}}',
            },
            perDelivery: 'per levering',
        },
        addedToCart: 'Goede keuze! Het artikel is toegevoegd',
        cantAddToCart: 'Artikel kann niet worden toegevoegd',
        productNumber: 'Art.Nr.',
        productRelation: 'Vergelijkbare producten',
        payOnce: 'of eenmalig',
        payOnceSum: 'Totale prijs',
        payOnceSumMobile: 'Totaal',
        next: 'volgende',
        previous: 'vorige',
        deliveryProductCard: 'Levertijd: {from}-{until} werkdagen',
        topReview: 'Topscore',
        seeMore: 'Lees verder',
        seeLess: 'Laat minder zien',
        totalReviews: '{formattedCount} {count, plural, one {Beoordeling} other {Beoordelingen}}',
        downloadFor: 'Download handleiding voor',
        noReviews: 'Geen gegevens beschikbaar.',
        videoThumbnailText: 'Video',
        subscription: {
            oneTimeDelivery: 'Eenmalige aankoop',
            abo: 'Abonnement',
            emptyOption: 'Leveringsinterval:',
            intervalUnit: {
                day: '{amount} {amount, plural, one {Dag} other {Dagen}}',
                month: '{amount} {amount, plural, one {Maand} other {Maanden}}',
                year: '{amount} {amount, plural, one {Jaar} other {Jaren}}',
            },
            delivery: 'levering',
            recommendedInterval: 'aanbevolen',
        },
        exceededMaxOrderQuantity: 'Maximum bestelhoeveelheid ({amount}) bereikt voor dit artikel.',
        pageTitle: '{title}',
        productUnavailable: {
            headline: 'Product momenteel niet beschikbaar!',
            text: 'Kies in plaats daarvan een alternatief uit ons assortiment!',
        },
        bundleTitle: 'Ook beschikbaar als set:',
    },
    preCheckout: {
        successMessage: 'Goede keuze! Het artikel is toegevoegd',
        ctaMessage: 'aan winkelwagen toevoegen en',
        ctaMessageBold: 'Bestellen met gratis verzending',
        accessories: 'Bijpassende accessoires',
        goBackBtn: 'Doorgaan met winkelen',
        goToCartBtn: 'Naar winkelwagen',
        addToCartSingle: 'Details',
        addToCart: 'Toevoegen',
        addToCartUpgrade: 'Upgrade',
        relations: 'nu bestellen',
        toProduct: 'naar het product',
        deliverables: 'Omvang van de levering: ',
        clubSuggestion: 'Extra besparen?',
        clubCardAlreadyInCart: 'U heeft al een klantenkaart in uw winkelwagen.',
    },
    orderSuccessPage: {
        orderSuccess: 'Bedankt voor uw bestelling!',
        goToShop: 'Terug naar de winkel',
        accountQuestion: 'Stel een wachtwoord in en profiteren van de voordelen van een klantenaccount?',
        accountAdvantagesHeader: 'Uw voordelen met een klantaccount:',
        accountAdvantageOne: 'Overzicht van al uw bestellingen',
        accountAdvantageTwo: 'Snellere afhandeling van bestellingen',
        accountAdvantageThree: 'Beheer van uw klantgegevens',
        password: 'Wachtwoord',
        passwordReq: 'min. 8 karakters',
        passwordReqDescription: 'Voer een wachtwoord in.',
        passwordReqDescTitle: 'Uw wachtwoord moet:',
        passwordReqDescOne: 'minstens 8 tekens lang zijn',
        passwordReqDescTwo: 'minimaal 1 cijfer bevatten',
        passwordReqDescThree: 'minimaal 1 hoofdletter bevatten',
        passwordReqDescFour: 'minimaal 1 kleine letter inbegrepen',
        checkboxText: ' Ik wil graag op de hoogte blijven van actuele aanbiedingen van {shopName}.',
        checkboxInfo: 'U kunt zich op elk moment kosteloos uitschrijven.',
        passwordSaveBtn: 'Sla',
    },
    cart: {
        noReservation: 'Artikelen in de winkelwagen zijn niet gereserveerd',
        headline: 'Winkelwagen',
        articlesWithCount: '{count} Artikel',
        addons: {
            headline: 'Handig',
            add: 'Om mee te bestellen',
        },
        subtotal: 'Subtotaal',
        deliveryCost: 'Verzenden',
        total: 'Totaal',
        allTotal: 'Totaalbedrag',
        shippedFreeOne: 'Deze bestelling zal ',
        shippedFreeTwo: 'GRATIS',
        shippedFreeThree: ' Gratis verznding!',
        vatIncluded: 'Inclusief BTW',
        free: 'Gratis',
        savings: 'Uw bespaart',
        savingsText: 'Uw totale besparing ',
        toCheckout: 'Naar de Check-out',
        goSafeToCheckout: 'BETALEN',
        xArticle: '({amount} artikelen)',
        voucher: 'Voucher',
        redeemVoucher: 'Voer uw kortingscode in',
        codePlaceholder: 'Vouchercode (optioneel)',
        redeemCTA: 'Inwisselen',
        voucherCodeSuccess: 'Kortingscode "{code}" ingewisseld',
        voucherCodeErrorCodes: {
            alreadyPresent: 'Vouchercode "{code}" is al ingewisseld',
            nonApplicable:
                'Deze kortingsbon is niet (meer) actief of kan niet worden toegevoegd aan uw huidige winkelwagen!',
        },
        emptyCart: 'Uw winkelwagen is leeg',
        emptyCartMessage: 'Vul uw winkelwagen met de nieuwste alledaagse helpers en probleemoplossers',
        emptyCartButton: 'Terug naar de winkel',
        emptyCartButtonMessage: 'Doorgaan met winkelen',
        voucherModal: {
            headline: 'Uw voucher is succesvol geactiveerd!',
            headlineGiftLineItem: 'Je gratis cadeau is geactiveerd!',
            text: '{totalSavings} korting bij een minimale bestelwaarde {minValue}.',
            continue: 'WINKEL NU',
        },
        wkoProductTitle: 'Je gratis cadeau',
        wkoModalHeadline: 'Bestel tegelijkertijd bijpassende accessoires en verzeker u van een cadeau',
        wkoDeclineOffer: 'Geen dank',
        wkoAddToCartSuccess: 'Goede keuze!\nHet artikel is toegevoegd.',
        restoreCartError: 'Uw winkelwagen kon niet worden hersteld.',
        clubSavings: {
            clubSavingsText: 'Bespaar met klantenclub {savings}!',
            clubSavingsLink: 'Klik {clubSavingsUnderline} voor meer informatie',
            clubSavingsUnderline: 'hier',
        },
        clubExtensionCta: 'verlengen',
        freeShippingBanner: {
            freeShipping: 'gratis levering',
            headline: 'Slechts {onlyLeftPrice} tot {freeShipping} vanaf {fromPrice}',
            continue: 'Doorgaan met ',
            shopping: 'winkelen',
            order: 'Deze bestelling wordt ',
            freeDelivery: 'gratis thuisbezorgd!',
        },
    },
    category: {
        showMore: 'Toon meer',
        breadcrumbHomeAriaLabel: 'Homepage',
    },
    voucher: {
        codes: {
            notActive: 'De kortingscode is niet actief.',
            notValid: 'De kortingscode is niet meer geldig.',
            doesNotMatchCart: 'De kortingscode voldoet niet aan de eisen.',
            maxApplicationReached: 'De kortingscode kan niet meer worden ingewisseld.',
            applicationStoppedByPreviousDiscount:
                'De kortingscode kan niet worden ingewisseld vanwege een andere voucher.',
            matchesCart: 'Voucher is geldig',
        },
        modal: {
            dontShowLegalText: 'Vouchervoorwaarden verbergen',
            displayLegalText: 'Vouchervoorwaarden tonen',
            savings: '{amount} korting',
            headline: 'Welke voucher wil je behouden?',
            minimalOrderAmount: ' bij een minimale bestelwaarde van {amount}',
        },
        priceGiftLineItem: 'Gratis cadeau: -{price}',
    },
    checkout: {
        minicartEdit: 'aanpassen',
        dividerText: 'of',
        allowRecurrence: {
            checkbox: 'Terugkerende betalingen toestaan',
            error: 'U moet akkoord gaan met terugkerende betalingen.',
        },
        catalogBillingAddressHeadline: 'Factuur adres',
        editBillingAddress: 'Factuur adres bewerken',
        billingAddressHeadline: '1. Factuur adres',
        guestInfo:
            'Uw gegevens worden alleen voor deze bestelling verzameld en moeten voor verdere bestellingen ' +
            'opnieuw worden ingevoerd. Om te profiteren van de voordelen van een klantenaccount, kunt ' +
            'u na het afronden van uw bestelling een gratis klantenaccount aanmaken.',
        paymentHeadline: '2. Betalingswijze',
        summaryHeadline: '3. Overzicht',
        nextStep: 'Verder',
        checkOrder: 'Controleer bestelling',
        nextStepPayment: 'Verder naar betaling',
        lastStep: 'Stap terug',
        buyNow: 'Nu kopen',
        cart: 'Winkelwagen',
        lineItem: {
            quantity: 'Hoeveelheid',
        },
        freeDelivery: 'Deze bestelling wordt gratis verstuurd!',
        enterAddress:
            'Voer eerst uw adresgegevens in en bevestig vervolgens door op "Verder naar betaling" te klikken.' +
            '\n\nIn de volgende stap krijgt u de beschikbare betaalmethoden te zien.',
        addresses: {
            headline: 'Gegevens & verzending',
            customerLogin: 'Inloggen klantaccount',
            guestOrder: 'Als gast bestellen /<br>Gebruikersaccount aanmaken',
            register: 'Gebruikersaccount aanmaken',
        },
        payment: {
            paymentFee: 'Transactiekosten: {price}',
            noPaymentFee: 'Geen transactiekosten',
            noPaymentFeeForNachnahme: 'Geen transactiekosten',
            networkError:
                'Helaas kunnen wij u momenteel geen aankoop op rekening aanbieden ' +
                'Selecteer a.u.b. een andere betaalmethode',
            tryOtherNetworkError:
                'Helaas kunnen wij u momenteel geen aankoop op rekening aanbieden. ' +
                'Selecteer a.u.b. een andere betaalmethode',
            installmentNetworkError:
                'Helaas kunnen wij voor deze bestelling geen betaling in termijnen aanbieden. ' +
                'Selecteer a.u.b. een andere betaalmethode.',
            invoiceNetworkError:
                'Helaas kunnen wij voor deze bestelling geen aankoop op rekening aanbieden. ' +
                'Selecteer a.u.b. een andere betaalmethode.',
            invoiceCheckDateOfBirth:
                'Uw gegevens konden niet met succes worden geverifieerd. ' +
                'Controleer de invoer van de geboortedatum en zorg ervoor dat deze correct en volledig is.',
            invoiceAddressError:
                'Bij aankoop op rekening moeten het leverings- en factuuradres ter identificatie overeenkomen.' +
                'Uw huidige adresgegevens voldoen hier niet aan en daarom is aankoop op rekening niet mogelijk.' +
                'Selecteer een alternatieve betalingsoptie om uw bestelling af te ronden.',
            genericError:
                'Uw betaling is mislukt. Controleer uw factuur- en betalingsgegevens voordat u het opnieuw ' +
                'probeert, of gebruik een andere betaalmethode.',
            unavailable: 'Betaalmethode tijdelijk niet beschikbaar',
            invoice: 'Aankoop op factuur',
            invoiceoffline: 'Aankoop op factuur',
            mastercard: 'Mastercard',
            paypal: 'PayPal',
            paypalexpress: 'PayPal Express',
            postfinancecard: 'PostFinance',
            visa: 'VISA',
            sofortueberweisung: 'Overschrijving',
            nachnahme: 'Onder rembours',
            installment: 'Betaling in termijnen',
            installmentActivateCTA: 'Kijk nu',
            installmentCheckbox:
                'U gaat akkoord met de overeenkomst op afbetaling en kunt de ' +
                'ontwerp-kredietovereenkomst op afbetaling {linkStart}hier{linkEnd} downloaden.',
            afterpayinvoice: 'AfterPay Riverty',
            idealr: 'iDEAL',
            mistercashR: 'Bancontact',
            unavailableShippingCountryTitle: 'Hint',
            unavailableShippingCountry:
                'De bestelling kan niet worden verzonden naar de door u geselecteerde afleverlocatie.',
            descriptions: {
                visa: 'Veilig betalen via Visa Secure.',
                mastercard: 'Veilig betalen via Mastercard Identity Check.',
                ideal:
                    'Betaal met uw online bankgegevens. Na de aankoopbevestiging wordt u direct doorgestuurd naar ' +
                    'het online portaal van uw bank, waar u kunt inloggen met uw gegevens voor uw internetbankieren ' +
                    'om de betaling uit te voeren.',
                afterPay:
                    'Betalen op factuur in slechts enkele stappen. Deze betaalmethode wordt uitgevoerd door ' +
                    'AfterPay Riverty en vereist een minimum leeftijd van 18 jaar. Het correct invoeren van uw ' +
                    'geboortedatum en telefoonnummer is verplicht zodat AfterPay Riverty een identiteits- en ' +
                    'kredietwaardigheidscontrole kan uitvoeren.',
                bancontact:
                    'Je hebt een Bancontact-kaart nodig bij een deelnemende bank. Na de aankoopbevestiging ' +
                    'wordt u rechtstreeks naar het online Bancontact-portaal geleid, waar u ofwel de Bancontact-app ' +
                    'kunt gebruiken of uw Bancontact-kaartgegevens handmatig kunt invoeren om de betaling af te ' +
                    'ronden.',
                payPal:
                    'Gemakkelijk betalen via uw PayPal-rekening. Aan het einde van uw bestelproces wordt u ' +
                    'rechtstreeks doorgestuurd naar PayPal. Uw bestelling is pas compleet nadat de betaling ' +
                    'daar is verwerkt.',
            },
        },
        shippingAddressHeadline: 'Leveringsadres',
        summary: {
            headline: 'Overzicht',
            usps: {
                moneyBack: 'Geldteruggarantie',
                freeDelivery: 'Geen verzendkosten',
                refund: '30 dagen retourbeleid',
                freeReturn: 'Gratis retour',
                securePayment: 'Veilig betalen',
            },
            subtotal: 'Totaal',
            deliveryCost: 'Verzendkosten',
            bulkyDeliveryCost: 'Verzendkosten voor bulk goederen',
            transactionFee: 'Transactiekosten',
            total: 'Totaal',
            taxIncluded: 'incl. BTW',
            totalSavings: 'Totale besparing',
            privacy: 'We gebruiken uw gegevens alleen in overeenstemming met ons {termsDetails}.',
            legal: 'Door op "Nu kopen" te klikken, gaat u akkoord met de {termsDetails} van {shopName}.',
            legalDetails: {
                privacyText: 'privacybeleid',
                privacyLink: '/privacyverklaring',
                termsText: 'Algemene Voorwaarden',
                termsLink: '/agb',
            },
            permissionEmailPhonePost:
                'Ik ga ermee akkoord dat mijn gegevens – zoals beschreven in de ' +
                '<link>Datenschutzerklärung</link> - voor informatie over nieuwe producten ' +
                'en diensten worden verwerkt per e-mail, telefoon of post. Deze toestemming ' +
                'kan ik elk moment terugtrekken',
            permissionEmailPhonePostDetails: {
                link: '/Privacy',
            },
            buyNow: 'Nu kopen',
            deliveryTime: 'Leverbaar binnen {time} werkdagen',
            newsletterSubscription:
                'Ik ontvang graag de {shopName} nieuwsbrief met aanbiedingen, voordelen en vouchers per e-mail. ' +
                'U kunt zich hiervoor op elk moment eenvoudig afmelden via een link in de nieuwsbrief.',
            comment: 'Bericht aan de bezorger',
        },
        orderNotSavedYet: 'Uw bestelling is nog niet voltooid. Wilt u de site echt verlaten?',
        resetPassword: {
            title: 'Noot',
            textBold: 'U bent reeds klant bij ons en had reeds toegang tot onze oude online shop?',
            textNormal: '{link} en blijf uw bestaande klantenaccount gebruiken.',
            textCta: 'Reset nu uw vorige wachtwoord',
            resetButton: 'Wachtwoord resetten',
            closeButton: 'Sluiten',
        },
        multiPage: {
            goToCartModal: {
                headline: 'Attentie',
                description: 'Weet u zeker dat u terug wilt naar uw winkelwagen?',
                dismiss: 'Blijf in het afrekenproces',
                submit: 'Winkelwagen bewerken',
            },
            summary: {
                amount: 'Hoeveelheid: {amount}',
                billingAddress: 'Factuur adres',
                billingAndDelivery: 'Factuur & Levering',
                change: 'aanpassen',
                lineItemTotalPrice: 'Totale prijs: {price}',
                paymentType: 'Betaalmethode',
                shippingAddress: 'Leverings adres',
                title: 'Overzicht',
                yourOrder: 'Uw bestelling',
            },
            headlines: {
                headline: 'Veilig bestellen in 3 stappen',
                address: 'Factuur adres',
                payment: 'Betalingswijze',
                summary: 'Overzicht',
            },
            nextButton: 'Volgende',
            checkDataButton: 'Details controleren',
        },
        customerClubExtensionModal: {
            title: 'Attentie',
            text:
                'U bent al lid van de klantenclub. De klantenkaart is uit uw winkelwagen verwijderd. ' +
                'Wilt u in plaats daarvan een verlenging van uw bestaande lidmaatschap kopen?',
            addToCart: 'Koop verlenging',
            closeButton: 'Sluiten',
        },
        subscriptionInfo:
            'Om een abonnement af te sluiten heeft u een klantaccount nodig. Log in met uw bestaande ' +
            'klantaccount of maak eenvoudig een nieuw klantaccount aan.',
    },
    totalPrice: {
        taxIncluded: 'incl. BTW',
    },
    address: {
        sectorPlaceholder: 'Sec',
        blockPlaceholder: 'Bl',
        stairwayPlaceholder: 'Sc',
        floorPlaceholder: 'Et',
        apartmentPlaceholder: 'Ap',
        billingAddress: 'Factuur adres*',
        requiredField: 'Alle velden met een * zijn verplicht.',
        emailLabel: 'E-mail adres',
        emailPlaceholder: 'E-mail adres',
        emailErrorText: 'Vul een geldig e-mailadres in.',
        salutationLabel: 'Begroeting*',
        salutationPlaceholder: 'Selecteer begroeting',
        salutations: {
            ms: 'Vrouw',
            mr: 'Man',
            none: 'Geen keuze',
        },
        customerTypeLabel: 'Klant type',
        customerType: {
            private: 'Particulier',
            corporate: 'Bedrijf',
        },
        contactLabel: 'Contact*',
        contactPerson: 'Contactpersoon',
        contactInfo: 'Contactinformatie',
        firstnamePlaceholder: 'Voornaam',
        firstnameErrorText: 'Voer een geldige voornaam in.',
        lastnamePlaceholder: 'Achternaam',
        lastnameErrorText: 'Voer een geldige achternaam in.',
        nameLengthErrorText: 'Maximaal 200 tekens toegestaan.',
        phonePlaceholder: 'Telefoonnummer',
        phoneErrorText: 'Voer een telefoonnummer in het formaat {examplePhoneNumber} in',
        company: 'Bedrijf',
        companyPlaceholder: 'Bedrijf',
        companyErrorText: 'Voer een geldig bedrijfsnaam in.',
        vatIdPlaceholder: 'btw nummer',
        vatIdErrorText: 'Voer een geldig btw nummer in.',
        address: 'Adres',
        streetPlaceholder: 'Straat',
        streetErrorText: 'Voer een geldige straatnaam in.',
        streetLengthErrorText: 'Maximaal 100 tekens toegestaan.',
        streetLengthErrorTextCZ: 'Maximaal 40 tekens toegestaan.',
        streetNumberPlaceholder: 'Huisnummer',
        streetNumberErrorText: 'Voer een geldig huisnummer in.',
        zipCodePlaceholder: 'Postcode',
        zipCodeErrorText: 'Voer een geldige postcode in.',
        cityPlaceholder: 'Plaats',
        cityErrorText: 'Voer een geldige plaatsnaam in.',
        country: 'Land',
        countryLabel: 'Land',
        countryPlaceholder: 'Land kiezen',
        countyLabel: 'District',
        countyPlaceholder: 'District kiezen',
        packingStationPlaceholder: 'Bezorging aan een pakketpunt:*',
        packingStation: {
            yes: 'Ja',
            no: 'Nee',
        },
        shippingAddressHeadline: 'Leveringsadres:*',
        shippingAddressPlaceholder: 'Dit is ook het leveringsadres:*',
        shippingAddress: {
            yes: 'Ja',
            no: 'Nee',
        },
        dayPlaceholder: 'Dag',
        monthPlaceholder: 'Maand',
        yearPlaceholder: 'Jaar',
        birthdateTitle: 'Geboortedatum (optionele)',
        birthdatePlaceholder: '1990-01-23',
        birthdateErrorText: 'Voer een geldige geboortedatum in',
        birthdateTooYoungErrorText: 'Je moet minimaal 18 jaar oud zijn.',
        birthdateDayErrorText: '*Ongeldig',
        birthdateMonthErrorText: '* Ongeldig ',
        birthdateYearErrorText: '*Ongeldig',
        birthdayTitle: 'Geboortedatum',
        birthDayError: ' De leeftijd mag niet hoger zijn dan 100 jaar.',
        loqatePlaceholder: 'Voer het adres in',
        loqateManualAddress: 'Voer het adres handmatig in',
        loqateManualAddressHint: 'of zoek handig op adres:',
        loqateSearchAddress: 'Alternatief zoeken naar adres?',
    },
    checkoutSuccess: {
        headline: 'Bedankt voor uw bestelling',
        headlineError: 'Er is een fout opgetreden',
        textFirst: ' We hebben uw bestelling ontvangen en sturen u ook een bevestiging <link>{email}</link>.',
        textSecond: ' Zodra uw pakket wordt verstuurd, informeren wij u opnieuw per e-mail.',
        textSecondPending: 'Uw bestelling wordt verwerkt. Wij houden u op de hoogte als de orderstatus is aangepast.',
        textError: ' Neem contact op met de klantenservice over de status van uw bestelling.',
        noEmailPlaceholder: 'Uw E-Mail-Adres',
        newsletter: {
            headline: 'Newsletter',
            text:
                'Ik wil graag gratis op de hoogte worden gehouden van actuele aanbiedingen van {shopName} ' +
                'door middel van een nieuwsbrief.',
            feedbackText:
                'Inschrijving nieuwsbrief succesvol! U ontvangt binnenkort een e-mail met een link om ' +
                'uw e-mailadres te bevestigen.',
            error: 'Registratie voor de nieuwsbrief is momenteel niet mogelijk.',
        },
        catalog: {
            headline: 'Catalogus',
            text: 'Ik wil de catalogus van {shopName} gratis en comfortabel per post thuis ontvangen.',
            feedbackText: 'De catalogus wordt binnenkort naar uw adres gestuurd.',
            error: 'De catalogusbestelling is momenteel niet mogelijk.',
        },
    },
    county: {
        alba: 'Alba',
        arad: 'Arad',
        arges: 'Arges',
        bacau: 'Bacau',
        bihor: 'Bihor',
        bistritaNasaud: 'Bistrita Nasaud',
        botosani: 'Botosani',
        braila: 'Braila',
        brasov: 'Brasov',
        bucuresti: 'Bucuresti',
        buzau: 'Buzau',
        calarasi: 'Calarasi',
        carasSeverin: 'Caras Severin',
        cluj: 'Cluj',
        constanta: 'Constanta',
        covasna: 'Covasna',
        dambovita: 'Dambovita',
        dolj: 'Dolj',
        galati: 'Galati',
        giurgiu: 'Giurgiu',
        gorj: 'Gorj',
        harghita: 'Harghita',
        hunedoara: 'Hunedoara',
        ialomita: 'Ialomita',
        iasi: 'Iasi',
        ilfov: 'Ilfov',
        maramures: 'Maramures',
        mehedinti: 'Mehedinti',
        mures: 'Mures',
        neamt: 'Neamt',
        olt: 'Olt',
        prahova: 'Prahova',
        salaj: 'Salaj',
        satuMare: 'Satu Mare',
        sibiu: 'Sibiu',
        suceava: 'Suceava',
        teleorman: 'Teleorman',
        timis: 'Timis',
        tulcea: 'Tulcea',
        valcea: 'Valcea',
        vaslui: 'Vaslui',
        vrancea: 'Vrancea',
    },
    country: {
        select: 'Land aanpassen',
        AT: 'Oostenrijk',
        DE: 'Duitsland',
        CH: 'Zwiterland',
        LI: 'Liechtenstein',
        HU: 'Hongarije',
        NL: 'Nederland',
        BE: 'Belgique',
        IT: 'Italië',
        BG: 'Bulgarije',
        HR: 'Kroatië',
        CY: 'Cyprus',
        CZ: 'Tsjechische Republiek',
        DK: 'Denemarken',
        EE: 'Estland',
        FI: 'Finland',
        FR: 'Frankrijk',
        GR: 'Griekenland',
        IE: 'Ierland',
        LV: 'Letland',
        LT: 'Litouwen',
        LU: 'Luxemburg',
        MK: 'Macedonië',
        MT: 'Malta',
        PL: 'Polen',
        PT: 'Portugal',
        RO: 'Roemenië',
        SM: 'San Marino',
        SK: 'Slowakije',
        SI: 'Slovenië',
        ES: 'Spanje',
        SE: 'Zweden',
    },
    socialMedia: {
        facebook: 'Deel op Facebook ',
        twitter: 'Deel op Twitter',
        whatsapp: 'Deel op Whatsapp ',
        native: 'Deel',
    },
    productListing: {
        noProducts: 'Geen producten gevonden',
        products: '{count} {count, plural, one {Product} other {Producten}}',
        loadMore: 'Laden',
        from: 'van',
        articles: 'Artikel',
        title: '{categoryName} {count, plural, one {} other { - Pagina {count}}}',
    },
    pagination: {
        ariaPage: 'Pagina {pageNumber}',
        next: 'volgende',
        previous: 'vorige',
    },
    menu: {
        logout: 'Afmelden',
        logoutHeader: 'Let op',
        logoutText: 'Wilt u echt afmelden?',
        logoutButtonText: 'Afmelden',
    },
    login: {
        password: 'Passwort',
        submitButtonLabel: 'Veilig inloggen',
        emailPlaceholder: 'E-Mail-Adres',
        passwordLabel: 'Wachtwoord opgeven',
        usernameEmptyError: 'Gebruikersnaam mag niet leeg zijn',
        usernameEmailError: 'De gebruikersnaam moet een geldig e-mailadres zijn.',
        userLoginError: 'Gebruikersnaam of wachtwoord is onjuist',
        passwordForgotten: 'Wachtwoord vergeten?',
        loginSuccess: 'U bent ingelogd',
        passwordEmptyError: 'Wachtwoord mag niet leeg zijn',
        existingUser: 'Ik ben al klant om te registeren',
        resetPassword: 'Wachtwoord resetten',
        resetEmailSent: 'Wij hebben u een email verstuurd.',
        resetErrorQuestions: 'Geen bericht ontvangen?',
        resetErrorAnswerOne: 'Controleer de Spamm-folder van uw emailinbox.',
        resetErrorAnswerTwo: 'Controleer uw gegevens nogmaals.',
        resetErrorAnswerThree: ' Wacht 15 minuten voordat u het opnieuw probeert.',
        newPassword: 'Nieuw wachtwoord',
        passwordNotMatchingReq: 'Wachtwoord voldoet niet aan de vereisten',
        passwordsNotIdentical: 'Nieuwe wachtwoorden komen niet overeen',
        repeatPassword: 'Wachtwoord bevestigen',
        changePassword: 'Wachtwoord aanpassen',
        passwordChanged: 'Het wachtwoord is succesvol gewijzigd',
        emailConfirmed: 'E-mail bevestigd. U kunt zich nu inschrijven',
        processToken: ' Er is een fout opgetreden. Probeer het later opnieuw ',
        generalError: ' Er is een fout opgetreden. Probeer het later opnieuw ',
        authorizeStoreTitle: 'Bestaande inloggegevens autoriseren ',
        authorizeStoreText: 'M Wilt u het gebruik van uw bestaande toegangsgegevens autoriseren?',
        yes: 'Ja',
        no: 'Nee',
        emailUnconfirmed: 'Bevestig eerst uw emailadres.',
    },
    orders: {
        order: 'Bestelling',
        total: 'Totaal',
        details: 'Details bekijken',
        back: 'Terug',
        shippingAddress: 'Afleveradres',
        paymentMethod: 'Betaalmethode',
        paymentMethodNotApplicable: 'N/A',
        quantity: 'Aantal: {quantity}',
        reorder: 'Nogmaals kopen ',
        reOrderSuccess: 'Artikel sucessvol toegevoegd ',
        reOrderFail: 'Artikel kon niet worden toegevoegd aan winkelwagen.',
        date: 'Datum',
        progress: '{items} van {total} bestellingen',
    },
    customer: {
        mr: 'meneer',
        mrs: 'mevrouw',
        customerNumber: 'klantnummer',
        billingAddress: 'Factuur adres',
        email: 'E-mail adres',
        phone: 'Telefoonnummer',
        password: 'Wachtwoord',
        passwordText: 'Het wachtwoord wordt veranderd voor de veiligheid niet getoond.',
        passwordLinkText: 'Wachtwoord vergeten?',
        tokenNotReceived: 'Uw e-mail komt niet voor in onze database',
        emailInvalid: 'Ongeldig e-mail adres',
        dataUpdated: 'Gegevens bijgewerkt',
        wrongPassword: 'Wachtwoord kon niet worden gewijzigd',
        couldNotResetPassword: 'Wachtwoord reset niet mogelijk.',
        customerClub: {
            cardNumber: 'Kaartnummer: {number}',
            validFrom: 'Lid sinds: {date}',
            validThru: 'Geldig t/m: {date}',
            expiryWarning: 'Let op: uw kaart verloopt over {days} {days, plural, one {dag} other {dagen}}.',
            cardExpired: 'Let op: uw kaart is verlopen.',
            offerCardRenewal: 'Nieuwe kaart bestellen',
        },
        customerCardLink: {
            headline: 'Koppel uw online account aan uw Club Card',
            subHeadline: 'Profiteer van de klantenclub bij het winkelen in onze online shop.',
            cardNumber: 'Kaartnummer',
            clubEmail: 'E-mailadres van de klantenclub',
            cta: 'Link',
            success: 'Uw klantenclubkaart is succesvol aan uw account gekoppeld.',
            error: {
                mykeneNotFound: 'Er kon geen klantenclubkaart met het opgegeven nummer worden gevonden.',
                mykeneInvalidCombination: 'Het e-mailadres komt niet overeen met de klantenclubkaart.',
                mykeneError: 'Er is een fout opgetreden. Probeer het later nog eens.',
                cardExistsForStore: 'Uw klantenclubkaart is al toegevoegd aan een rekening.',
                unknownError: 'Er is een fout opgetreden. Probeer het later nog eens.',
            },
        },
    },
    register: {
        passwordEmptyError: 'Wachtwoord mag niet leeg zijn',
        passwordMinError: 'Het wachtwoord moet minimaal 8 tekens lang zijn',
        passwordNotValidError: 'Minimaal 1x een cijfer, 1x een hoofdletter en 1x een kleine letter',
        passwordConfirmationError: 'De wachtwoorden komen niet overeen',
        passwordLabel: 'Wachtwoord aanmaken',
        passwordConfirmationLabel: 'Herhaal wachtwoord',
        passwordCriteriaHeader: 'Uw wachtwoord moet ...',
        passwordCriteria: {
            numberOfCharacters: '✔ minimaal 8 tekens lang zijn',
            hasNumber: '✔ mind. 1 cijfer toevoegen',
            hasUppercaseLetter: '✔ mind. 1 hoofdletter toevoegen',
            hasLowercaseLetter: '✔ mind. 1 kleine letter toevoegen',
        },
        acceptGDPRError: 'U moet akkoord gaan met het privacybeleid',
        nextStepPayment: 'Verder naar betaling',
        error: 'Sorry, er is een fout opgetreden. Probeer het opnieuw.',
        successMessage: 'Registratie voltooid. Bevestig eerst uw e-mail adres.',
        mainError: 'Corrigeer alstublieft de fouten!',
        change: 'OPSLAAN',
        accountVerificationTitle: 'Account verificatie',
        accountVerificationText:
            'U ontvangt straks een e-mail met een link naar ' +
            'Activering van uw klantaccount. Let op: deze link is slechts 24 uur geldig.',
        accountVerificationCTA: 'OK',
        existingAccountModal: {
            text:
                'Dit e-mailadres is al in gebruik. Als u een account heeft voor een van de volgende winkels, ' +
                'log dan in met uw bekende inloggegevens.',
            shops: '<ul><li>haus-hobby.ch</li><li>maison-hobby.ch</li><li>haus-hobby.com</li></ul>',
            accept: 'Naar de login',
            close: 'Sluiten',
        },
        newsletterSubscription: 'Ik wil graag op de hoogte gehouden worden van actuele aanbiedingen van {shopName}.',
    },
    countrySwitcher: {
        headline: 'Taal en verzendopties aanpassen',
        country: 'Levering aan',
        language: 'Taal',
        button: 'Opslaan',
        modal: {
            headline: 'Attentie',
            description:
                'U verandert uw regio in {newSelectedCountry}. Om iets te kunnen bestellen, moet uw ' +
                'verzendadres in die regio zijn. Als u niet in de valuta van de regio betaalt, kan uw bank u ' +
                'wisselkosten aanrekenen. Alle artikelen in uw winkelwagen worden verwijderd.',
            submitText: 'Selecteer',
            closeText: 'Annuleren',
        },
    },
    subcategoryFilter: {
        all: 'Alle',
        reset: 'Reset',
    },
    personalInfo: {
        newEmail: 'Nieuw e-mail adres',
        newEmailConfirm: 'Nieuwe e-mail bevestigen',
        passwordConfirm: 'Wachtwoord bevestigen',
        currentPassword: 'Huidig wachtwoord',
        newPassword: 'Nieuw wachtwoord',
        newPasswordConfirm: 'Nieuwe wachtwoord bevestigen',
        actualEmail: 'Huidig e-mail adres',
    },
    newsletter: {
        inputPlaceholder: 'E-Mail adres',
        success: 'U ontvangt straks.',
        invalidEmail: 'Vul een geldig e-mail adres in.',
        error: 'Helaas is er een probleem opgetreden bij het aanmelden van de nieuwsbrief.',
        emailEmptyError: 'Voer een e-mail adres in alstublieft',
        registered: 'U heeft zich aangemeld voor de nieuwsbrief.',
        notRegistered: 'U bent niet geabonneerd op de nieuwsbrief.',
        subscribe: 'Inschrijven',
        unsubscribe: 'Afmelden',
        alreadySubscribed: 'E-mail adres is reeds geregistreerd voor de nieuwsbrief',
        successAccount: 'U heeft zich succesvol afgemeld voor de nieuwsbrief.',
        alreadyRegisteredQuestion: 'Ben je al ingeschreven?',
        unsubscribeHere: ' U kunt zich hier afmelden',
        successUnsubscribe: 'U bent succesvol afgemeld',
        unsubscribeError: 'Er is een fout opgetreden. Probeer het later nog eens.',
    },
    cartChangeModal: {
        headline: 'Attentie',
        description: 'De winkelwagen is bijgewerkt, kies de betaalmethode opnieuw.',
        submitText: 'OK',
    },
    infoBanner: {
        closeButton: 'Sluiten',
    },
    slider: {
        prevButton: 'Opbrengst',
        nextButton: 'Volgende',
        activePagination: 'actieve dia',
        inactivePagination: 'inactieve dia',
    },
    specialDeal: {
        days: '{count, plural, one {Dag} other {Dagen}}',
        hours: 'Uren',
        minutes: 'Min.',
        seconds: 'Sec.',
        specialDealOver: 'Deze actie is helaas beëindigd.',
    },
    userManuals: {
        headline: 'Handleidingen',
        search: 'Zoek op product, artikelnummer of code',
        resetSearch: 'Opnieuw zoeken',
        table: {
            sku: 'Artikel nummer',
            code: 'Code',
            productTitle: 'Product',
            type: 'Type',
            types: {
                /* eslint-disable camelcase */
                instruction_manual: 'Gebruiksaanwijzing',
                recipe_booklet: 'Receptenboekje',
                training_plan: 'Opleidingsplan',
                nutrition_plan: 'Voedingsplan',
                /* eslint-enable camelcase */
            },
        },
        noResults: 'Geen handleidingen gevonden.',
    },
    price: {
        from: 'vanaf\u00a0{price}',
        reference: '{price}\u00a0/\u00a0{baseUnitMultiplier}\u00a0{referenceUnit}',
        statt: 'van\u00a0{price}',
        uvp: 'adviesprijs\u00a0{price}',
    },
    emarsysFieldWriter: {
        success: 'De wijziging is succesvol doorgevoerd.',
        error: 'Er is een fout opgetreden. Probeer het later nog eens.',
    },
    subscription: {
        amount: 'Hoeveelheid:',
        deliveryInterval: 'Leveringsinterval:',
        nextDelivery: 'Volgende levering:',
        ordered: 'Besteld:',
        status: 'Toestand:',
        cancelSubscription: 'Annuleer abonnement',
        state: {
            active: 'Actief',
            paused: 'Gepauzeerd',
            cancelled: 'Ontslag nemen',
            problems: 'Problemen',
        },
        intervalUnit: {
            day: '{amount} {amount, plural, one {Dag} other {Dagen}}',
            month: '{amount} {amount, plural, one {Maand} other {Maanden}}',
            year: '{amount} {amount, plural, one {Jaar} other {Jaren}}',
        },
    },
};
