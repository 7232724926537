import { Price } from '@mediashop/base/pattern/molecule/Price';
import classNames from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useFreeShippingInfo } from '@mediashop/app/hooks/useFreeShippingInfo';
import calculateSubtotal from '@mediashop/app/helper/calculateSubtotal';
import { Cart } from '@mediashop/app/api/types/ClientCart';
import { useResizeObserver } from '@mediashop/app/hooks/useResizeObserver';
import { useNavigate } from '@mediashop/app/hooks/useNavigate';

const componentName = 'splp-cart';

type ShippingCostBarProps = {
    cart: Cart;
    deviceType: string;
};

const ShippingCostBar = ({ cart, deviceType }: ShippingCostBarProps): JSX.Element => {
    const { isFreeShippingAvailable, isTotalPriceGreaterThanFreeShippingLimit, isShippingPriceZero } =
        useFreeShippingInfo();
    const intl = useIntl();
    const navigate = useNavigate();
    const progressBarTextRef = useRef<HTMLDivElement | null>(null);
    const progressBarRef = useRef<HTMLDivElement | null>(null);

    const subtotal = useMemo(() => calculateSubtotal(cart.productTotal, cart.customLineItems), [cart]);

    const freeShippingAmount = useMemo(
        () => cart.shippingInfo?.shippingRate?.freeAbove ?? { centAmount: 0, fractionDigits: 2 },
        [cart]
    );

    const getOnlyLeftToFreeShippingPrice = () => {
        const diff = freeShippingAmount.centAmount - subtotal.centAmount;

        return {
            centAmount: diff < 0 ? 0 : diff,
            fractionDigits: 2,
            currencyCode: subtotal.currencyCode,
        };
    };

    const continueShoppingLink = () => {
        navigate('/');
    };

    const freeShippingProgress = useMemo(() => {
        const percentageDifference = (subtotal.centAmount / Math.abs(freeShippingAmount.centAmount)) * 100;
        return Math.min(percentageDifference, 100);
    }, [subtotal, freeShippingAmount]);

    const [progressMinWidth, setProgressMinWidth] = useState(0);
    const calcProgressMinWidth = () => {
        const width = progressBarTextRef.current?.offsetWidth ?? 0;
        setProgressMinWidth(width);
    };
    useResizeObserver(progressBarTextRef, calcProgressMinWidth);

    return (
        <>
            {isFreeShippingAvailable && freeShippingAmount ? (
                <>
                    {isTotalPriceGreaterThanFreeShippingLimit || isShippingPriceZero ? (
                        <div
                            className={`${componentName}__progress-bar-container ${componentName}__progress-bar-container-margin`}
                        >
                            <FormattedMessage id="cart.freeShippingBanner.order" />
                            <span className={`${componentName}__bold ${componentName}__text-green`}>
                                <FormattedMessage id="cart.freeShippingBanner.freeDelivery" />
                            </span>
                        </div>
                    ) : (
                        <div
                            onClick={continueShoppingLink}
                            className={classNames(`${componentName}__progress-bar-container`, {
                                [`${componentName}__progress-bar-container-margin`]:
                                    !isTotalPriceGreaterThanFreeShippingLimit,
                            })}
                        >
                            <p className={`${componentName}__progress-bar-header`}>
                                <FormattedMessage
                                    id="cart.freeShippingBanner.headline"
                                    values={{
                                        onlyLeftPrice: (
                                            <span className={`${componentName}__progress-bar-price-difference`}>
                                                <Price price={getOnlyLeftToFreeShippingPrice()} />
                                            </span>
                                        ),
                                        fromPrice: <Price price={freeShippingAmount} />,
                                        freeShipping: (
                                            <span className={`${componentName}__bold`}>
                                                {intl.formatMessage({
                                                    id: 'cart.freeShippingBanner.freeShipping',
                                                })}
                                            </span>
                                        ),
                                    }}
                                />
                            </p>

                            <div ref={progressBarRef} className={`${componentName}__progress-bar`}>
                                <div
                                    ref={progressBarTextRef}
                                    className={`${componentName}__text`}
                                    style={{
                                        width: `calc(${freeShippingProgress}% - 15px)`,
                                    }}
                                >
                                    {deviceType !== 'mobile' ? (
                                        <p>
                                            <FormattedMessage id="cart.freeShippingBanner.continue" />
                                            <span className={`${componentName}__underline`}>
                                                <FormattedMessage id="cart.freeShippingBanner.shopping" />
                                            </span>
                                        </p>
                                    ) : (
                                        SKIP_RENDER
                                    )}
                                    <span className={`${componentName}__price`}>
                                        <Price price={subtotal} />
                                    </span>
                                </div>

                                <div
                                    data-testid="progress-bar"
                                    className={`${componentName}__progress`}
                                    style={{
                                        width: `calc(${freeShippingProgress}% - 10px)`,
                                        minWidth: `${progressMinWidth + 15}px`,
                                    }}
                                >
                                    <svg
                                        onClick={continueShoppingLink}
                                        className={`${componentName}__arrow`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <polygon
                                            className={`${componentName}__polygon`}
                                            points="10,0 20,10 10,20 10,15 0,15 0,5 10,5"
                                        />
                                    </svg>
                                </div>

                                <span id="div2Ref" className={`${componentName}__progress-bar-goal`}>
                                    <Price price={freeShippingAmount} />
                                </span>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                SKIP_RENDER
            )}
        </>
    );
};

export default ShippingCostBar;
