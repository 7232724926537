import { CartLineItem } from '@mediashop/app/api/types/ClientCart';
import {
    CartDiscount,
    CartDiscountValueRelative,
    CartDiscountValueWithMoney,
    DiscountCode,
    DiscountCodeItem,
    PreparedTotalPriceDiscount,
    TotalPriceDiscount,
    VoucherRowDiscountType,
} from '@mediashop/app/api/types/Discount';
import { Money } from '@mediashop/app/api/types/Money';
import MoneyFunctions from '@mediashop/app/domain/Money';

/**
 * Check if a discount code is a relative discount code.
 * @param discountCode discount code
 * @returns true when discount code is relative, otherwise false
 */
export const isRelativeDiscount = (discountCode: DiscountCode): boolean =>
    discountCode.cartDiscounts.some((cartDiscount) => cartDiscount.value.type === 'relative') ?? false;

/**
 * Get money object of a fixed or absolute discount code
 * @param discountCode discount code
 * @param currency the shops current currency
 * @returns money object of discount code for passed currency, otherwise undefined
 */
export const getDiscountedValuePerCurrency = (discountCode: DiscountCode, currency: string): Money | undefined => {
    if (isRelativeDiscount(discountCode)) {
        return undefined;
    }

    const discountCodeMoney = discountCode.cartDiscounts[0].value as CartDiscountValueWithMoney;

    if (Array.isArray(discountCodeMoney?.money)) {
        return (discountCodeMoney?.money as Money[])?.find((money) => money.currencyCode === currency);
    }

    return discountCodeMoney.money;
};

/**
 * Get relative discount value in percent
 * @param discountCode discount code
 * @returns relative discount value in percent, or undefined
 */
export const getRelativeDiscountValue = (discountCode: DiscountCode): number | undefined => {
    if (!isRelativeDiscount(discountCode)) {
        return undefined;
    }

    const relativeDiscount = discountCode.cartDiscounts[0].value as CartDiscountValueRelative;

    return Math.round(relativeDiscount.permyriad / 100);
};

/**
 * Helper function to extract automatic discounts from all applied discounts.
 * If a discount id is not included in manualDiscounts, it searches for the line item where the automatic discount
 * is applied to. For every automatic discount found, a discount Object is created and returned.
 * @param lineItems
 * @param allAppliedDiscounts
 * @param manualDiscounts
 * @return automaticDiscounts
 */
const getAutomaticDiscountsFromLineItems = (
    lineItems: CartLineItem[],
    allAppliedDiscounts: Record<string, unknown>,
    manualDiscounts: Record<string, unknown>
): Record<string, VoucherRowDiscountType> => {
    let automaticDiscounts = {};
    Object.keys(allAppliedDiscounts).forEach((cartDiscountId) => {
        if (!Object.keys(manualDiscounts).includes(cartDiscountId)) {
            lineItems.forEach((lineItem) =>
                lineItem.discountedPricePerQuantity.forEach((discountedPrice) =>
                    discountedPrice.discountedPrice.includedDiscounts.forEach((discount) => {
                        if (discount.discount.id === cartDiscountId) {
                            automaticDiscounts = {
                                ...automaticDiscounts,
                                [discount.discount.name]: {
                                    state: 'MatchesCart',
                                    description: discount.discount.description,
                                    value: allAppliedDiscounts[cartDiscountId],
                                    code: discount.discount.name,
                                },
                            };
                        }
                    })
                )
            );
        }
    });
    return automaticDiscounts;
};

/**
 * Get all applied discount codes and their money value from line-items
 * @param lineItems the cart´s line items
 * @param discountCodes the cart´s applied discount codes
 * @param shopCurrencyCode the shops current currency
 * @returns object of discount codes with their state, description and value
 */
export const getDiscountFromLineItems = (
    lineItems: CartLineItem[],
    discountCodes: DiscountCodeItem[],
    shopCurrencyCode: string
): Record<string, VoucherRowDiscountType> => {
    // Get all cart discounts to calculate savings
    const cartDiscounts = discountCodes
        .map((discountCode) => discountCode.discountCode?.cartDiscounts)
        .flat()
        .filter((cartDiscount): cartDiscount is CartDiscount => Boolean(cartDiscount));

    // Get mapping of cart discount id <-> discount amount (from discounted price per quantity)
    const discountedPricePerCartDiscount = lineItems.reduce((allDiscounts, lineItem) => {
        lineItem.discountedPricePerQuantity.forEach(({ discountedPrice, quantity }) => {
            discountedPrice.includedDiscounts.forEach(({ discount, discountedAmount }) => {
                if (allDiscounts[discount.id] === undefined) {
                    allDiscounts[discount.id] = {
                        centAmount: 0,
                        fractionDigits: discountedAmount.fractionDigits,
                        currencyCode: discountedAmount.currencyCode,
                    };
                }
                allDiscounts[discount.id].centAmount += quantity * discountedAmount.centAmount;
            });
        });

        return allDiscounts;
    }, {});

    // Add fallback values to cart discounts
    const cartDiscountsWithFallbacks = cartDiscounts.reduce((allDiscounts, cartDiscount) => {
        const fallbackValue: Money = { centAmount: 0, fractionDigits: 2, currencyCode: shopCurrencyCode };

        return {
            ...allDiscounts,
            [cartDiscount.id]: discountedPricePerCartDiscount[cartDiscount.id] ?? fallbackValue,
        };
    }, {} as Record<string, Money>);

    const lineItemsWithoutGiftLineItem = lineItems.filter((item) => item.lineItemMode !== 'GiftLineItem');

    const automaticDiscounts = getAutomaticDiscountsFromLineItems(
        lineItemsWithoutGiftLineItem,
        discountedPricePerCartDiscount,
        cartDiscountsWithFallbacks
    );

    // Return mapped data
    const manualDiscounts = discountCodes
        .filter((code) => {
            const targetType = code.discountCode?.cartDiscounts[0]?.target;
            return targetType === 'lineItems' || code.discountCode?.cartDiscounts[0]?.value.type === 'giftLineItem';
        })
        .reduce((allDiscounts, { discountCode, state }) => {
            const targetType = discountCode!.cartDiscounts[0].target;
            return {
                ...allDiscounts,
                [discountCode!.code]: {
                    state,
                    description: discountCode!.description,
                    value: discountCode!.cartDiscounts.reduce((sum, cartDiscount) => {
                        const calculatedCartDiscount = cartDiscountsWithFallbacks[cartDiscount.id];
                        return {
                            currencyCode: calculatedCartDiscount.currencyCode,
                            fractionDigits: calculatedCartDiscount.fractionDigits,
                            centAmount: calculatedCartDiscount.centAmount + (sum.centAmount ?? 0),
                        };
                    }, {} as Money),
                    code: discountCode!.code,
                    stackingMode: discountCode!.cartDiscounts.map((cartDiscount) => cartDiscount.stackingMode)[0],
                    type: discountCode!.cartDiscounts.map((cartDiscount) => cartDiscount.value)[0],
                    legalText: discountCode!.legalText,
                    minimumOrderValue: discountCode!.minimumOrderValue,
                    targetType:
                        discountCode!.cartDiscounts[0]?.value.type === 'giftLineItem' ? 'GiftLineItem' : targetType,
                },
            };
        }, {} as Record<string, VoucherRowDiscountType>);

    return {
        ...manualDiscounts,
        ...automaticDiscounts,
    };
};

/**
 * Calculate total discount savings per line-item
 * @param lineItem line item of the order
 * @returns money object of discounted savings of line-item
 */
export const getTotalLineItemDiscount = (lineItem: CartLineItem): Money | undefined => {
    const currencyCode = lineItem.price.currencyCode;
    const emptyMoneyObject: Money = { centAmount: 0, fractionDigits: 2, currencyCode };

    return lineItem.discountedPricePerQuantity.reduce(
        (lineItemTotalDiscount, { discountedPrice: { includedDiscounts }, quantity }) =>
            MoneyFunctions.add(
                lineItemTotalDiscount,
                includedDiscounts.reduce(
                    (includedDiscountSum, includedDiscount) =>
                        MoneyFunctions.add(
                            includedDiscountSum,
                            MoneyFunctions.multiplyByScalar(includedDiscount.discountedAmount, quantity)
                        ),
                    emptyMoneyObject
                )
            ),
        emptyMoneyObject
    );
};

export const getTotalPriceDiscounts = (
    discountCodes: DiscountCodeItem[],
    discountOnTotalPrice?: TotalPriceDiscount
): PreparedTotalPriceDiscount[] =>
    discountCodes.flatMap(
        (discountCode) =>
            discountCode.discountCode?.cartDiscounts
                .filter((discount) => discount.target === 'totalPrice')
                .map((discount) => {
                    const totalPriceDiscount = discountOnTotalPrice?.includedDiscounts.find(
                        (discountOnTotalPrice) => discountOnTotalPrice.discount.id === discount.id
                    );

                    return {
                        ...discountCode.discountCode,
                        ...discount,
                        type: { ...discount.value },
                        targetType: discount.target,
                        state: discountCode.state,
                        value: {
                            ...totalPriceDiscount?.discountedAmount,
                        },
                    };
                })
                .filter(Boolean) as PreparedTotalPriceDiscount[]
    );
