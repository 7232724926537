import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { MappedLocale } from '../../../../types/country-switcher';
import SelectBox from '../../../atom/SelectBox';

type LanguageSelectProps = {
    componentName: string;
    onLanguageChanged: (country: MappedLocale) => void;
    options: MappedLocale[];
    selectedLanguage: MappedLocale;
};

export const LanguageSelect: React.FC<LanguageSelectProps> = ({
    componentName,
    onLanguageChanged,
    options,
    selectedLanguage,
}: LanguageSelectProps) => (
    <div className={`${componentName}__select-language`}>
        <span className={`${componentName}__select-label`}>
            <FormattedMessage id="countrySwitcher.language" />
        </span>
        <SelectBox defaultValue={selectedLanguage} options={options} onChange={onLanguageChanged} />
    </div>
);
