/* eslint-disable max-lines-per-function */
import BaseUrlHelper from '@mediashop/app/helper/BaseUrlHelper';
import { setCookies } from '@mediashop/app/helper/setCookies';
import { useCart } from '@mediashop/app/hooks/useCart';
import { useCategory } from '@mediashop/app/hooks/useCategory';
import { resetCustomerDataFromSession } from '@mediashop/app/hooks/useCustomerDataFromSession';
import { useAppDispatch } from '@mediashop/app/store/hooks/hooks';
import classNames from 'classnames';
import { useEffect, useRef, useState, useMemo } from 'react';
import Button from '../../atom/Button';
import { injectComponent } from '@mediashop/app/component-injector';
import Icon from '../../atom/Icon';
import { FormattedMessage } from 'react-intl';
import { CountrySwitcherProps, MappedCountry, MappedLocale } from '@mediashop/base/types/country-switcher';
import { getMappedCountry, getMappedLocale, hasCountryAndLocale } from '@mediashop/base/utils/country-switcher';
import ShopSwitchModal from '../ShopSwitchModal';
import { useLocation } from 'react-router-dom';
import CountrySwitchModal from '../CountrySwitchModal';
import { defaultCurrencies } from '@mediashop/app/constants/defaultCurrencies';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useProject } from '@mediashop/app/hooks/useProject';
import { useShopContext } from '@mediashop/app/hooks/useShopContext';
import { useCategories } from '@mediashop/app/queries/categories/useCategories';
import { LanguageSelect } from './LanguageSelect/LanguageSelect';
import { CountrySelect } from './CountrySelect/CountrySelect';
import { useGetProductBySlug } from '@mediashop/app/hooks/useGetProductBySlug';
import { getTargetCategoryPath, getTargetProductPath } from './helpers';
import { useQueryParams } from '@mediashop/app/hooks/useQueryParams';
import { countryChanged, localeChanged } from '@mediashop/app/store/reducer/context';
import { slugProductRemoved } from '@mediashop/app/store/reducer/product';
import * as icons from '@mediashop/base/icons';
import { useDeleteCart } from '@mediashop/app/queries/cart/useDeleteCart';
import { useNavigate } from '@mediashop/app/hooks/useNavigate';

const allIcons = icons.default;

const componentName = 'country-switch';

function CountrySwitch({ icon = 'ChevronDown', hasFlag = true, showTitle = true }: CountrySwitcherProps): JSX.Element {
    const dispatch = useAppDispatch();

    const { cart } = useCart();
    const isEmptyCart = !cart?.lineItems || cart?.lineItems.length === 0;
    const navigate = useNavigate();
    const location = useLocation();

    const { country: userCountry, locale: userLocale, currency: userCurrency, isLocaleFromCookie } = useShopContext();

    const {
        basePath,
        currencies: configCurrencies,
        locales: configLocales,
        shippingCountries: configShippingCountries,
        takeLanguageFromUrl,
        searchProvider,
    } = useProject();

    const reloadOnSwitch = searchProvider === 'Nosto';

    const selectedCountry = getMappedCountry(userCountry);
    const selectedLocale = getMappedLocale(userLocale, configLocales);

    const cookiePath = useMemo(() => (basePath !== '' ? basePath : '/'), [basePath]);

    const hasLanguageInUrl = useMemo(
        () => hasCountryAndLocale(configShippingCountries, configLocales, location.pathname),
        [configShippingCountries, configLocales, location]
    );

    const [newCountry, setNewCountry] = useState(selectedCountry);
    const [newLocale, setNewLocale] = useState(selectedLocale);
    const [open, setOpen] = useState(false);
    const [showCountrySwitchModal, setShowCountrySwitchModal] = useState(false);
    const refSelectContainer = useRef<HTMLDivElement>(null);
    const refDropdown = useRef<HTMLDivElement>(null);
    const queryParams = useQueryParams();

    const { data: categories = [] } = useCategories();

    const { selectedCategoryId } = useCategory();
    const { product } = useGetProductBySlug();

    const { mutate: deleteCart } = useDeleteCart();

    const handleCountryChange = (item: MappedCountry) => {
        setNewCountry(item);
    };

    const handleLocaleChange = (item: MappedLocale) => {
        if (item.originalLocale.split('-')[0] === userLocale.split('-')[0]) {
            return;
        }

        setNewLocale(item);
    };

    const getCurrencyIfValid = (currency: string) => (configCurrencies.includes(currency) ? currency : null);

    const updateUrlWithLanguage = () => {
        let pathWithLanguage = location.pathname;

        // Replace language or add it to the url
        if (hasLanguageInUrl) {
            pathWithLanguage = pathWithLanguage.replace(
                `/${selectedCountry.value.toLowerCase()}/${userLocale.split('-')[0]}`,
                `/${newCountry.value.toLowerCase()}/${newLocale.originalLocale.split('-')[0]}`
            );
        } else if (takeLanguageFromUrl) {
            pathWithLanguage = `/${newCountry.value.toLowerCase()}/${newLocale.originalLocale.split('-')[0]}${
                location.pathname
            }`;
        }

        // Handle categories
        if (location.pathname.includes('/c/')) {
            pathWithLanguage = getTargetCategoryPath({
                categories,
                currentLocale: userLocale,
                nextLocale: newLocale.originalLocale,
                pathname: pathWithLanguage,
                selectedCategoryId,
            });
        }

        // Handle PDPs
        if (location.pathname.includes('/p/')) {
            pathWithLanguage = getTargetProductPath({
                currentLocale: userLocale,
                nextLocale: newLocale.originalLocale,
                pathname: pathWithLanguage,
                product,
            });
        }

        // Handle search results
        if (location.pathname.includes('/search-results')) {
            pathWithLanguage = `${pathWithLanguage}?q=${queryParams.q}`;
        }

        if (reloadOnSwitch) {
            window.location.href = BaseUrlHelper.addBasePathToPath(pathWithLanguage, window.location.origin + basePath);
        } else {
            navigate(pathWithLanguage, { keepLanguage: true });
        }
    };

    const changeCountry = () => {
        const currencyToUse = getCurrencyIfValid(defaultCurrencies[newCountry.value]) ?? 'EUR';

        if (reloadOnSwitch) {
            setCookies(
                {
                    country: newCountry.value,
                    currency: currencyToUse,
                },
                cookiePath
            );
        } else {
            dispatch(countryChanged({ country: newCountry.value, currency: currencyToUse }));

            // Get rid of slug product in state (MS-3764)
            dispatch(slugProductRemoved());
        }

        resetCustomerDataFromSession();

        updateUrlWithLanguage();
    };

    const handleCountrySwitchModalSubmit = () => {
        setShowCountrySwitchModal(false);
        changeCountry();
    };

    const handleOnSubmit = () => {
        const hasCountryChanged = newCountry.value !== selectedCountry.value;
        const hasLanguageChanged = newLocale.value !== selectedLocale.value;

        if (hasCountryChanged) {
            if (!isEmptyCart) {
                setShowCountrySwitchModal(true);
            } else {
                deleteCart();
                changeCountry();
            }
        }

        if (hasLanguageChanged) {
            if (!reloadOnSwitch) {
                dispatch(localeChanged({ locale: newLocale.originalLocale }));
            }
            updateUrlWithLanguage();
        }

        setOpen(false);
    };

    useEffect(() => {
        setCookies(
            {
                country: userCountry,
                currency: userCurrency,
            },
            cookiePath
        );
    }, [cookiePath, userCountry, userCurrency]);

    useEffect(() => {
        /** If we have a locale set from the cookie don't do the navigator part */
        if (isLocaleFromCookie) {
            setCookies({ locale: userLocale }, cookiePath);
        } else {
            const lang = navigator.language ?? userLocale;
            handleLocaleChange(getMappedLocale(lang, configLocales));
        }
    }, [userLocale]);

    useEffect(() => {
        const checkIfClickedOutside = (event) => {
            if (event.target === refDropdown.current || refDropdown.current?.contains(event.target)) {
                return;
            }
            if (open && refSelectContainer.current && !refSelectContainer.current.contains(event.target)) {
                setNewCountry(selectedCountry);
                setNewLocale(selectedLocale);
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [open, refSelectContainer]);

    const optionsCountries = configShippingCountries
        .map(getMappedCountry)
        .filter((country) => country.value !== undefined);

    const optionsLocales = configLocales
        .map((locale) => getMappedLocale(locale, configLocales))
        .filter((item) => item.value !== undefined)
        .map((item, idx, arr) => {
            // remove the duplicate locales by value
            if (arr.findIndex((item2) => item2.value === item.value) === idx) {
                return item;
            }
            return null;
        })
        .filter((item) => item !== null) as MappedLocale[];

    const isCountrySwitchEnabled = optionsCountries.length > 1;
    const isLanguageSwitchEnabled = optionsLocales.length > 1;
    const hideCountrySwitchIcon = optionsCountries.length === 1 && optionsLocales.length === 1;

    return (
        <div className={componentName}>
            <div className="dropdown" ref={refDropdown}>
                <div
                    data-testid="country-switch"
                    className={classNames('dropdown__header', {
                        'dropdown__header--clickable': !hideCountrySwitchIcon,
                    })}
                    onClick={!hideCountrySwitchIcon ? () => setOpen(!open) : undefined}
                >
                    {showTitle ? (
                        <span className={`${componentName}__title`}>
                            <FormattedMessage id="countrySwitcher.headline" />
                        </span>
                    ) : (
                        SKIP_RENDER
                    )}
                    {hasFlag && (
                        <img
                            alt="Flag"
                            height="18"
                            width="25"
                            src={allIcons[selectedCountry.iconName]}
                            className={classNames(`dropdown__item-flag`, {
                                'dropdown__item-flag--select-disabled': hideCountrySwitchIcon,
                            })}
                        />
                    )}
                    {!hideCountrySwitchIcon ? (
                        <Icon
                            name={icon ?? 'ChevronDown'}
                            className={classNames({
                                'dropdown__country-switch-icon-filled': icon === 'ArrowDownFilled',
                            })}
                        />
                    ) : (
                        SKIP_RENDER
                    )}
                </div>
            </div>
            {open && (
                <div className={`${componentName}__select-container`} ref={refSelectContainer}>
                    {isCountrySwitchEnabled ? (
                        <CountrySelect
                            componentName={componentName}
                            onCountryChanged={handleCountryChange}
                            options={optionsCountries}
                            selectedCountry={selectedCountry}
                        />
                    ) : (
                        SKIP_RENDER
                    )}
                    {isLanguageSwitchEnabled ? (
                        <LanguageSelect
                            componentName={componentName}
                            onLanguageChanged={handleLocaleChange}
                            options={optionsLocales}
                            selectedLanguage={selectedLocale}
                        />
                    ) : (
                        SKIP_RENDER
                    )}

                    <br />

                    <div className={`${componentName}__button-container`}>
                        <Button iconRight="ChevronRight" onClick={handleOnSubmit}>
                            <FormattedMessage id="countrySwitcher.button" />
                        </Button>
                    </div>
                </div>
            )}

            <ShopSwitchModal />

            <CountrySwitchModal
                isOpen={showCountrySwitchModal}
                newCountry={newCountry.label!}
                onSubmit={handleCountrySwitchModalSubmit}
                onClose={() => setShowCountrySwitchModal(false)}
            />
        </div>
    );
}

export default injectComponent('pattern.atom.Countries', CountrySwitch);
