import LoqateClient, { LoqateItem } from '@mediashop/app/api/LoqateClient';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useOutsideClicked } from '@mediashop/app/hooks/useOutsideClicked';
import { useProject } from '@mediashop/app/hooks/useProject';
import { useShopContext } from '@mediashop/app/hooks/useShopContext';
import { useEffect, useMemo, useRef, useState, type ChangeEvent } from 'react';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { FormikProps } from 'formik';
import { useDebounceEffect } from '@mediashop/app/hooks/useDebounce';
import Icon from '../Icon';
import { useOutsideFocused } from '@mediashop/app/hooks/useOutsideFocused';

export type InputProps = BaseProps & {
    error?: string;
    formik: FormikProps<Record<string, string>>;
    label?: string;
    name: string;
    placeholder?: string;
    required?: boolean;
    setFieldValueWithTimeout?: (name: string, value: string, timeout: number) => void;
};

const componentName = 'loqate-input';

function LoqateInput({
    className,
    error,
    formik,
    label,
    name,
    placeholder,
    required,
    setFieldValueWithTimeout,
}: InputProps): JSX.Element {
    const project = useProject();
    const context = useShopContext();

    const Loqate = useMemo(
        () => new LoqateClient(project.shippingCountries, context),
        [project.shippingCountries, context]
    );

    const [value, setValue] = useState<string>('');
    const [suggestion, setSuggestion] = useState<LoqateItem[]>([]);
    const [container, setContainer] = useState<string | undefined>(undefined);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const wrapperRef = useRef(null);
    useOutsideClicked(wrapperRef, () => setIsFocused(false));
    useOutsideFocused(wrapperRef, () => setIsFocused(false));

    const setAddress = async (loqateId: string) => {
        if (loqateId) {
            const items = await Loqate.retrieve(loqateId);
            const formName = name.split('_')[0];
            if (items.length) {
                const item = items[0];
                formik.setFieldValue(`${formName}_streetName`, item.Street);
                formik.setFieldValue(`${formName}_streetNumber`, item.BuildingNumber);
                formik.setFieldValue(`${formName}_postalCode`, item.PostalCode);
                formik.setFieldValue(`${formName}_city`, item.City);
                formik.setFieldValue(`${formName}_country`, item.CountryIso2);
                setFieldValueWithTimeout && setFieldValueWithTimeout(`${formName}_hasLoqate`, 'false', 100);
            }
            setIsFocused(false);
        }
    };

    useDebounceEffect(
        () => {
            if (value) {
                setContainer(undefined);
                Loqate.find(value).then((resp) => setSuggestion(resp));
            } else {
                setSuggestion([]);
            }
        },
        200,
        [value]
    );

    useEffect(() => {
        if (container) {
            Loqate.find(value, container).then((resp) => setSuggestion(resp));
        }
    }, [Loqate, container]);

    const onItemClick = async (item: LoqateItem) => {
        if (item.Type === 'Address') {
            await setAddress(item.Id);
        } else {
            setContainer(item.Id);
        }
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value);

    return (
        <div
            ref={wrapperRef}
            className={classNames(componentName, className, {
                [`${componentName}--with-values`]: isFocused && suggestion.length,
            })}
            onFocus={() => setIsFocused(true)}
        >
            <label className={`${componentName}__label`}>
                <input
                    name={name}
                    className={classNames(`${componentName}__input`, { [`${componentName}--error`]: Boolean(error) })}
                    type="text"
                    placeholder={placeholder ?? label}
                    onChange={onChange}
                    value={value}
                    autoComplete="__away"
                />

                <span className={`${componentName}__title`}>
                    <Icon name="Search" className={`${componentName}__search-icon`} />
                    <span className={`${componentName}__title-text`}>{required ? `${label}*` : label}</span>
                </span>

                {error ? <div className={`${componentName}__error-message`}>{error}</div> : SKIP_RENDER}
            </label>

            {isFocused && suggestion.length ? (
                <div className={`${componentName}__suggestion-list`} data-testid="suggestion-list">
                    {suggestion.map((item) => (
                        <div
                            key={item.Id}
                            className={`${componentName}__suggestion-list-item`}
                            onClick={() => onItemClick(item)}
                            data-testid={`${item.Text}, ${item.Description}`}
                        >
                            <span className={`${componentName}__highlight`}>{item.Text}</span>, {item.Description}
                        </div>
                    ))}
                </div>
            ) : (
                SKIP_RENDER
            )}
        </div>
    );
}

export default injectComponent('pattern.molecule.DynamicAddressForm.LoqateInput', LoqateInput);
