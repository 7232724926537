/* eslint-disable id-length */
import { useEffect, useState, type ReactNode } from 'react';

import { injectComponent } from '@mediashop/app/component-injector';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import useRoute from '@mediashop/app/hooks/useRoute';
import LoadingIndicator from '../LoadingIndicator';
import Icon from '../Icon';
import { ButtonProps } from './types';
import { EMPTY_STRING, SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';

type ElementProps = {
    type?: string;
    target?: string;
    rel?: string;
    href?: string;
    to?: string;
};

/**
 * Determine whether we should render a button, an a-element or a Link
 * depending on the href- & route-props
 */
const getElementType = ({ type, href, to }: Partial<ElementProps>) => {
    let ElementType: string | any = 'button';

    if (type === 'submit') {
        return 'button';
    }

    if (href) {
        ElementType = 'a';
    }

    if (to) {
        ElementType = Link;
    }

    return ElementType;
};

/**
 * Determine props for a-element or link-element.
 * Also takes care of secure target=_blank.
 */
const useGetElementProps = ({ type, href, to, target }: ElementProps) => {
    const props = {} as ElementProps;

    const resolvedRoute = useRoute(to ?? EMPTY_STRING);

    // Submit
    if (type === 'submit') {
        props.type = 'submit';
        return props;
    }

    /*
     * Securely open links in new tab
     * https://mathiasbynens.github.io/rel-noopener/
     */
    if (target === '_blank') {
        props.target = '_blank';
        props.rel = 'noopener';
    }

    // Simple link
    if (href) {
        props.href = href;
    }

    // Forward route to Link.
    if (typeof to !== 'undefined' && !props.to) {
        props.to = to;
    }

    // Resolve route for Links.
    if (resolvedRoute) {
        props.to = resolvedRoute;
    }

    return props;
};

const componentName = 'button';

/**
 * Render a button component.
 *
 * If provided with an href, it will render a <a>.
 * Provided a to-prop, it will render a <Link>.
 * Provided with neither of these, it will render a <button>.
 */
function Button({
    style = 'primary',
    size = 'medium',
    fullWidth,
    iconLeft,
    iconRight,
    iconSize = 'medium',
    to = '',
    href,
    type = 'button',
    target,
    disabled,
    loading,
    onClick,
    children,
    className = '',
    customStyles,
    animation = false,
    ...props
}: ButtonProps): JSX.Element {
    const disabledOrLoading = disabled || loading;
    const ElementType = getElementType({ type, href, to });
    const targetProps = useGetElementProps({ type, href, to, target });

    const [isButtonAnimated, setButtonAnimated] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setButtonAnimated(false);
        }, 7000);
    }, []);

    const targetClassName = classNames(
        className,
        componentName,
        `${componentName}--${style}`,
        `${componentName}--${size}`,
        {
            [`${componentName}--full-width`]: fullWidth,
            [`${componentName}--disabled`]: disabled,
            [`${componentName}--loading`]: loading,
            [`${componentName}--with-icon-left`]: iconLeft && !loading,
            [`${componentName}--with-icon-right`]: iconRight && !loading,
            [`${componentName}--animate`]: animation && isButtonAnimated,
        }
    );

    const RootElement = ({ children: rootChildren }: { children: ReactNode }) => (
        <ElementType
            className={targetClassName}
            onClick={disabledOrLoading ? null : onClick}
            style={customStyles}
            disabled={disabled}
            {...props}
            {...targetProps}
        >
            {rootChildren}
        </ElementType>
    );

    return loading ? (
        <RootElement>
            <LoadingIndicator />
        </RootElement>
    ) : (
        <RootElement>
            {children}

            {iconLeft ? (
                <div
                    className={classNames(
                        `${componentName}__icon-left`,
                        `${componentName}__icon`,
                        `${componentName}__icon--${iconSize}`
                    )}
                >
                    <Icon name={iconLeft} />
                </div>
            ) : (
                SKIP_RENDER
            )}

            {iconRight ? (
                <div
                    className={classNames(
                        `${componentName}__icon-right`,
                        `${componentName}__icon`,
                        `${componentName}__icon--${iconSize}`
                    )}
                >
                    <Icon name={iconRight} />
                </div>
            ) : (
                SKIP_RENDER
            )}
        </RootElement>
    );
}

export default injectComponent('pattern.atom.Button', Button);
