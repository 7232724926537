import { calculateShipping } from '../helper/calculateShipping';
import { useCart } from './useCart';

type FreeShippingInfoReturnProps = {
    isFreeShippingAvailable: boolean;
    isShippingPriceZero: boolean;
    isTotalPriceGreaterThanFreeShippingLimit: boolean;
};

/**
 * Hook that returns infos about free shipping.
 * @returns FreeShippingInfoReturnProps
 */
export const useFreeShippingInfo = (hasShippingDiscounts?: boolean): FreeShippingInfoReturnProps => {
    const { cart } = useCart();

    if (!cart?.shippingInfo) {
        return {
            isFreeShippingAvailable: false,
            isShippingPriceZero: false,
            isTotalPriceGreaterThanFreeShippingLimit: false,
        };
    }

    const { shippingCost, shippingPrice } = calculateShipping(
        cart.shippingInfo,
        cart.customFields,
        hasShippingDiscounts
    );

    const totalLineItemPrice = cart.lineItems.reduce((totalPrice, lineItem) => {
        const lineItemPrice =
            lineItem.discountedPricePerQuantity[0]?.discountedPrice?.value.centAmount ?? lineItem.price.centAmount;
        return totalPrice + lineItemPrice * lineItem.quantity;
    }, 0);

    const isFreeShippingAvailable = Boolean(cart.shippingInfo.shippingRate.freeAbove);
    const isTotalPriceGreaterThanFreeShippingLimit =
        isFreeShippingAvailable &&
        totalLineItemPrice - shippingCost.centAmount >= cart.shippingInfo.shippingRate.freeAbove!.centAmount;
    const isShippingPriceZero = shippingPrice.centAmount === 0;

    return {
        isFreeShippingAvailable,
        isShippingPriceZero,
        isTotalPriceGreaterThanFreeShippingLimit,
    };
};
