/* eslint-disable id-length */
export default {
    test: 'Hallo Test auf Deutsch',
    account: {
        test: 'Vitajte, {name}',
        subscribeButton: 'prihlásiť sa na odber noviniek',
        unSubscribeButton: 'Zrušiť odber noviniek',
        unsubscribeSuccess: 'Úspešne ste sa odhlásili z odberu noviniek.',
    },
    deal: {
        days: 'D',
        hours: 'H',
        minutes: 'M',
        seconds: 's',
        dealOver: 'Platnosť ponuky vypršala.',
        countdownEnd: 'Táto ponuka končí',
    },
    mainNavigation: {
        greeting: 'Vitajte, {name}',
        account: 'Môj účet',
        logout: 'Odhlásenie',
        search: 'Vyhľadať produkt',
        loggedout: 'Úspešne ste sa odhlásili!',
        searchSuggestion: 'Návrhy na vyhľadávanie',
        productSuggestion: 'Návrhy produktov',
        showResults: '{count, plural, =1 {{text} Zobraziť výsledok} other {Zobraziť všetky {text} výsledkov}}',
        resultSearch: '{count} {count, plural, one {Výsledok} few {Výsledky} other {Výsledkov}} pre {query}',
        logoLinkLabel: 'Úvodná stránka',
        accountLinkLabel: 'Zákaznícky účet',
        content: 'Obsah',
    },
    product: {
        cheaperSet: '2. kus len ',
        setTypeXplusY: '2. kus len ',
        quantityAdvantage: 'Množstevná výhoda: ',
        quantityAdvantageActive: 'Množstevná zľava!',
        ratingCount: '{count} {count, plural, one {Recenzie} other {Recenzii}}',
        unitPriceSuffix: '\u202f/\u202f{baseUnitMultiplier}\u00a0{referenceUnit}',
        priceHint: 'Cena vrátane DPH',
        deliveryHint: 'Dodacie a platobné podmienky',
        savedMoneySuffix: 'Úspora',
        savedMoneyBadge: 'úspora',
        buyNow: 'Objednať teraz',
        addToCart: 'Pridať do košíka',
        goToPdp: 'Na stránku s podrobnosťami o produkte',
        showDetails: 'Zobraziť podrobnosti',
        descriptionShowMore: 'Zobraziť viac...',
        descriptionShowLess: 'Zobraziť menej...',
        detailsShowMore: 'Viac informácií',
        detailsShowLess: 'Menej informácií',
        variantSelectorTitle: 'Prosím vyberte si:',
        emptyVariant: 'Prosím vyberte si',
        chooseVariant: ' vyberte',
        emptyVariantError: 'Prosím vyberte možnosť',
        suggestedPriceShort: 'UVP',
        uvp: 'UVP',
        statt: 'namiesto',
        variantSize: 'Veľkosť',
        variantColor: 'Farba',
        badges: {
            installments: '{count}x {count, plural, one {Splátka} other {Splátky\npo}} {price}',
            installmentsVariants: 'od {count}x {count, plural, one {Splátka\n} other {Splátky\npo}} {price}',
            installmentsVariantsAsAlternative:
                'alebo od {count}x {count, plural, one {Splátka} other {Splátky po}} {price}',
            installmentsAsAlternative: 'alebo {count}x {count, plural, one {Splátka} other {Splátky po}} {price}',
            installmentsInFinalSummary: '{count}x {count, plural, one {Splátka} other {Splátky po}}',
            installmentsInCart: '{count}x {count, plural, one {Splátka} other {Splátky po}} {price}',
            xplusygratis: '{text} GRÁTIS',
            savings: '{count}% úspora',
            savingsPDP: '-{count}%',
            deal: 'AKCIA -{count}%',
        },
        socialMediaShareHeadline: '{shopName}',
        socialMediaShareText: 'Pozri, čo som našiel:',
        attributes: {
            headline: 'Informácie o produkte',
            subheadline: 'Technické údaje',
            true: 'Áno',
            false: 'Nie',
            maxOrderQuantity: 'Max. objednané množstvo',
            deliverables: 'Služby',
            name: 'Meno',
            description: 'Popis',
            usp: 'USP',
            productDetails: 'Detaily produktu',
            claim: 'Nárok',
            weightGross: 'Hmotnosť',
            articleType: 'Typ produktu',
            articleIsBuyable: 'Kúpiteľné',
            erpCategory1: 'Kategoria',
            erpCategory2: 'Podkategória',
            crmArticleBundleNo: 'Artikelbündel Nr.',
            webSubtitle: 'Web podnadpis',
            countryLicensesAvailable: 'Dostupnosť v krajine',
            articleDimensions: 'Dimenzie',
            textilMaterialComposition: 'Materiál',
            relatedUpsell: 'Navýšenie predaja',
            relatedUpgrade: 'Príslušenstvo',
            subscriptionAvailable: 'Predplatné',
            subscriptionSelection: {
                day: '<b>Doba doručenia</b>: {amount} {amount, plural, one {Deň} other {Dní}}',
                month: '<b>Doba doručenia</b>: {amount} {amount, plural, one {Mesiac} other {Mesiace}}',
                week: '<b>Doba doručenia</b>: {amount} {amount, plural, one {Týždeň} other {Týždne}}',
            },
            deliveryTimeFrom: 'Min. dodacia lehota',
            deliveryTimeUntil: 'Min. dodacia lehota',
            medicalProduct: 'Zdravotnícka pomôcka',
            productChemicalIngredients: 'Zloženie',
            signalWord: 'Signalwort',
            ean: 'EAN',
            eanNl: 'EAN NL',
            prices: 'Ceny',
            biocidalProduct: 'Biocídne výrobky',
            shippingTimeCode: 'Kód času doručenia',
            expiryDate: 'Dátum platnosti',
            navBulkyItem: 'Nav Bulky Item',
            productSafety: 'Bezpečnosť produktu',
            perDelivery: 'za dodávku',
        },
        addedToCart: 'Dobrá voľba! Produkt bol pridaný.',
        cantAddToCart: 'Produkt nebolo možné pridať.',
        productNumber: 'Číslo produktu',
        productRelation: 'Súvisiace produkty',
        payOnce: 'alebo jednorazovo',
        payOnceSum: 'Celková cena',
        payOnceSumMobile: 'Spolu',
        next: 'ďalší',
        previous: 'predchádzajúci',
        deliveryProductCard: 'Dodacia lehota: {from}-{until} pracovných dní',
        downloadFor: 'Stiahnuť manuál pre',
        topReview: 'Top rezenzia',
        seeMore: 'Zobraziť viac',
        seeLess: 'Zobraziť menej',
        totalReviews: '{formattedCount} {count, plural, one {Recenzie} other {Recenzii}}',
        noReviews: 'Nie sú k dispozícii žiadne údaje.',
        videoThumbnailText: 'Video',
        subscription: {
            oneTimeDelivery: 'Jednorazový nákup',
            abo: 'Predplatné',
            emptyOption: 'Interval doručenia:',
            intervalUnit: {
                day: '{amount} {amount, plural, one {Deň} other {Dní}}',
                month: '{amount} {amount, plural, one {Mesiac} other {Mesiace}}',
                year: '{amount} {amount, plural, one {Rok} other {Rokov}}',
            },
            delivery: 'doručenie',
            recommendedInterval: 'odporúčané',
        },
        exceededMaxOrderQuantity: 'Prekročené maximálne množstvo objednávky ({amount}) pre túto položku.',
        pageTitle: '{title}{store, select, EMPTY {} other { | {store}}}',
        productUnavailable: {
            headline: 'Produkt nie je momentálne k dispozícii!',
            text: 'Vyberte si namiesto toho alternatívu z našej ponuky!',
        },
        bundleTitle: 'Dostupné aj ako sada:',
    },
    preCheckout: {
        successMessage: 'Dobrá voľba! Produkt bol pridaný.',
        ctaMessage: 'pridať do košíka a',
        ctaMessageBold: 'Objednávka s dopravou zadarmo!',
        accessories: 'Vhodné príslušenstvo',
        goBackBtn: 'Pokračovať v nákupe',
        goToCartBtn: 'Zobraziť košík',
        addToCartSingle: 'Detaily',
        addToCart: 'Objednať spolu',
        addToCartUpgrade: 'Aktualizácia',
        relations: 'Objednať tiež',
        toProduct: 'k produktu',
        deliverables: 'Rozsah doručenia: ',
        clubSuggestion: 'Ušetrite navyše?',
        clubCardAlreadyInCart: 'V nákupnom košíku už máte zákaznícku kartu.',
    },
    orderSuccessPage: {
        orderSuccess: 'Ďakujeme za vašu objednávku!',
        goToShop: 'Späť do obchodu',
        accountQuestion: 'Chcete nastaviť heslo a využívať výhody zákazníckeho účtu?',
        accountAdvantagesHeader: 'Vaše výhody so zákazníckym účtom:',
        accountAdvantageOne: 'Prehľad všetkých vašich objednávok',
        accountAdvantageTwo: 'Rýchlejšie dokončenie objednávky',
        accountAdvantageThree: 'Správa údajov o zákazníkoch',
        password: 'Heslo',
        passwordReq: 'min. 8 znakov',
        passwordReqDescription: 'Zadajte bezpečnostné heslo.',
        passwordReqDescTitle: 'Vaše heslo musí obsahovať:',
        passwordReqDescOne: 'min. 8 znakov',
        passwordReqDescTwo: 'min. 1 číslo',
        passwordReqDescThree: 'min. 1 veľké písmeno',
        passwordReqDescFour: 'min. 1 malé písmeno',
        checkboxText: 'Chcem byť informovaný o aktuálnych ponukách a zľavách.',
        checkboxInfo: 'Z odberu sa môžete kedykoľvek bezplatne odhlásiť.',
        passwordSaveBtn: 'Uložiť',
    },
    cart: {
        noReservation: 'Položky v nákupnom košíku nebudú rezervované',
        headline: 'Nákupný košík',
        articlesWithCount: '{count} {count, plural, one {produkt} few {produkty} other {produktov}}',
        addons: {
            headline: 'Objednať spolu',
            add: 'Objednať tiež',
        },
        subtotal: 'Medzisúčet',
        deliveryCost: 'Doprava a doručenie',
        total: 'Spolu',
        allTotal: 'Spolu',
        shippedFreeOne: 'Pri tejto objednávke máte dopravu ',
        shippedFreeTwo: 'ZADARMO',
        shippedFreeThree: '!',
        vatIncluded: 'vrátane DPH',
        free: 'Zadarmo',
        savings: 'Celkovo ušetríte',
        savingsText: 'Celkovo ste ušetrili',
        toCheckout: 'K pokladni',
        goSafeToCheckout: 'DO POKLADNE',
        xArticle: ' ',
        voucher: 'Kupón',
        redeemVoucher: 'Zadajte kód kupónu',
        codePlaceholder: 'Kód kupónu (voliteľné)',
        redeemCTA: 'Použiť kupón',
        voucherCodeSuccess: 'Kód kupónu "{code}" bol uplatnený',
        voucherCodeErrorCodes: {
            alreadyPresent: 'Kód kupónu "{code}" už bol použitý',
            nonApplicable:
                'Tento kupón (už) nie je aktívny alebo ho nemožno použiť vo vašom aktuálnom nákupnom košíku!',
        },
        emptyCart: 'Váš nákupný košík je prázdny',
        emptyCartMessage: 'Vyberte si vaše obľúbené produkty a pridajte ich do nákupného košíka.',
        emptyCartButton: 'Späť do obchodu',
        emptyCartButtonMessage: 'Pokračovať v nákupe',
        voucherModal: {
            headline: 'Váš kupón bol úspešne aktivovaný!',
            headlineGiftLineItem: 'Váš darček zadarmo bol aktivovaný!',
            text: '{totalSavings} zľava pri minimálnej hodnote objednávky {minValue}',
            continue: 'NAKUPOVAŤ',
        },
        wkoProductTitle: 'Váš darček',
        wkoModalHeadline: 'Objednajte si zároveň vhodné príslušenstvo a zabezpečte si darček',
        wkoDeclineOffer: 'Nie, ďakujem',
        wkoAddToCartSuccess: 'Dobrá voľba!',
        restoreCartError: 'Váš nákupný košík sa nepodarilo obnoviť.',
        clubSavings: {
            clubSavingsText: 'Ušetrite ďalších {savings} so zákazníckym klubom! ',
            clubSavingsLink: 'Kliknite {clubSavingsUnderline} pre podrobnosti!',
            clubSavingsUnderline: 'sem',
        },
        clubExtensionCta: 'Predĺženie',
        freeShippingBanner: {
            freeShipping: 'bezplatného doručenia',
            headline: 'Už len {onlyLeftPrice} zostáva do {freeShipping} pri objednávke nad {fromPrice}',
            continue: 'Pokračovať v ',
            shopping: 'nákupe',
            order: 'Táto objednávka bude ',
            freeDelivery: 'doručená zadarmo!',
        },
    },
    category: {
        showMore: 'Zobraziť viac',
        breadcrumbHomeAriaLabel: 'Úvodná stránka',
    },
    voucher: {
        codes: {
            notActive: 'Kupón nie je aktívny.',
            notValid: 'Kupón už nie je platný.',
            doesNotMatchCart: 'Kupón nespĺňa požiadavky.',
            maxApplicationReached: 'Kupón už nie je možné uplatniť.',
            applicationStoppedByPreviousDiscount: 'Kupón nie je možné uplatniť z dôvodu iného kupónu.',
            matchesCart: 'Voucher sú peniaze',
        },
        modal: {
            dontShowLegalText: 'Skryť podmienky poukazu',
            displayLegalText: 'Ukážte podmienky kupónu',
            savings: 'Zľava {amount}',
            headline: 'Ktorý kupón si chcete ponechať?',
            minimalOrderAmount: ' pri objednávke v hodnote od {amount}',
        },
        priceGiftLineItem: 'Darček zadarmo: -{price}',
    },
    checkout: {
        minicartEdit: 'upraviť',
        dividerText: 'alebo',
        allowRecurrence: {
            checkbox: 'Povoliť opakované platby',
            error: 'Musíte súhlasiť s opakovanými platbami',
        },
        catalogBillingAddressHeadline: 'Fakturačná adresa',
        editBillingAddress: 'Upraviť fakturačnú adresu',
        billingAddressHeadline: '1. Fakturačná adresa',
        guestInfo:
            'Vaše údaje budú zhromaždené iba pre túto objednávku ' +
            'a musia byť poskytnuté znova pre ďalšie objednávky. ' +
            'Aby ste mohli využívať výhody zákazníckeho účtu, ' +
            'máte možnosť si po dokončení objednávky vytvoriť ' +
            'bezplatný zákaznícky účet.',
        paymentHeadline: '2. Spôsob platby',
        summaryHeadline: '3. Zhrnutie',
        nextStep: 'Ďalej',
        checkOrder: 'Skontrolovať objednávku',
        nextStepPayment: 'Pokračujte k platobným metódam',
        lastStep: 'Krok späť',
        buyNow: 'Objednať s povinnosťou platby',
        cart: 'Nákupný košík',
        lineItem: {
            quantity: 'Množstvo',
        },
        freeDelivery: 'Pri tejto objednávke máte poštovné ZADARMO!',
        enterAddress:
            'Najprv prosím zadajte svoju adresu.' +
            'Potom kliknite na "Pokračovať k platbe". ' +
            '\n\nV ďalšom kroku sa zobrazia dostupné spôsoby platby.',
        addresses: {
            headline: 'Údaje',
            customerLogin: 'Prihlásenie k zákazníckemu účtu',
            guestOrder: 'objednať ako hosť',
            register: 'Vytvorenie zákazníckeho účtu',
        },
        payment: {
            paymentFee: 'Poplatky za transakciu: {price}',
            noPaymentFee: 'Bez poplatkov za bezhotovostnú platbu',
            noPaymentFeeForNachnahme: 'Cena dobierky: 0 EUR',
            networkError: 'Bohužiaľ, momentálne nemôžeme ponúkať nákup na účet. Vyberte iný spôsob platby.',
            tryOtherNetworkError: 'Bohužiaľ, momentálne nemôžeme ponúkať nákup na účet. Vyberte iný spôsob platby.',
            installmentNetworkError:
                'Žiaľ, pre túto objednávku nemôžeme ponúknuť platby na splátky. ' +
                'Na dokončenie objednávky vyberte alternatívnu možnosť platby.',
            invoiceNetworkError:
                'Pri tejto objednávke žiaľ nemôžeme ponúknuť nákup na účet. ' +
                'Na dokončenie objednávky vyberte inú možnosť platby.',
            invoiceCheckDateOfBirth:
                'Údaje, ktoré ste odoslali, nebolo možné úspešne overiť. ' +
                'Skontrolujte prosím zadané údaje k dátumu narodenia a uistite sa, ' +
                'že sú správne a úplné.',
            invoiceAddressError:
                'Upozorňujeme, že pri nákupe na účet sa musí zhodovať dodacia a fakturačná adresa na ' +
                'účely identifikácie. Vaše aktuálne údaje o adrese nespĺňajú túto požiadavku, a preto nie je ' +
                'možné vykonať nákup na účet. Na dokončenie objednávky vyberte alternatívnu možnosť platby.',
            genericError:
                'Vaša platba sa nepodarila. Pred ďalším pokusom si skontrolujte fakturačné a platobné údaje alebo ' +
                'použite iný spôsob platby.',
            unavailable: 'Spôsob platby dočasne nedostupný',
            invoice: 'Kauf auf Rechnung',
            invoiceoffline: 'Kauf auf Rechnung',
            mastercard: 'Mastercard',
            paypal: 'PayPal',
            paypalexpress: 'PayPal Express',
            postfinancecard: 'PostFinance',
            visa: 'VISA',
            sofortueberweisung: 'Okamžitý prevod',
            nachnahme: 'Dobierka',
            installment: 'Platba na splátky',
            installmentActivateCTA: 'Skontrolovať teraz',
            installmentCheckbox:
                'Sie stimmen der Ratenzahlungsvereinbarung zu und können den ' +
                'Ratenkredit-Vertragsentwurf {linkStart}hier{linkEnd} herunterladen.',
            afterpayinvoice: 'AfterPay',
            idealr: 'iDEAL',
            mistercashR: 'Bancontact',
            unavailableShippingCountryTitle: 'Poznámka',
            unavailableShippingCountry: 'Objednávku nie je možné odoslať na miesto doručenia, ktoré ste vybrali.',
            descriptions: {
                visa: 'Bezpečná platba cez VISA Secure.',
                mastercard: 'Bezpečná platba cez Mastercard Identity Check.',
                payPal: 'Jednoduchá platba cez Váš PayPal účet. Na konci procesu objednávky budete presmerovaný priamo na PayPal. Vaša objednávka bude dokončená až po spracovaní platby.',
            },
        },
        shippingAddressHeadline: 'Dodacia adresa',
        summary: {
            headline: 'Zhrnutie',
            usps: {
                moneyBack: 'Záruka vrátenia peňazí',
                freeDelivery: 'bezplatné doručenie',
                refund: '14 dní právo na vrátenie tovaru',
                freeReturn: 'bezplatné vrátenie tovaru',
                securePayment: 'Zabezpečená platba',
            },
            subtotal: 'Medzisúčet',
            deliveryCost: 'Poštovné',
            bulkyDeliveryCost: 'Preprava objemného tovaru',
            transactionFee: 'Poplatky za transakciu',
            total: 'Spolu',
            taxIncluded: 'vrátane DPH',
            totalSavings: 'Celkovo ušetríte',
            privacy: 'Vaše údaje používame iba v súlade s našimi {termsDetails}.',
            legal: 'Kliknutím na „Objednať s povinnosťou platby“ súhlasíte so {termsDetails} spoločnosti {shopName}.',
            legalDetails: {
                privacyText: 'Zásadami ochrany osobných údajov',
                privacyLink: '/ochrana-osobnych-udajov',
                termsText: 'Všeobecnými obchodnými podmienkami',
                termsLink: '/vop',
            },
            permissionEmailPhonePost:
                'Súhlasím so spracovaním svojich údajov - ako je uvedené v ' +
                '<link>zásadách ochrany osobných údajov</link> - na účely informovania o ' +
                'nových produktoch a službách e-mailom, telefonicky alebo poštou. ' +
                'Tento súhlas môžem kedykoľvek odvolať.',
            permissionEmailPhonePostDetails: {
                link: '/ochrana-osobnych-udajov',
            },
            buyNow: 'Kúpiť teraz',
            deliveryTime: 'Možnosť dodania do {time} pracovných dní',
            newsletterSubscription:
                'Chcem bezplatne dostávať e-mailom {shopName} ' +
                'newsletter s ponukami, výhodami a kupónmi. Z odberu noviniek ' +
                'sa môžete kedykoľvek odhlásiť prostredníctvom odkazu v e-mailoch ' +
                'newslettera.',
            comment: 'Správu doručovateľovi',
        },
        orderNotSavedYet: 'Vaša objednávka ešte nebola uložená. Chcete stránku naozaj opustiť?',
        resetPassword: {
            title: 'Poznámka',
            textBold: 'Už ste našim zákazníkom a mali ste už prístup do nášho starého internetového obchodu?',
            textNormal: 'Teraz naďalej používajte {link} svoj existujúci zákaznícky účet.',
            textCta: 'resetovať predchádzajúce heslo',
            resetButton: 'Resetovať heslo',
            closeButton: 'Zatvoriť',
        },
        multiPage: {
            goToCartModal: {
                headline: 'Pozor',
                description: 'Naozaj sa chcete vrátiť do košíka?',
                dismiss: 'Zostaňte v procese platby',
                submit: 'Upraviť nákupný košík',
            },
            summary: {
                amount: 'Množstvo: {amount}',
                billingAddress: 'Fakturačná adresa',
                billingAndDelivery: 'Fakturácia a doručenie',
                change: 'zmeniť',
                lineItemTotalPrice: 'Celková cena: {price}',
                paymentType: 'Spôsob platby',
                shippingAddress: 'Adresa dodania',
                title: 'Zhrnutie',
                yourOrder: 'Vaša objednávka',
            },
            headlines: {
                headline: 'Objednajte bezpečne v 3 krokoch',
                address: 'Adresa',
                payment: 'Typ platby',
                summary: 'Skontrolovať a odoslať',
            },
            nextButton: 'Ďalej',
            checkDataButton: 'Kontrola údajov',
        },
        customerClubExtensionModal: {
            title: 'Attention',
            text:
                'Už ste členom klubu zákazníkov. Vernostná karta bola odstránená z vášho nákupného košíka. ' +
                'Chcete si namiesto toho kúpiť obnovenie svojho existujúceho členstva?',
            addToCart: 'Kúpiť obnovenie',
            closeButton: 'Close',
        },
        subscriptionInfo:
            'Na zakúpenie predplatného potrebujete zákaznícky účet. Buď sa prihláste pomocou svojho existujúceho ' +
            'zákazníckeho účtu alebo si jednoducho vytvorte nový zákaznícky účet.',
    },
    totalPrice: {
        taxIncluded: 'vrátane DPH',
    },
    address: {
        sectorPlaceholder: 'Sec',
        blockPlaceholder: 'Bl',
        stairwayPlaceholder: 'Sc',
        floorPlaceholder: 'Et',
        apartmentPlaceholder: 'Ap',
        billingAddress: 'Fakturačná adresa*',
        requiredField: '* označené polia sú povinné',
        emailLabel: 'E-mailová adresa',
        emailPlaceholder: 'E-mailová adresa',
        emailErrorText: 'Prosím, zadajte platnú e-mailovú adresu.',
        salutationLabel: 'Oslovenie*',
        salutationPlaceholder: 'Oslovenie',
        salutations: {
            ms: 'Pani',
            mr: 'Pán',
            none: 'Neuvedené',
        },
        customerTypeLabel: 'Typ zákazníka',
        customerType: {
            private: 'Súkromná osoba',
            corporate: 'Spoločnosť',
        },
        contactLabel: 'Kontakt*',
        contactPerson: 'Kontaktná osoba',
        contactInfo: 'Kontaktné údaje',
        firstnamePlaceholder: 'Meno',
        firstnameErrorText: 'Prosím, zadajte platné meno.',
        lastnamePlaceholder: 'Priezvisko',
        lastnameErrorText: 'Prosím, zadajte platné priezvisko.',
        nameLengthErrorText: 'Maximálne 200 povolených znakov.',
        phonePlaceholder: 'Telefónne číslo',
        phoneErrorText: 'Prosím, zadajte platné telefónne číslo.',
        company: 'Spoločnosť',
        companyPlaceholder: 'Spoločnosť',
        companyErrorText: 'Zadajte platný názov spoločnosti.',
        vatIdPlaceholder: 'Daňové číslo',
        vatIdErrorText: 'Zadajte platné daňové číslo.',
        address: 'Adresa',
        streetPlaceholder: 'Ulica',
        streetErrorText: 'Prosím, zadajte platnú ulicu.',
        streetLengthErrorText: 'Maximálne 100 povolených znakov.',
        streetLengthErrorTextCZ: 'Maximálne 40 povolených znakov.',
        streetNumberPlaceholder: 'Č. domu',
        streetNumberErrorText: 'Prosím, zadajte platné číslo domu.',
        zipCodePlaceholder: 'PSČ',
        zipCodeErrorText: 'Prosím, zadajte platné PSČ.',
        cityPlaceholder: 'Mesto/obec',
        cityErrorText: 'Prosím, zadajte platné mesto/obec.',
        country: 'Krajina',
        countryLabel: 'Krajina',
        countryPlaceholder: 'Vyberte krajinu',
        countyLabel: 'Okres',
        countyPlaceholder: 'Vyberte okres',
        packingStationPlaceholder: 'Doručenie na odberné miesto*',
        packingStation: {
            yes: 'Áno',
            no: 'Nie',
        },
        shippingAddressHeadline: 'Dodacia adresa:*',
        shippingAddressPlaceholder: 'To je aj dodacia adresa*',
        shippingAddress: {
            yes: 'Áno',
            no: 'Nie',
        },
        dayPlaceholder: 'Deň',
        monthPlaceholder: 'Mesiac',
        yearPlaceholder: 'Rok',
        birthdateTitle: 'Dátum narodenia',
        birthdatePlaceholder: '1990-01-23',
        birthdateErrorText: 'Zadajte platný dátum.',
        birthdateTooYoungErrorText: 'Musíte mať aspoň 18 rokov.',
        birthdateDayErrorText: '*Neplatné',
        birthdateMonthErrorText: '*Neplatné',
        birthdateYearErrorText: '*Neplatné',
        birthdayTitle: 'Dátum narodenia',
        birthDayError: 'Vek nesmie prekročiť 100 rokov.',
        loqatePlaceholder: 'Zadajte adresu',
        loqateManualAddress: 'Zadajte adresu manuálne',
        loqateManualAddressHint: 'alebo pohodlne hľadať podľa adresy:',
        loqateSearchAddress: 'prípadne hľadať podľa adresy?',
    },
    checkoutSuccess: {
        headline: 'Ďakujeme za Vašu objednávku',
        headlineError: 'Vyskytla sa chyba',
        textFirst: 'Prijali sme Vašu objednávku a poslali sme Vám aj potvrdenie na <link>{email}</link>.',
        textSecond: 'Len čo Vami objednaný tovar opustí náš sklad, budeme Vás opäť informovať e-mailom.',
        textSecondPending:
            'Vaša objednávka sa spracováva. O vytvorení objednávky vás budeme informovať prostredníctvom e-mailu.',
        textError: 'Kontaktujte, prosím, zákaznícky servis ohľadne stavu Vašej objednávky.',
        noEmailPlaceholder: 'Vaša e-mailová adresa',
        newsletter: {
            headline: 'Newsletter',
            text: 'Chcem byť bezplatne informovaný o aktuálnych ponukách {shopName} prostredníctvom newslettera.',
            feedbackText:
                'Úspešná registrácia Newslettera! Čoskoro dostanete e-mail s odkazom na potvrdenie ' +
                'vašej e-mailovej adresy.',
            error: 'Registrácia noviniek momentálne nie je možná.',
        },
        catalog: {
            headline: 'Katalóg',
            text: 'Chcem dostávať katalóg {shopName} bezplatne a pohodlne poštou až domov.',
            feedbackText: 'Katalóg vám bude čoskoro zaslaný na vašu adresu.',
            error: 'Katalógová objednávka v súčasnosti nie je možná.',
        },
    },
    county: {
        alba: 'Alba',
        arad: 'Arad',
        arges: 'Arges',
        bacau: 'Bacau',
        bihor: 'Bihor',
        bistritaNasaud: 'Bistrita Nasaud',
        botosani: 'Botosani',
        braila: 'Braila',
        brasov: 'Brasov',
        bucuresti: 'Bucuresti',
        buzau: 'Buzau',
        calarasi: 'Calarasi',
        carasSeverin: 'Caras Severin',
        cluj: 'Cluj',
        constanta: 'Constanta',
        covasna: 'Covasna',
        dambovita: 'Dambovita',
        dolj: 'Dolj',
        galati: 'Galati',
        giurgiu: 'Giurgiu',
        gorj: 'Gorj',
        harghita: 'Harghita',
        hunedoara: 'Hunedoara',
        ialomita: 'Ialomita',
        iasi: 'Iasi',
        ilfov: 'Ilfov',
        maramures: 'Maramures',
        mehedinti: 'Mehedinti',
        mures: 'Mures',
        neamt: 'Neamt',
        olt: 'Olt',
        prahova: 'Prahova',
        salaj: 'Salaj',
        satuMare: 'Satu Mare',
        sibiu: 'Sibiu',
        suceava: 'Suceava',
        teleorman: 'Teleorman',
        timis: 'Timis',
        tulcea: 'Tulcea',
        valcea: 'Valcea',
        vaslui: 'Vaslui',
        vrancea: 'Vrancea',
    },
    country: {
        select: 'Zmeniť krajinu',
        AT: 'Rakúsko',
        DE: 'Nemecko',
        CH: 'Schweiz',
        LI: 'Lichtenštajnsko',
        HU: 'Maďarsko',
        IT: 'Taliansko',
        BE: 'Belgicko',
        NL: 'Holandsko',
        BG: 'Bulharsko',
        HR: 'Chorvátsko',
        CY: 'Cyprus',
        CZ: 'Česká republika',
        DK: 'Dánsko',
        EE: 'Estónsko',
        FI: 'Fínsko',
        FR: 'Francúzsko',
        GR: 'Grécko',
        IE: 'Írsko',
        LV: 'Lotyšsko',
        LT: 'Litva',
        LU: 'Luxembursko',
        MK: 'Macedónsko',
        MT: 'Malta',
        PL: 'Poľsko',
        PT: 'Portugalsko',
        RO: 'Rumunsko',
        SM: 'San Maríno',
        SK: 'Slovensko',
        SI: 'Slovinsko',
        ES: 'Španielsko',
        SE: 'Švédsko',
    },
    socialMedia: {
        facebook: 'Zdieľať na Facebooku',
        twitter: 'Zdieľať na Twitteri',
        whatsapp: 'Zdieľať cez Whatsapp',
        native: 'Zdieľať',
    },
    productListing: {
        noProducts: 'Nenašli sa žiadne produkty',
        products: '{count} {count, plural, one {produkt} few {produkty} other {produktov}}',
        loadMore: 'Načítať viac',
        from: 'z',
        articles: 'články',
        title: '{categoryName} | {shopName}{count, plural, one {} other { - Stránky {count}}}',
    },
    pagination: {
        ariaPage: 'Strana {pageNumber}',
        next: 'Ďalej',
        previous: 'Späť',
    },
    menu: {
        logout: 'Odhlásiť sa',
        logoutHeader: 'Pozor',
        logoutText: 'Naozaj sa chcete odhlásiť?',
        logoutButtonText: 'Odhlásiť sa',
    },
    login: {
        password: 'Heslo',
        submitButtonLabel: 'Prihláste sa bezpečne',
        emailPlaceholder: 'Emailová adresa',
        passwordLabel: 'Zadajte heslo',
        usernameEmptyError: 'Používateľské meno by nemalo byť prázdne',
        usernameEmailError: 'Používateľské meno by malo byť platnou e-mailovou adresou',
        userLoginError: 'Používateľské meno alebo heslo je nesprávne',
        passwordForgotten: 'Zabudli ste heslo?',
        loginSuccess: 'Ste prihlásený',
        passwordEmptyError: 'Heslo by nemalo byť prázdne',
        existingUser: 'Už som registrovaný zákazník, chcem sa prihlásiť',
        resetPassword: 'Obnoviť heslo',
        resetEmailSent: 'Poslali sme vám e-mail na adresu {email}',
        resetErrorQuestions: 'Neobdržali ste žiadnu správu?',
        resetErrorAnswerOne: 'Skontrolujte, prosím, priečinok so spamom vo svojej e-mailovej schránke.',
        resetErrorAnswerTwo: 'Dôkladne skontrolujte svoje údaje.',
        resetErrorAnswerThree: 'Pred opätovným pokusom počkajte 15 minút.',
        newPassword: 'Nové heslo',
        passwordNotMatchingReq: 'Zdá sa, že heslo nezodpovedá požiadavkám',
        passwordsNotIdentical: 'Nové heslá sa nezhodujú',
        repeatPassword: 'Potvrdiť heslo',
        changePassword: 'Zmeniť heslo',
        passwordChanged: 'Heslo bolo úspešne zmenené',
        emailConfirmed: 'E-mail bol potvrdený. Teraz sa môžete prihlásiť.',
        processToken: 'Pri spracovaní požiadavky sa vyskytla chyba. Skúste neskôr prosím.',
        generalError: 'Vyskytla sa chyba. Skúste neskôr prosím.',
        authorizeStoreTitle: 'Autorizovať existujúce poverenia',
        authorizeStoreText: 'Chceli by ste povoliť používanie vašich existujúcich poverení?',
        yes: 'Áno',
        no: 'Nie',
        emailUnconfirmed: 'Najskôr potvrďte svoju e-mailovú adresu.',
    },
    orders: {
        order: 'Objednať',
        total: 'Celkom',
        details: 'Pozrite si detaily',
        back: 'Späť',
        shippingAddress: 'Dodacia adresa',
        paymentMethod: 'Spôsob platby',
        paymentMethodNotApplicable: 'N/A',
        quantity: 'Množstvo: {quantity}',
        reorder: 'Kúpiť znova',
        reOrderSuccess: 'Položka bola úspešne pridaná',
        reOrderFail: 'Produkt sa nepodarilo pridať do košíka',
        date: 'Dátum',
        progress: '{items} z {total} objednávok',
    },
    customer: {
        mr: 'Pán',
        mrs: 'Pani',
        customerNumber: 'Zákaznícke číslo',
        billingAddress: 'Fakturačná adresa',
        email: 'Emailová adresa',
        phone: 'Telefón',
        password: 'Heslo',
        passwordText: 'Heslo sa z bezpečnostných dôvodov nezobrazuje.',
        passwordLinkText: 'Zabudol si heslo?',
        tokenNotReceived: 'Váš e-mail neexistuje v našej databáze',
        emailInvalid: 'Neplatná e-mailová adresa',
        dataUpdated: 'Údaje aktualizované',
        wrongPassword: 'Heslo sa nepodarilo zmeniť',
        couldNotResetPassword: 'Heslo nebolo možné resetovať.',
        customerClub: {
            cardNumber: 'Číslo karty: {number}',
            validFrom: 'Platí od: {date}',
            validThru: 'Platnosť do: {date}',
            expiryWarning: 'Pozor: platnosť vašej karty vyprší za {days} {days, plural, one {dňa} other {dní}}.',
            cardExpired: 'Upozornenie: Platnosť vašej karty vypršala.',
            offerCardRenewal: 'Objednanie novej karty',
        },
        customerCardLink: {
            headline: 'Prepojte svoj online účet s vašou zákazníckou kartou!',
            subHeadline: 'Využite výhody zákazníckeho klubu pri každom online nákupe!',
            cardNumber: 'Číslo karty',
            clubEmail: 'E-mailová adresa verného zákazníka',
            cta: 'Prepojiť',
            success: 'Vaša klubová karta bola úspešne prepojená s vaším účtom.',
            error: {
                mykeneNotFound: 'Nepodarilo sa nájsť žiadnu klubovú kartu zákazníka so zadaným číslom.',
                mykeneInvalidCombination: 'E-mailová adresa sa nezhoduje s klubovou kartou zákazníka.',
                mykeneError: 'Vyskytla sa chyba. Skúste to prosím neskôr.',
                cardExistsForStore: 'Vaša klubová karta zákazníka už bola pridaná na účet.',
                unknownError: 'Vyskytla sa chyba. Skúste to prosím neskôr.',
            },
        },
    },
    register: {
        passwordEmptyError: 'Heslo nemôže byť prázdne',
        passwordMinError: 'Heslo musí mať aspoň 8 znakov',
        passwordNotValidError: 'Aspoň 1x číslo, 1x veľké písmeno, 1x malé písmeno',
        passwordConfirmationError: 'Heslá sa nezhodujú',
        passwordLabel: 'Vytvoriť heslo',
        passwordConfirmationLabel: 'Opakovať heslo',
        passwordCriteriaHeader: 'Vaše heslo musí obsahovať ...',
        passwordCriteria: {
            numberOfCharacters: '✔ min. 8 znakov',
            hasNumber: '✔ min. 1 číslo',
            hasUppercaseLetter: '✔ min. 1 veľké písmeno',
            hasLowercaseLetter: '✔ min. 1 malé písmeno',
        },
        acceptGDPRError: 'Musíte súhlasiť so zásadami ochrany osobných údajov',
        nextStepPayment: 'Pokračovať k platbe',
        error: 'Prepáčte, vyskytla sa chyba. Prosím skúste znova.',
        successMessage: 'Registrácia bola úspešná. Potvrďte prosím svoju e-mailovú adresu.',
        mainError: 'Prosím, opravte chyby!',
        change: 'ZMENIŤ',
        accountVerificationTitle: 'Overenie účtu',
        accountVerificationText:
            'Čoskoro dostanete e-mail s odkazom ' +
            'na aktiváciu vášho zákazníckeho účtu. Upozorňujeme, že tento odkaz je platný iba 24 hodín. ' +
            'Neoverené zákaznícke účty budú automaticky vymazané po 24 hodinách.',
        accountVerificationCTA: 'OK',
        existingAccountModal: {
            text:
                'Táto e-mailová adresa sa už používa. Ak sa chcete zaregistrovať v našom internetovom obchode,' +
                ' prihláste sa pomocou uložených údajov.',
            shops: '<ul><li>haus-hobby.ch</li><li>maison-hobby.ch</li><li>haus-hobby.com</li></ul>',
            accept: 'Prihlásiť sa',
            close: 'Zatvoriť',
        },
        newsletterSubscription: 'Chcem byť informovaný/á o aktuálnych ponukách od {shopName}.',
        existingAccountToast: 'Táto e-mailová adresa sa už používa.',
    },
    countrySwitcher: {
        headline: 'Zmeňte jazyk a nastavenia dopravy',
        country: 'Krajina',
        language: 'Jazyk',
        button: 'Uložiť',
        modal: {
            headline: 'Pozor!',
            description:
                'Meníte svoj región na {newSelectedCountry}. Aby ste si mohli niečo objednať, ' +
                'vaša dodacia adresa musí byť v tomto regióne.',
            submitText: 'Ďalej',
            closeText: 'Zrušiť',
        },
    },
    subcategoryFilter: {
        all: 'Všetky',
        reset: 'Odstrániť kategóriu',
    },
    personalInfo: {
        newEmail: 'Nová e-mailová adresa',
        newEmailConfirm: 'Potvrdiť novú e-mailovú adresu',
        passwordConfirm: 'Potvrdiť heslo',
        currentPassword: 'Aktuálne heslo',
        newPassword: 'Nové heslo',
        newPasswordConfirm: 'Potvrdiť nové heslo',
        actualEmail: 'Aktuálna e-mailová adresa',
    },
    newsletter: {
        inputPlaceholder: 'E-mailová adresa',
        success: 'Čoskoro dostanete e-mail s potvrdzovacím odkazom.',
        invalidEmail: 'Zadajte platnú e-mailovú adresu.',
        error: 'Ľutujeme, vyskytol sa problém s prihlásením na odber noviniek.',
        emailEmptyError: 'Prosím, zadajte e-mailovú adresu',
        registered: 'Prihlásili ste sa na odber noviniek.',
        notRegistered: 'Neprihlásili ste sa na odber noviniek.',
        subscribe: 'Prihlásiť sa',
        unsubscribe: 'Odhlásiť sa',
        alreadySubscribed: 'E-mailová adresa je už prihlásená na odber noviniek.',
        successAccount: 'Úspešne ste sa prihlásili.',
        alreadyRegisteredQuestion: 'Už ste sa zaregistrovali?',
        unsubscribeHere: 'Tu sa môžete odhlásiť',
        successUnsubscribe: 'Úspešne ste sa odhlásili',
        unsubscribeError: 'Vyskytla sa chyba. Skúste to prosím neskôr.',
    },
    cartChangeModal: {
        headline: 'Pozor',
        description: 'Nákupný košík bol aktualizovaný, znova vyberte spôsob platby.',
        submitText: 'OK',
    },
    clubCustomer: {
        priceMessage: 'Zákaznícka cena {price}',
        modal: {
            bannerHeadline: 'Staňte sa našim stálym zákazníkom!',
            bannerSubHeadline: 'Čakajú vás osobné zľavy a poukážky!',
            headline: 'Prečo je členstvo tak užitočné?',
            text:
                'So získaním zákazníckej karty získate 1-ročné predplatné v našom ' +
                'Klube zákazníkov, kde získate množstvo výhod a zakaždým ušetríte ' +
                'viac peňazí! Počas členstva garantujeme aj DOPRAVU ZADARMO na ' +
                'všetky Vaše objednávky. V našom klube na Vás čaká niekoľko ' +
                'ďalších špeciálnych zliav: *** teraz za každú novú registráciu ' +
                'vraciame 70 EUR vo forme kupónov! *** Kupóny Vám budú zaslané ' +
                'poštou spolu s Vašou kartou, aby ste si ich mohli uplatniť už ' +
                'pri ďalšej objednávke v našom internetovom obchode alebo pri ' +
                'telefonickej objednávke na našej zákazníckej linke. Poplatok ' +
                'za zákaznícku kartu je len 29,90 EUR na jeden rok. Pri nakupovaní ' +
                'sa teda oplatí objednať si aj vernostnú kartu! Kliknutím na ' +
                'tlačidlo sa Vaša zákaznícka karta pridá do nákupného košíka ' +
                'internetového obchodu, následne pri dokončovaní objednávky ' +
                'uveďte svoje osobné údaje.',
            button: 'CHCEM SA STAŤ ČLENOM!',
        },
    },
    infoBanner: {
        closeButton: 'Zavrieť',
    },
    slider: {
        prevButton: 'Späť',
        nextButton: 'Ďalej',
        activePagination: 'aktívne rolovanie',
        inactivePagination: 'inaktívne rolovanie',
    },
    specialDeal: {
        days: '{count, plural, one {Deň} other {Dni}}',
        hours: 'Hod.',
        minutes: 'Zap.',
        seconds: 'Sek.',
        specialDealOver: 'Bohužiaľ, táto akcia sa už skončila.',
    },
    userManuals: {
        headline: 'Príručky',
        search: 'Hľadať podľa produktu, čísla článku alebo kódu',
        resetSearch: 'Obnovenie vyhľadávania',
        table: {
            sku: 'Číslo článku',
            code: 'Code',
            productTitle: 'Produkt',
            type: 'Typ',
            types: {
                /* eslint-disable camelcase */
                instruction_manual: 'Návod na obsluhu',
                recipe_booklet: 'Brožúra s receptami',
                training_plan: 'Plán výcviku',
                nutrition_plan: 'Výživový plán',
                /* eslint-enable camelcase */
            },
        },
        noResults: 'Nenašli sa žiadne príručky.',
    },
    price: {
        from: 'od\u00a0{price}',
        reference: '{price}\u00a0/\u00a0{baseUnitMultiplier}\u00a0{referenceUnit}',
        statt: 'namiesto\u00a0{price}',
        uvp: 'UVP\u00a0{price}',
    },
    emarsysFieldWriter: {
        success: 'Zmena prebehla úspešne.',
        error: 'Vyskytla sa chyba. Skúste to prosím neskôr.',
    },
    subscription: {
        amount: 'Množstvo:',
        deliveryInterval: 'Interval doručenia:',
        nextDelivery: 'Ďalšia dodávka:',
        ordered: 'Objednal:',
        status: 'Postavenie:',
        cancelSubscription: 'Zrušiť predplatné',
        state: {
            active: 'Aktívne',
            paused: 'Pozastavené',
            cancelled: 'Ukončite',
            problems: 'Problémy',
        },
        intervalUnit: {
            day: '{amount} {amount, plural, one {Deň} other {Dní}}',
            month: '{amount} {amount, plural, one {Mesiac} other {Mesiace}}',
            year: '{amount} {amount, plural, one {Rok} other {Rokov}}',
        },
    },
};
