import { TYPE_CONTAINER_INLINE } from '@bloomreach/spa-sdk';
import Bloomreach from '@mediashop/base/bloomreach';
import ContainerInline from '../pattern/template/ContainerInline';
import AccordionPDP from './AccordionPDP';
import AccountNavigation from './AccountNavigation';
import AccountSettings from './AccountSettings';
import AmbienteShopping from './AmbienteShopping';
import Breadcrumb from './Breadcrumb';
import Checkout from './Checkout';
import ContentNavigation from './ContentNavigation';
import ContentNavigationItem from './ContentNavigationItem';
import CustomerClub from './CustomerClub';
import DealOfTheDay from './DealOfTheDay';
import EmailConfirmation from './EmailConfirmation';
import EmarsysFieldWriter from './EmarsysFieldWriter';
import Flexbox from './Flexbox';
import Form from './Form';
import GDPRSettings from './GDPRSettings';
import HeroProductSlider from './HeroProductSlider';
import HeroTeaserSlider from './HeroTeaserSlider';
import ImageBannerSlider from './ImageBannerSlider';
import LoginAndRegister from './LoginAndRegister';
import MainNavigation from './MainNavigation';
import MainNavigationExtended from './MainNavigationExtended';
import Manuals from './Manuals';
import MarketingSlider from './MarketingSlider';
import Newsletter from './Newsletter';
import NoIndex from './NoIndex';
import OrderDetail from './OrderDetail';
import OrderOverview from './OrderOverview';
import PasswordForgotten from './PasswordForgotten';
import PasswordSetNew from './PasswordSetNew';
import PersonalInfo from './PersonalInfo';
import ProductComponentDetails from './ProductComponentDetails';
import ProductDetails from './ProductDetails';
import ProductGrid from './ProductGrid';
import ProductSlider from './ProductSlider';
import ProductsPreCheckout from './ProductsPreCheckout';
import Register from './Register';
import Samsung from './Samsung';
import TeaserGrid from './TeaserGrid';
import TopHeader from './TopHeader';
import TrackingAndTrace from './TrackingAndTrace';
import SubscriptionManagement from './SubscriptionManagement';

export default {
    ...Bloomreach,
    AccordionPDP,
    AccountNavigation,
    AccountSettings,
    AmbienteShopping,
    Breadcrumb,
    Checkout,
    ContentNavigation,
    ContentNavigationItem,
    CustomerClub,
    DealOfTheDay,
    EmailConfirmation,
    EmarsysFieldWriter,
    Flexbox,
    Form,
    GDPRSettings,
    HeroProductSlider,
    HeroTeaserSlider,
    ImageBannerSlider,
    LoginAndRegister,
    MainNavigation,
    MainNavigationExtended,
    Manuals,
    MarketingSlider,
    Newsletter,
    NoIndex,
    OrderDetail,
    OrderOverview,
    PasswordForgotten,
    PasswordSetNew,
    PersonalInfo,
    ProductComponentDetails,
    ProductDetails,
    ProductGrid,
    ProductSlider,
    ProductsPreCheckout,
    Register,
    Samsung,
    SubscriptionManagment: SubscriptionManagement,
    TeaserGrid,
    TopHeader,
    TrackingAndTrace,
    [TYPE_CONTAINER_INLINE]: ContainerInline,
};
