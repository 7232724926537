import { useEffect } from 'react';
import { usePage } from '../../hooks/usePage';
import useEventTracking from '../../hooks/useEventTracking';
import { useLocation } from 'react-router-dom';
import { useProduct } from '../../hooks/useProduct';
import { useBrxPageContext } from '../../bloomreach/useBrxPageContext';

export default function EventTracking(): null {
    const { pathname } = useLocation();
    const page = usePage();
    const { trackCartViewed, trackHomepageViewed, trackPDPViewed } = useEventTracking();
    const { slugProduct } = useProduct();
    const brxPage = useBrxPageContext();

    const trackPDPViewedEvent = () => {
        const sku = slugProduct.selectedVariant?.sku ?? slugProduct.product?.variants[0].sku ?? '';
        const page = brxPage.toJSON().page;
        const componentCount = Object.keys(page).filter((prop) => page[prop].type === 'container-item').length;
        trackPDPViewed(sku, componentCount);
    };

    useEffect(() => {
        switch (page.pageType) {
            case 'homepage': {
                trackHomepageViewed();
                break;
            }
            case 'products-default': {
                if (slugProduct.status === 'succeeded') {
                    trackPDPViewedEvent();
                }
                break;
            }
            case 'cart': {
                trackCartViewed();
                break;
            }
            default: {
                const pathNameSplit = pathname.split('/');
                if (pathNameSplit.includes('p') && slugProduct.status === 'succeeded') {
                    trackPDPViewedEvent();
                }
                break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page.pageType, slugProduct.status]);

    return null;
}
