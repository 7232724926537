import { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import { IMAGE_SIZE_MAP } from './imageSizeMap';
import { CroppedImageVariant } from '@mediashop/base/types/imageVariants';
import { useElementIsVisible } from '@mediashop/app/hooks/useElementIsVisible';
import { BreakpointName } from '@mediashop/app/config/breakpoints';
import NoImageAvailable from '../../../assets/images/NoImageAvailable.jpg';

const DEFAULT_IMAGE_URL = 'https://mediashop-media.scalecommerce.cloud';

const componentName = 'cropped-image';

const processSrc = (src: string, targetWidth: number, targetHeight: number) => {
    if (src.includes('NoImageAvailable')) {
        return src;
    }
    /*
     * set image size via query string
     * not-preferred method as the result cannot be cached in the CDN
     */
    if (src.startsWith(DEFAULT_IMAGE_URL)) {
        const [location, search] = src.split('?');

        const urlParams = new URLSearchParams(search);
        urlParams.set('w', targetWidth.toString());
        urlParams.set('h', targetHeight.toString());

        return `${location}?${urlParams.toString()}`;
    }

    // Set image size via path (preferred)
    return src.replace(/\/images\//, `/images/${targetWidth}_${targetHeight}/`);
};

type CroppedImageProps = BaseProps & {
    src?: string;
    altText?: string;
    variant: CroppedImageVariant;
    lazyLoad?: boolean;
    fetchPriority?: FetchPriorityTypes;
};

export type FetchPriorityTypes = 'high' | 'low' | 'auto';

function CroppedImage({ className, src, altText, variant, fetchPriority = 'auto' }: CroppedImageProps) {
    const deviceType = useDeviceType();
    const [device, setDevice] = useState<BreakpointName>('desktop');
    useEffect(() => setDevice(deviceType), [deviceType]);

    const imageRef = useRef(null);

    const isVisible = useElementIsVisible(imageRef);

    const { width: targetWidth, height: targetHeight } = useMemo(
        () => IMAGE_SIZE_MAP[variant][device],
        [variant, device]
    );

    const source = useMemo(
        () => (src ? processSrc(src, targetWidth, targetHeight) : NoImageAvailable),
        [src, targetWidth, targetHeight]
    );

    return (
        <img
            alt={altText}
            className={classNames(className, `${componentName}`)}
            src={source}
            ref={imageRef}
            loading={isVisible ? 'eager' : 'lazy'}
            // remove once fetchpriority is not experimental anymore
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line react/no-unknown-property
            fetchpriority={fetchPriority}
        />
    );
}

export default injectComponent('pattern.atom.CroppedImage', CroppedImage);
