import filterShippingCountriesByCurrency from '../helper/filterShippingCountriesByCurrency';
import { ContextState } from '../store/reducer/context';
import { LOQATE_API_KEY, LOQATE_BASE_URL, LOQATE_FIND_URL, LOQATE_RETRIEVE_URL } from './constants/loqate';

type LoqateDataProps = {
    Key: string;
    Text?: string;
    Container?: string;
    Countries?: string;
    Language?: string;
};

type LoqateResponse = {
    Items: LoqateItem[];
};

export type LoqateItem = {
    Id: string;
    Type: string;
    Text: string;
    Highlight: string;
    Description: string;
    Street: string;
    BuildingNumber: string;
    PostalCode: string;
    City: string;
    CountryIso2: string;
    ProvinceName: string;
    Line1: string;
};

export default class LoqateClient {
    shippingCountries: string;
    language: string;
    country: string;
    key: string;
    baseUrl: string;

    constructor(
        shippingCountries: string[],
        shopContext: ContextState,
        key: string = LOQATE_API_KEY,
        baseUrl: string = LOQATE_BASE_URL
    ) {
        shippingCountries = filterShippingCountriesByCurrency(shippingCountries, shopContext.currency);

        this.shippingCountries = shippingCountries.join();
        this.language = shopContext.locale.slice(0, 2);
        this.country = shopContext.country;
        this.key = key;
        this.baseUrl = baseUrl;
    }

    async fetchLoqate(url: string, data: LoqateDataProps): Promise<LoqateResponse> {
        Object.keys(data).forEach((key) => {
            if (data[key] === undefined) {
                delete data[key];
            }
        });
        const query = new URLSearchParams(data).toString();
        const response = await fetch(`${this.baseUrl}/${url}?${query}`);

        if (!response.ok) {
            return { Items: [] };
        }

        return response.json();
    }

    async find(value: string, container?: string): Promise<LoqateItem[]> {
        const data = {
            Key: LOQATE_API_KEY,
            Text: value,
            Container: container,
            Countries: this.shippingCountries,
            Language: this.language,
            IsMiddleware: true,
            Origin: this.country,
        };
        const resp = await this.fetchLoqate(LOQATE_FIND_URL, data);
        return resp.Items;
    }

    async retrieve(itemId: string): Promise<LoqateItem[]> {
        const data = {
            Key: LOQATE_API_KEY,
            // eslint-disable-next-line id-length
            Id: itemId,
        };
        const resp = await this.fetchLoqate(LOQATE_RETRIEVE_URL, data);
        return resp.Items;
    }
}
