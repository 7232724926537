import { lazy } from 'react';
import { Variant } from '@mediashop/app/api/types/ClientProduct';
import { ProductWithRelation } from '@mediashop/catalog-base/types/productRelations';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { CartLineItem } from '@mediashop/app/api/types/ClientCart';

const PreCheckoutBestsellerProductMolecule = lazy(() =>
    lazyRetry(() => import('./PreCheckoutBestsellerProduct'))
);

export interface PreCheckoutBestsellerProductProps {
    product: ProductWithRelation;
    onClose: () => void;
    selectedMainVariant: Variant;
    cartLineItems: CartLineItem[];
}

const PreCheckoutBestsellerProduct = (props: PreCheckoutBestsellerProductProps): JSX.Element => (
    <LazyComponent>
        <PreCheckoutBestsellerProductMolecule {...props} />
    </LazyComponent>
);

export default PreCheckoutBestsellerProduct;
