import { DISPATCH_ACTION, SCRIPT_LOADING_STATE, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { useEffect } from 'react';
import { useCmpServiceApproved } from '@mediashop/app/hooks/cmp/useCmpServiceApproved';

interface UsePayPalProps {
    isEnabled?: boolean;
}

export const usePayPal = ({ isEnabled = true }: UsePayPalProps = {}) => {
    const [{ isInitial, isPending, isResolved }, dispatch] = usePayPalScriptReducer();

    const isConsentApproved = useCmpServiceApproved('PayPal');

    useEffect(() => {
        if (isInitial && isEnabled && isConsentApproved) {
            dispatch({
                type: DISPATCH_ACTION.LOADING_STATUS,
                value: SCRIPT_LOADING_STATE.PENDING,
            });
        }
    }, [dispatch, isConsentApproved, isEnabled, isInitial]);

    return {
        isLoading: isPending,
        isResolved,
    };
};
