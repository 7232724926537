import { ProductWithActiveVariant, ProductWithDeeplink } from '@mediashop/app/api/types/ClientProduct';
import { BackgroundProps, BaseProps } from '@mediashop/app/bloomreach/types';
import { LinkOpenInTab } from '@mediashop/base/pattern/atom/LinkOpenInTab';
import classNames from 'classnames';
import { getSearchParam } from '../../helper';
import parseHtml from 'html-react-parser';
import { PRODUCT_TITLE_LINE_LIMIT } from '@mediashop/app/constants/productTitleLimit';
import { useMemo } from 'react';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';

const componentName = 'product-description';

const calculateProductTitleAndClaimLineCount = (activeInfos: number, product: ProductWithDeeplink) => {
    let productTitleLineCount: number;
    let claimLineCount: number;

    switch (activeInfos) {
        case 0:
            productTitleLineCount = 2;
            claimLineCount = 5;
            if (product?.name?.length >= PRODUCT_TITLE_LINE_LIMIT * productTitleLineCount) {
                productTitleLineCount = 3;
                claimLineCount = 4;
            }
            if (product?.name?.length >= PRODUCT_TITLE_LINE_LIMIT * productTitleLineCount) {
                productTitleLineCount = 4;
                claimLineCount = 3;
            }
            break;
        case 1:
            productTitleLineCount = 3;
            claimLineCount = 3;
            if (product?.name?.length >= PRODUCT_TITLE_LINE_LIMIT * productTitleLineCount) {
                productTitleLineCount = 4;
                claimLineCount = 2;
            }
            break;
        default:
            productTitleLineCount = 2;
            claimLineCount = 2;
            break;
    }

    return { productTitleLineCount, claimLineCount };
};

type ProductDescriptionProps = BaseProps & {
    activeInfos: number;
    description?: string;
    product: ProductWithDeeplink & ProductWithActiveVariant;
    backgroundColor?: BackgroundProps;
};

function ProductDescription({
    activeInfos,
    description,
    product,
    backgroundColor,
}: ProductDescriptionProps): JSX.Element {
    const isDeviceTypeMobile = useDeviceType() === 'mobile';
    const { productTitleLineCount, claimLineCount } = calculateProductTitleAndClaimLineCount(activeInfos, product);

    const productTitle = useMemo(() => {
        const baseTitle = product.activeVariant?.attributes?.name ?? product?.name;
        const needsTrimming =
            baseTitle?.length > PRODUCT_TITLE_LINE_LIMIT * productTitleLineCount && !isDeviceTypeMobile;
        return needsTrimming ? `${baseTitle.slice(0, PRODUCT_TITLE_LINE_LIMIT * productTitleLineCount)}...` : baseTitle;
    }, [product.activeVariant?.attributes?.name, product?.name, productTitleLineCount, isDeviceTypeMobile]);
    return (
        <>
            {/** Title / Name */}
            <div
                className={classNames(
                    `${componentName}__title`,
                    `${componentName}__title--${backgroundColor}`,
                    `${componentName}__title--lines-${productTitleLineCount}`
                )}
                title={product?.name}
            >
                <LinkOpenInTab
                    tab="sameTab"
                    link={product.deepLink ?? `p/${product?.slug}?${getSearchParam(product)}`}
                    onClick={(event) => event.stopPropagation()}
                >
                    {productTitle}
                </LinkOpenInTab>
            </div>

            {/** Description */}
            {description && (
                <div
                    className={classNames(
                        `${componentName}__description`,
                        `${componentName}__description--lines-${claimLineCount}`
                    )}
                >
                    {parseHtml(description)}
                </div>
            )}
        </>
    );
}

export default ProductDescription;
