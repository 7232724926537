import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { MappedCountry } from '../../../../types/country-switcher';
import SelectBox from '../../../atom/SelectBox';

type CountrySelectProps = {
    componentName: string;
    onCountryChanged: (country: MappedCountry) => void;
    options: MappedCountry[];
    selectedCountry: MappedCountry;
};

export const CountrySelect: React.FC<CountrySelectProps> = ({
    componentName,
    onCountryChanged,
    options,
    selectedCountry,
}: CountrySelectProps) => (
    <div className={`${componentName}__select-country`}>
        <span className={`${componentName}__select-label`}>
            <FormattedMessage id="countrySwitcher.country" />
        </span>
        <SelectBox
            className={componentName}
            defaultValue={selectedCountry}
            options={options}
            onChange={onCountryChanged}
        />
    </div>
);
