/* eslint-disable id-length */
export const defaultCurrencies = {
    DE: 'EUR',
    AT: 'EUR',
    CH: 'CHF',
    LI: 'CHF',
    HU: 'HUF',
    CZ: 'CZK',
    SK: 'EUR',
    BE: 'EUR',
    NL: 'EUR',
    RO: 'RON',
    GB: 'GBP',
    US: 'USD',
};
