import Money from '@mediashop/app/domain/Money';
import { Cart, CustomLineItem } from '../types/ClientCart';
import AddressMapper from './AddressMapper';
import { CartDto } from '../dtos/cart/cartDto';
import { CustomLineItemDto } from '../dtos/cart/customLineItemDto';

export const mapCustomLineItem = (customLineItem: CustomLineItemDto): CustomLineItem => ({
    ...customLineItem,
    money: customLineItem.money,
    name: customLineItem.name,
});

/**
 * Calculates the product total or resolves to zero money
 */
const calculateProductTotal = (cart: CartDto) => {
    if (cart?.shippingInfo) {
        return Money.subtract(cart.totalPrice, cart.shippingInfo?.taxedPrice?.totalGross);
    }

    return cart.totalPrice;
};

/**
 * Maps cart-data for easier consumption by developer.
 */
const mapCart = (cart: CartDto): Cart => ({
    ...cart,
    customLineItems: cart.customLineItems.map((customLineItem) => mapCustomLineItem(customLineItem)),
    shippingAddress: AddressMapper.mapAddressDto(cart.shippingAddress)!,
    billingAddress: AddressMapper.mapAddressDto(cart.billingAddress),
    productTotal: calculateProductTotal(cart),
});

export default {
    mapCart,
};
