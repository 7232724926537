import { injectComponent } from '@mediashop/app/component-injector';
import Button from '@mediashop/base/pattern/atom/Button';
import Icon from '../Icon';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { SocialShareProps } from '../../../types';

const getActionProps = (props: SocialShareProps) => {
    switch (props.type) {
        case 'link':
            return { type: 'link', href: props.url, target: '_blank' };
        case 'button':
            return { onClick: props.clickHandler };
        default:
            throw new Error('Invalid type passed to SocialShare');
    }
};

function SocialShareButton(props: SocialShareProps) {
    const { platform, messageId, mobileOnly = false, componentName } = props;

    const actionProps = getActionProps(props);

    const messageIdProp = messageId ?? platform.toLowerCase();
    const baseClassName = `${componentName}__button`;
    const mobileClassName = `${baseClassName}-mobile`;

    return (
        <Button
            className={classNames(baseClassName, {
                [mobileClassName]: mobileOnly,
            })}
            {...actionProps}
        >
            <Icon className={`${componentName}__button-icon`} name={platform} />
            <span className={`${componentName}__button-text`}>
                <FormattedMessage id={`socialMedia.${messageIdProp}`} />
            </span>
        </Button>
    );
}

export default injectComponent('pattern.atom.SocialShareButton', SocialShareButton);
