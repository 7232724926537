import { injectComponent } from '@mediashop/app/component-injector';
import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';

type ShowMoreButtonProps = BasePropsWithChildren & {
    onClick: () => void;
};

function ShowMoreButton({ children, onClick }: ShowMoreButtonProps) {
    const componentName = 'show-more-button';

    return (
        <button className={`${componentName}`} onClick={onClick}>
            {children}
        </button>
    );
}

export default injectComponent('pattern.atom.ShowMoreButton', ShowMoreButton);
