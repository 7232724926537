import { useCart } from '../../hooks/useCart';
import { useProject } from '../../hooks/useProject';
import { useShopContext } from '../../hooks/useShopContext';
import { getProjectPaymentMethods } from '../common/getProjectPaymentMethods';

export const usePaymentMethodAllowed = (paymentMethod: string) => {
    const { cart } = useCart();
    const { paymentGroups } = useProject();
    const { country: shopCountry } = useShopContext();

    const billingCountry = cart?.billingAddress?.country ?? shopCountry;

    const countryPaymentMethods = getProjectPaymentMethods(paymentGroups, billingCountry);

    return countryPaymentMethods.includes(paymentMethod);
};
