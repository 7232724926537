import { injectComponent } from '@mediashop/app/component-injector';
import { lazy } from 'react';
import { BaseProps, BrxImageSetImpl, BrxTextCompound } from '@mediashop/app/bloomreach/types';
import { BrxLinkOpenInTab } from '@mediashop/base/pattern/atom/LinkOpenInTab';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const TeaserGridTileMolecule = lazy(() => lazyRetry(() => import('./TeaserGridTile')));

// Note: we cannot use the BrxImageWithAltText type here as for whatever reason the image can be null
type TeaserGridTileImages = {
    desktopImage: { altText?: string; image?: BrxImageSetImpl };
    mobileImage: { altText?: string; image?: BrxImageSetImpl };
};

export type TeaserGridTileType = {
    hideTeaser?: boolean;
    imageCompoundDesktopMobile?: TeaserGridTileImages;
    imagesDesktopMobile: TeaserGridTileImages;
    targetUrl: BrxLinkOpenInTab;
    teaserBackgroundColor?: string;
    teaserFontColor?: string;
    videoUrl?: string;
    ignoreTextBlockPositionForMobile?: boolean;
    textCompound?: BrxTextCompound;
};

export type TeaserGridTileProps = BaseProps &
    TeaserGridTileType & {
        boxShadow?: boolean;
        globalTeaserBackgroundColor?: string;
        globalTeaserFontColor?: string;
        gridColumnStart?: number;
        useSquareCorners?: boolean;
        verticalAlignmentDesktop?: string;
        textBlockPosition?: string;
    };

const TeaserGridTile = (props: TeaserGridTileProps): JSX.Element => (
    <LazyComponent>
        <TeaserGridTileMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.TeaserGridTile', TeaserGridTile, 'catalog-base');
