import { TrackableButton } from '@mediashop/app/analytics/components/TrackableButton';
import { TrackingKeys } from '@mediashop/app/analytics/constants/trackingKeys';
import { Category } from '@mediashop/app/api/types/ClientCategory';
import { BackgroundProps, BaseProps, BrxImageSetImpl, BrxLink } from '@mediashop/app/bloomreach/types';
import { injectComponent } from '@mediashop/app/component-injector';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useProject } from '@mediashop/app/hooks/useProject';
import { useShopContext } from '@mediashop/app/hooks/useShopContext';
import { useCategories } from '@mediashop/app/queries/categories/useCategories';
import BackgroundColorWrapper from '@mediashop/base/pattern/atom/BackgroundColorWrapper';
import ContentWrapper from '@mediashop/base/pattern/atom/ContentWrapper';
import Icon from '@mediashop/base/pattern/atom/Icon';
import {
    BrxLinkOpenInTab,
    BrxLinkOpenInTabWithColors,
    LinkOpenInTab,
} from '@mediashop/base/pattern/atom/LinkOpenInTab';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import SearchBar from '../MainNavigation/SearchBar';
import DesktopMenu from './DesktopMenu';
import HeaderLogo, { LogoEffects } from './HeaderLogo';
import { filterEmptyCategories, getCategoryItems } from './mainnavigationhelper';
import MobileMenu from './MobileMenu';
import UserMenu from './UserMenu';
import parseHtml from 'html-react-parser';
import VoucherCheck from '../../molecule/VoucherCheck';

const componentName = 'main-navigation';

type MainNavigationProps = BaseProps & {
    background: BackgroundProps;
    msLogoMobile: BrxImageSetImpl;
    msLogoDesktop: BrxImageSetImpl;
    exchangeLogoDesktop?: BrxImageSetImpl;
    exchangeLogoMobile?: BrxImageSetImpl;
    offCanvasLinksWithColorsMobile: BrxLinkOpenInTabWithColors[];
    quickLinkWithColorsDesktop: BrxLinkOpenInTabWithColors;
    quickLinksWithColorsMobile: BrxLinkOpenInTabWithColors[];
    shippingText: string;
    country: string[];
    userIconLinkLoggedIn: BrxLink;
    userIconLinkLoggedOut: BrxLink;
    extendedNavigation?: BrxLinkOpenInTab[];
    linksForLoggedInUsers: BrxLink[];
    displayName?: string;
    minWidthMobileLogo?: string;
    minWidthDesktopLogo?: string;
};

const MainNavigation = ({
    extendedNavigation,
    linksForLoggedInUsers,
    msLogoDesktop,
    msLogoMobile,
    exchangeLogoDesktop,
    exchangeLogoMobile,
    offCanvasLinksWithColorsMobile,
    quickLinkWithColorsDesktop,
    quickLinksWithColorsMobile,
    shippingText,
    userIconLinkLoggedIn,
    userIconLinkLoggedOut,
    displayName,
    minWidthMobileLogo,
    minWidthDesktopLogo,
}: MainNavigationProps): ReactElement => {
    const { data: categories = [] } = useCategories();
    const { shippingCountries } = useProject();
    const { country } = useShopContext();

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const menuItems = useMemo<Category[]>(
        () => filterEmptyCategories(getCategoryItems(categories, country, shippingCountries)),
        [categories, shippingCountries, country]
    ).slice(0, 10);

    const openMenu = () => {
        const scrollY = window.scrollY;
        document.querySelector('body')?.classList.add('scroll-lock--modal');
        setIsMobileMenuOpen(true);
        document.body.style.top = `-${scrollY}px`;
    };

    const closeMenu = () => {
        const scrollY = parseInt(document.body.style.top, 10);
        document.querySelector('body')?.classList.remove('scroll-lock--modal');
        setIsMobileMenuOpen(false);
        window.scrollTo(0, Math.abs(scrollY));
    };

    useEffect(() => {
        // remove the 'scroll-lock--modal'-class on componentUnmount
        return closeMenu;
    }, []);

    let effect: LogoEffects;
    switch (displayName) {
        case 'MainNavigationFlipLogo':
            effect = LogoEffects.Flip;
            break;
        case 'MainNavigationFadeLogo':
            effect = LogoEffects.FadeInFadeOut;
            break;

        default:
            effect = LogoEffects.None;
            break;
    }

    return (
        <BackgroundColorWrapper backgroundColor="default" className={componentName}>
            <ContentWrapper>
                <div className={`${componentName}__wrapper`}>
                    <VoucherCheck />

                    <TrackableButton
                        className={`${componentName}__mobile-button`}
                        trackingKey={TrackingKeys.BURGER_MENU}
                        aria-label="menu-button"
                        onClick={openMenu}
                    >
                        <Icon className={`${componentName}__mobile-icon`} name="Menu" />
                    </TrackableButton>

                    <HeaderLogo
                        msLogoDesktop={msLogoDesktop}
                        msLogoMobile={msLogoMobile}
                        effect={effect}
                        msLogoEffectDesktop={exchangeLogoDesktop}
                        msLogoEffectMobile={exchangeLogoMobile}
                        minWidthMobileLogo={minWidthMobileLogo}
                        minWidthDesktopLogo={minWidthDesktopLogo}
                    />

                    {shippingText.length ? (
                        <div className={`${componentName}__shipping-text`}>{parseHtml(shippingText)}</div>
                    ) : (
                        SKIP_RENDER
                    )}

                    <UserMenu
                        linksForLoggedInUsers={linksForLoggedInUsers}
                        userIconLinkLoggedIn={userIconLinkLoggedIn}
                        userIconLinkLoggedOut={userIconLinkLoggedOut}
                    />

                    <SearchBar />

                    <DesktopMenu
                        menuItems={menuItems}
                        extendedItems={extendedNavigation}
                        quickLink={quickLinkWithColorsDesktop}
                    />

                    {quickLinksWithColorsMobile.length ? (
                        <div className={`${componentName}__mobile-quick-links`}>
                            {quickLinksWithColorsMobile.map((link) => (
                                <LinkOpenInTab
                                    key={typeof link.link === 'object' ? link.link.text : link.link}
                                    {...link}
                                />
                            ))}
                        </div>
                    ) : (
                        SKIP_RENDER
                    )}

                    {isMobileMenuOpen ? (
                        <MobileMenu
                            closeMenu={closeMenu}
                            extendedItems={extendedNavigation}
                            linksForLoggedInUsers={linksForLoggedInUsers}
                            menuItems={menuItems}
                            msLogoDesktop={msLogoDesktop}
                            msLogoMobile={msLogoMobile}
                            offCanvasLinksMobile={offCanvasLinksWithColorsMobile}
                            shippingText={shippingText}
                            userIconLinkLoggedIn={userIconLinkLoggedIn}
                            userIconLinkLoggedOut={userIconLinkLoggedOut}
                        />
                    ) : (
                        SKIP_RENDER
                    )}
                </div>
            </ContentWrapper>
        </BackgroundColorWrapper>
    );
};

export default injectComponent('pattern.organism.MainNavigation', MainNavigation, 'catalog-base');
