import { PaymentGroup } from '../types/paymentGroup';

/**
 * Get payment methods from project configuration
 * @param paymentGroups payment groups
 * @param country current (billing) country
 * @returns list of payment methods
 */
export const getProjectPaymentMethods = (paymentGroups: Array<PaymentGroup>, country: string): Array<string> => {
    const groupsUsedByCountry = paymentGroups.filter((group) => !group.countries || group.countries?.includes(country));
    return groupsUsedByCountry.reduce((paymentMethods, group) => [...paymentMethods, ...group.paymentMethods], []);
};
