import { inferQueryKeyStore, mergeQueryKeys } from '@lukemorales/query-key-factory';
import { addresses } from './addresses';
import { cart } from './cart';
import { categories } from './categories';
import { customer } from './customer';
import { payment } from './payment';
import { products } from './products';
import { search } from './search';

export const queries = mergeQueryKeys(addresses, cart, categories, customer, payment, products, search);

export type QueryKeys = inferQueryKeyStore<typeof queries>;
